import * as THREE from "three";
import { EPSILON, MODEL_LINE_COLOR, MODEL_LINE_RENDER_ORDER, ZOOM_TO_FIT_SIZE_FACTOR_2D, ZOOM_TO_FIT_SIZE_FACTOR_3D } from "../../consts";
import OrbitControls from "../../libs/OrbitControls";
import TrackballControls from "../../libs/TrackballControls";
import { Direction } from "../../models/Direction";
import { Segment } from "../../models/segments/Segment";
import { Side } from "../../../models/Side";
import MathUtils from "../MathUtils";
import UnitsUtils from "../UnitsUtils";
import { v4 as uuidv4 } from "uuid";
import { RoomEntityType } from "../../../models/RoomEntityType";
import { soBoundaryLine } from "../../models/SceneObjects/RoomBoundary/soBoundaryLine";
import { soRoom2D } from "../../models/SceneObjects/Room/soRoom2D";
import { soRoomBoundary } from "../../models/SceneObjects/RoomBoundary/soRoomBoundary";
import BoundingBoxUtils from "./BoundingBoxUtils";
import { soDataBox } from "../../models/SceneObjects/DataBox/soDataBox";

export default class GeometryUtils {
  static disposeObject(obj: any): void {
    if (obj) {
      GeometryUtils._disposeObject(obj);

      while (obj.children.length > 0) {
        GeometryUtils.disposeObject(obj.children[0]);
        obj.remove(obj.children[0]);
      }
    }
  }
  private static _disposeObject(obj: any): void {
    if (obj) {
      obj.geometry?.dispose();

      if (obj.material) {
        if (obj.material.length) {
          for (let i = 0; i < obj.material.length; ++i) {
            obj.material[i].dispose();
          }
        } else {
          obj.material.dispose();
        }
      }
    }
  }
  static isPointOnLine(point: THREE.Vector3, line: THREE.Line3) {
    const start = line.start;
    const end = line.end;

    // Vector from start to end of the line
    const lineVec = new THREE.Vector3().subVectors(end, start);

    // Vector from start to the point
    const pointVec = new THREE.Vector3().subVectors(point, start);

    // Cross product to check if the point is on the same direction as the line
    const cross = new THREE.Vector3().crossVectors(lineVec, pointVec);

    // If cross product is not zero, the point is not on the line (parallel check)
    if (cross.lengthSq() > 1e-10) {
      return false;
    }

    // Dot product to check if the point is between the start and end of the line segment
    const dot = pointVec.dot(lineVec);
    if (dot < 0 || dot > lineVec.lengthSq()) {
      return false;
    }

    // If both checks passed, the point is on the line segment
    return true;
  }
  /**
   * Calculates signed CCW angle
   */
  static calculateSignedAngle(x1: number, y1: number, x2: number, y2: number): number {
    const dot = x1 * x2 + y1 * y2;
    const dot90 = x1 * -y2 + y1 * x2;
    return -Math.atan2(dot90, dot);
  }

  static setRenderOrder(object: THREE.Object3D, renderOrder: number): void {
    if (!(object instanceof THREE.Group)) {
      object.renderOrder = renderOrder;
    }

    for (let i = 0, l = object.children.length; i < l; i++) {
      GeometryUtils.setRenderOrder(object.children[i], renderOrder);
    }
  }
  static planeIntersectsObject3D(plane, object3d) {
    // Compute the bounding box of the object in world coordinates
    const boundingBox = new THREE.Box3().setFromObject(object3d);

    // Check if the bounding box intersects with the plane
    return boundingBox.intersectsPlane(plane);
  }
  static isLineHorizontal(line: THREE.Line3 | Segment): boolean {
    return MathUtils.areNumbersEqual(line.start.y, line.end.y);
  }
  static isLineVertical(line: THREE.Line3): boolean {
    return MathUtils.areNumbersEqual(line.start.x, line.end.x);
  }

  static isBBoxHorizontal(bb: THREE.Box3): boolean {
    const width = bb.max.x - bb.min.x;
    const height = bb.max.y - bb.min.y;
    return width > height ? true : false;
  }

  static isBBoxVertical(bb: THREE.Box3): boolean {
    const width = bb.max.x - bb.min.x;
    const height = bb.max.y - bb.min.y;
    return height > width ? true : false;
  }
  static isBBoxAndSegmentIsSameDirection(bb: THREE.Box3, segment: Segment): boolean {
    return (this.isBBoxHorizontal(bb) && segment.isHorizontal) || (this.isBBoxVertical(bb) && segment.isVertical);
  }

  static createBoundingBoxFromSegment(segment: Segment): THREE.Box3 {
    const start3D = new THREE.Vector3(segment.start.x, segment.start.y, 0);
    const end3D = new THREE.Vector3(segment.end.x, segment.end.y, 0);

    const boundingBox = new THREE.Box3().setFromPoints([start3D, end3D]);
    return boundingBox;
  }

  static getLineDirection(line: THREE.Line3 | Segment): Direction {
    return GeometryUtils.isLineHorizontal(line) ? Direction.Horizontal : Direction.Vertical;
  }

  /**
   * Check if lines(horizontal or vertical) overlap fully or partially
   * @param {THREE.Line3 | Segment} line1:Horizontal or Vertical line
   * @param {THREE.Line3 | Segment} line2:Horizontal or Vertical line
   * @returns {boolean}
   */
  static doLinesOverlap(line1: THREE.Line3 | Segment, line2: THREE.Line3 | Segment): boolean {
    const line1Dir = GeometryUtils.getLineDirection(line1);
    const line2Dir = GeometryUtils.getLineDirection(line2);
    if (line1Dir !== line2Dir) {
      return false;
    }
    const [axis, axis2] = line1Dir === Direction.Vertical ? ["x", "y"] : ["y", "x"];
    if (!MathUtils.areNumbersEqual(line1.start[axis], line2.start[axis])) {
      return false;
    }
    if (
      MathUtils.isNumberInRange(line1.start[axis2], line2.start[axis2], line2.end[axis2]) ||
      MathUtils.isNumberInRange(line1.end[axis2], line2.start[axis2], line2.end[axis2]) ||
      MathUtils.isNumberInRange(line2.start[axis2], line1.start[axis2], line1.end[axis2]) ||
      MathUtils.isNumberInRange(line2.end[axis2], line1.start[axis2], line1.end[axis2])
    ) {
      return true;
    }
    return false;
  }
  static doLinesIntersect(
    line1A: THREE.Vector3 | THREE.Vector2,
    line1B: THREE.Vector3 | THREE.Vector2,
    line2C: THREE.Vector3 | THREE.Vector2,
    line2D: THREE.Vector3 | THREE.Vector2
  ): boolean {
    const det = (line1B.x - line1A.x) * (line2D.y - line2C.y) - (line2D.x - line2C.x) * (line1B.y - line1A.y);
    if (MathUtils.areNumbersEqual(det, 0)) {
      return false;
    }

    // Check that intersection lies between both sets of points
    const lambda = ((line2D.y - line2C.y) * (line2D.x - line1A.x) + (line2C.x - line2D.x) * (line2D.y - line1A.y)) / det;
    const gamma = ((line1A.y - line1B.y) * (line2D.x - line1A.x) + (line1B.x - line1A.x) * (line2D.y - line1A.y)) / det;
    return lambda > 0 && lambda < 1 && gamma > 0 && gamma < 1;
  }
  static isLineContainedInBoundingBox(line: THREE.Line3, bbox: THREE.Box3): boolean {
    return bbox.containsPoint(line.start) && bbox.containsPoint(line.end);
  }

  /**
   * Checks if a line intersects with a bounding box, with an optional tolerance to account for small discrepancies.
   *
   * @param {THREE.Line3} line - The line to be checked for intersection.
   * @param {THREE.Box3 | THREE.Box2} bbox - The bounding box (either 2D or 3D) to check against.
   * @param {number} [tolerance=0] - Optional tolerance value to account for precision issues. Default is 0.
   *
   * @returns {boolean} - Returns true if the line intersects the bounding box, considering the given tolerance; false otherwise.
   */
  static lineIntersectsBoundingBox(line: THREE.Line3, bbox: THREE.Box3 | THREE.Box2, tolerance: number = 0): boolean {
    const cLine = line.clone();
    const [axis, axis2] = GeometryUtils.isLineHorizontal(cLine) ? ["x", "y"] : ["y", "x"];

    // Ensure line starts from the lower axis value
    if (cLine.start[axis] > cLine.end[axis]) {
      const temp = cLine.start.clone();
      cLine.start = cLine.end;
      cLine.end = temp;
    }

    // Adjust bounding box boundaries by the tolerance
    const minAxis2 = bbox.min[axis2] - tolerance;
    const maxAxis2 = bbox.max[axis2] + tolerance;
    const minAxis = bbox.min[axis] - tolerance;
    const maxAxis = bbox.max[axis] + tolerance;

    // Check if line's start is within the tolerance-adjusted bbox boundaries
    if (cLine.start[axis2] >= minAxis2 && cLine.start[axis2] <= maxAxis2) {
      if (cLine.start[axis] <= minAxis) {
        if (cLine.end[axis] >= minAxis) {
          return true;
        }
      } else if (cLine.start[axis] <= maxAxis) {
        return true;
      }
    }

    return false;
  }

  /**
   * Finds the intersection of two line segments in 2D or 3D.
   *
   * @param {THREE.Line3} line1 - The first line segment.
   * @param {THREE.Line3} line2 - The second line segment.
   * @returns {THREE.Vector3 | null} - Returns the intersection point as a THREE.Vector3 if it exists, otherwise null.
   */
  static findIntersection(line1: THREE.Line3, line2: THREE.Line3): THREE.Vector3 | null {
    const dir1 = new THREE.Vector3().subVectors(line1.end, line1.start);
    const dir2 = new THREE.Vector3().subVectors(line2.end, line2.start);

    const denom = dir1.x * dir2.y - dir1.y * dir2.x;

    if (Math.abs(denom) < Number.EPSILON) {
      return null; // Lines are parallel or collinear
    }

    const t = ((line2.start.x - line1.start.x) * dir2.y - (line2.start.y - line1.start.y) * dir2.x) / denom;

    if (t < 0 || t > 1) {
      return null; // Intersection point not on the first line segment
    }

    const u = ((line2.start.x - line1.start.x) * dir1.y - (line2.start.y - line1.start.y) * dir1.x) / denom;

    if (u < 0 || u > 1) {
      return null; // Intersection point not on the second line segment
    }

    return line1.start.clone().add(dir1.multiplyScalar(t));
  }

  /**
   * Finds all intersection vertices of a line with a bounding box.
   *
   * @param {THREE.Line3} line - The line to check for intersections.
   * @param {THREE.Box3 | THREE.Box2} bbox - The bounding box (either 2D or 3D) to check against.
   * @returns {THREE.Vector3[]} - Returns an array of intersection vertices as THREE.Vector3 objects. If no intersections exist, returns an empty array.
   */
  static lineIntersectsBoundingBoxLines(line: THREE.Line3, bbox: THREE.Box3 | THREE.Box2): THREE.Vector3[] {
    const intersections: THREE.Vector3[] = [];
    const bboxEdges: THREE.Line3[] = [];

    // Convert the bounding box into edges
    if (bbox instanceof THREE.Box3) {
      const { min, max } = bbox;
      bboxEdges.push(
        new THREE.Line3(new THREE.Vector3(min.x, min.y, min.z), new THREE.Vector3(max.x, min.y, min.z)), // Bottom edge
        new THREE.Line3(new THREE.Vector3(max.x, min.y, min.z), new THREE.Vector3(max.x, max.y, min.z)), // Right edge
        new THREE.Line3(new THREE.Vector3(max.x, max.y, min.z), new THREE.Vector3(min.x, max.y, min.z)), // Top edge
        new THREE.Line3(new THREE.Vector3(min.x, max.y, min.z), new THREE.Vector3(min.x, min.y, min.z)) // Left edge
      );
    } else if (bbox instanceof THREE.Box2) {
      const { min, max } = bbox;
      bboxEdges.push(
        new THREE.Line3(new THREE.Vector3(min.x, min.y, 0), new THREE.Vector3(max.x, min.y, 0)), // Bottom edge
        new THREE.Line3(new THREE.Vector3(max.x, min.y, 0), new THREE.Vector3(max.x, max.y, 0)), // Right edge
        new THREE.Line3(new THREE.Vector3(max.x, max.y, 0), new THREE.Vector3(min.x, max.y, 0)), // Top edge
        new THREE.Line3(new THREE.Vector3(min.x, max.y, 0), new THREE.Vector3(min.x, min.y, 0)) // Left edge
      );
    } else {
      console.error("lineIntersectsBoundingBoxLines: Unsupported bounding box type", bbox);
      return []; // Unsupported bounding box type
    }

    // Check intersection for each edge of the bounding box
    bboxEdges.forEach(edge => {
      const intersection = this.findIntersection(line, edge);
      if (intersection) {
        intersections.push(intersection);
      }
    });

    // Return all intersections
    return intersections;
  }

  static isLineInsideLine(line: THREE.Line3, mainLine: THREE.Line3): boolean {
    const lineDir = GeometryUtils.isLineHorizontal(line) ? Direction.Horizontal : Direction.Vertical;
    const mainLineDir = GeometryUtils.isLineHorizontal(mainLine) ? Direction.Horizontal : Direction.Vertical;
    if (lineDir !== mainLineDir) {
      return false;
    }
    const [axis, axis2] = lineDir === Direction.Vertical ? ["x", "y"] : ["y", "x"];
    if (!MathUtils.areNumbersEqual(line.start[axis], mainLine.start[axis])) {
      return false;
    }
    if (
      MathUtils.isNumberInRange(line.start[axis2], mainLine.start[axis2], mainLine.end[axis2]) &&
      MathUtils.isNumberInRange(line.end[axis2], mainLine.start[axis2], mainLine.end[axis2])
    ) {
      return true;
    }
    return false;
  }

  //treating the lines as infinite
  static getLineIntersectionPoint(
    line1start: THREE.Vector3,
    line1end: THREE.Vector3,
    line2start: THREE.Vector3,
    line2end: THREE.Vector3
  ): THREE.Vector3 | null {
    const denominator = (line2end.y - line2start.y) * (line1end.x - line1start.x) - (line2end.x - line2start.x) * (line1end.y - line1start.y);
    if (MathUtils.areNumbersEqual(denominator, 0)) {
      return null;
    }
    let a = line1start.y - line2start.y;
    let b = line1start.x - line2start.x;
    const numerator1 = (line2end.x - line2start.x) * a - (line2end.y - line2start.y) * b;
    const numerator2 = (line1end.x - line1start.x) * a - (line1end.y - line1start.y) * b;
    a = numerator1 / denominator;
    b = numerator2 / denominator;
    return new THREE.Vector3(line1start.x + a * (line1end.x - line1start.x), line1start.y + a * (line1end.y - line1start.y));
  }

  static getLineSegmentsIntersectionPointParameters(
    start1: THREE.Vector3 | THREE.Vector2,
    end1: THREE.Vector3 | THREE.Vector2,
    start2: THREE.Vector3 | THREE.Vector2,
    end2: THREE.Vector3 | THREE.Vector2,
    epsilon = EPSILON
  ): number[] | null {
    const denominator = (end1.x - start1.x) * (end2.y - start2.y) - (end2.x - start2.x) * (end1.y - start1.y);
    if (MathUtils.areNumbersEqual(denominator, 0)) {
      return null;
    }

    const u = ((end2.y - start2.y) * (end2.x - start1.x) + (start2.x - end2.x) * (end2.y - start1.y)) / denominator;
    const v = ((start1.y - end1.y) * (end2.x - start1.x) + (end1.x - start1.x) * (end2.y - start1.y)) / denominator;

    // Check that intersection lies between both sets of points.
    if (MathUtils.isNumberInRange(u, 0, 1, epsilon) && MathUtils.isNumberInRange(v, 0, 1, epsilon)) {
      return [u, v];
    }

    return null;
  }
  static getLineSegmentsIntersectionPoint(
    start1: THREE.Vector3 | THREE.Vector2,
    end1: THREE.Vector3 | THREE.Vector2,
    start2: THREE.Vector3 | THREE.Vector2,
    end2: THREE.Vector3 | THREE.Vector2,
    epsilon = EPSILON
  ): THREE.Vector3 | null {
    const params = GeometryUtils.getLineSegmentsIntersectionPointParameters(start1, end1, start2, end2, epsilon);
    if (!params) {
      return null;
    }

    const u = params[0];
    return new THREE.Vector3(start1.x + u * (end1.x - start1.x), start1.y + u * (end1.y - start1.y));
  }

  static evaluateLineParameter(parameter: number, start: THREE.Vector3, end: THREE.Vector3): THREE.Vector3 {
    return end.clone().sub(start).multiplyScalar(parameter).add(start);
  }

  static getLineMidPoint(line: THREE.Line3): THREE.Vector3 {
    return GeometryUtils.getLineMidPoint2(line.start, line.end);
  }
  static getLineMidPoint2(startPoint: THREE.Vector3, endPoint: THREE.Vector3): THREE.Vector3 {
    return startPoint.clone().add(endPoint).multiplyScalar(0.5);
  }
  static getLocalLine3(line: THREE.Object3D): THREE.Line3 {
    const bb = GeometryUtils.getGeometryBoundingBox2D(line.clone());
    return new THREE.Line3(bb.min, bb.max);
  }

  static isPointInsidePolygon(polygonPoints: THREE.Vector3[], point: THREE.Vector3): boolean {
    const t = (p1: THREE.Vector3, p2: THREE.Vector3) => ((p2.x - p1.x) * (point.y - p1.y)) / (p2.y - p1.y) + p1.x;

    let inside = false;

    for (let i = -1, l = polygonPoints.length, j = l - 1; ++i < l; j = i) {
      const p1 = polygonPoints[i];
      const p2 = polygonPoints[j];

      ((p1.y <= point.y && point.y < p2.y) || (p2.y <= point.y && point.y < p1.y)) && point.x < t(p1, p2) && (inside = !inside);
    }

    return inside;
  }

  static isPolygonInsidePolygon(polygon: THREE.Vector3[], container: THREE.Vector3[]): boolean {
    for (let i = 0; i < polygon.length; i++) {
      const p1 = polygon[i];
      const p2 = polygon[(i + 1) % polygon.length];

      for (let j = 0; j < container.length; j++) {
        const q1 = container[j];
        const q2 = container[(j + 1) % container.length];

        if (GeometryUtils.doLinesIntersect(p1, p2, q1, q2)) {
          return false; // Lines intersect, polygons are not completely inside each other
        }
      }
    }

    // Check if one of the points of the polygon is inside container polygon
    return polygon.some(point => GeometryUtils.isPointInsidePolygon(container, point));
  }

  static getPointOnObject(object: THREE.Object3D): THREE.Vector3 {
    const geometry = (object as any).geometry;
    if (geometry) {
      if (geometry instanceof THREE.BufferGeometry && geometry.attributes?.position) {
        return new THREE.Vector3().fromBufferAttribute(geometry.attributes.position, 0).applyMatrix4(object.matrixWorld);
      }

      if (THREE.Geometry !== undefined && geometry instanceof THREE.Geometry) {
        return geometry.vertices[0].clone().applyMatrix4(object.matrixWorld);
      }
    }

    for (const child of object.children) {
      const point = this.getPointOnObject(child);
      if (point) {
        return point;
      }
    }
    return null;
  }
  static getPointsPositions(geometry: THREE.BufferGeometry): THREE.Vector3[] {
    const start = new THREE.Vector3().fromBufferAttribute(geometry.attributes.position, 0);
    const end = new THREE.Vector3().fromBufferAttribute(geometry.attributes.position, 1);
    return [start, end];
  }
  static doObjectsIntersect3D(obj1: THREE.Object3D, obj2: THREE.Object3D): boolean {
    const bb1 = GeometryUtils.getGeometryBoundingBox3D(obj1);
    const bb2 = GeometryUtils.getGeometryBoundingBox3D(obj2);

    return bb1.intersectsBox(bb2);
  }
  static isPointInsideBoundingBox(point: THREE.Vector3, bbox: THREE.Box3, tolerance = 1e-6) {
    // Check if the point's coordinates are within the bounds of the Box3, with the given tolerance
    return (
      point.x >= bbox.min.x - tolerance &&
      point.x <= bbox.max.x + tolerance &&
      point.y >= bbox.min.y - tolerance &&
      point.y <= bbox.max.y + tolerance &&
      point.z >= bbox.min.z - tolerance &&
      point.z <= bbox.max.z + tolerance
    );
  }
  static isPointOnBoundingBoxPerimeter(point: THREE.Vector3, bbox: THREE.Box3): boolean {
    const onMinX = MathUtils.areNumbersEqual(point.x, bbox.min.x) && point.y >= bbox.min.y && point.y <= bbox.max.y;
    const onMaxX = MathUtils.areNumbersEqual(point.x, bbox.max.x) && point.y >= bbox.min.y && point.y <= bbox.max.y;
    const onMinY = MathUtils.areNumbersEqual(point.y, bbox.min.y) && point.x >= bbox.min.x && point.x <= bbox.max.x;
    const onMaxY = MathUtils.areNumbersEqual(point.y, bbox.max.y) && point.x >= bbox.min.x && point.x <= bbox.max.x;

    return onMinX || onMaxX || onMinY || onMaxY;
  }
  static getGeometryBoundingBox3D(obj: THREE.Object3D): THREE.Box3 {
    return new THREE.Box3().setFromObject(obj);
  }
  static getGeometryBoundingBox2D(obj: THREE.Object3D): THREE.Box3 {
    const bb = this.getGeometryBoundingBox3D(obj);
    bb.min.z = bb.max.z = 0;
    return bb;
  }
  static getGeometryBoundingSphere(obj: THREE.Object3D): THREE.Sphere {
    const bb = this.getGeometryBoundingBox3D(obj);

    if (!bb.isEmpty()) {
      const result = new THREE.Sphere();
      bb.getBoundingSphere(result);
      return result.clone();
    } else {
      return new THREE.Sphere(new THREE.Vector3(), 1);
    }
  }

  static getSceneUnitPixels(camera: THREE.PerspectiveCamera, renderer?: THREE.WebGLRenderer): number {
    const vFOV = (camera.fov * Math.PI) / 180;
    const height = 2 * Math.tan(vFOV / 2) * Math.abs(camera.position.z);
    //const width = height * camera.aspect;

    const rect = renderer?.domElement.getBoundingClientRect();
    //return rect ? rect.width / width : 0.0; // pixels per scene unit
    return rect ? rect.height / height : 0.0; // pixels per scene unit
  }

  static zoomToFit2D(sphere: THREE.Sphere, camera: THREE.PerspectiveCamera, controls: TrackballControls): void {
    const distance = sphere.radius * ZOOM_TO_FIT_SIZE_FACTOR_2D;
    const c = sphere.center.clone();

    camera.position.copy(new THREE.Vector3(c.x, c.y, c.z + distance));
    camera.lookAt(c);
    camera.updateProjectionMatrix();

    controls.target.copy(c);
    controls.update();
  }
  static zoomToFit3D(sphere: THREE.Sphere, camera: THREE.PerspectiveCamera, controls: TrackballControls | OrbitControls): void {
    const lookAt = new THREE.Vector3(-1, 1, -1);
    const up = new THREE.Vector3(0, 0, 1);
    this.zoomToFit3DByDirection(sphere, lookAt, up, camera, controls);
  }
  static zoomToFit3DByQuaternion(
    sphere: THREE.Sphere,
    q: THREE.Quaternion,
    camera: THREE.PerspectiveCamera,
    controls: TrackballControls | OrbitControls
  ): void {
    camera.setRotationFromQuaternion(q);

    const lookAt = new THREE.Vector3(0, 0, -1).applyQuaternion(q).normalize();
    const up = new THREE.Vector3(0, 1, 0).applyQuaternion(q).normalize();
    this.zoomToFit3DByDirection(sphere, lookAt, up, camera, controls);
  }
  static zoomToFit3DByDirection(
    sphere: THREE.Sphere,
    lookAt: THREE.Vector3,
    up: THREE.Vector3,
    camera: THREE.PerspectiveCamera,
    controls: TrackballControls | OrbitControls
  ): void {
    camera.position.copy(lookAt.clone().negate().add(sphere.center));
    camera.up.copy(up);
    camera.lookAt(sphere.center);

    controls.target.copy(sphere.center);
    this.fitCameraDistance(sphere, camera, controls);
    controls.update();
  }
  static fitCameraDistance(sphere: THREE.Sphere, camera: THREE.PerspectiveCamera, controls: TrackballControls | OrbitControls): void {
    const p = camera.position.clone();
    const cameraTarget = controls.target.clone();

    const distance = sphere.radius * ZOOM_TO_FIT_SIZE_FACTOR_3D;

    const c = sphere.center.clone();
    const diff = cameraTarget.sub(p).normalize().multiplyScalar(distance);

    const newPosition = c.sub(diff);
    camera.position.copy(newPosition);

    // if (!isPerspectiveCamera) {
    // 	_originalAspect = renderer.domElement.width / renderer.domElement.height;
    // 	_aspect = _originalAspect;

    // 	if (_aspect > 1) {
    // 		_frustumSize = sphere.radius * 2;
    // 	} else {
    // 		_frustumSize = (sphere.radius * 2) / _aspect;
    // 	}

    // 	_camera.left = (-_frustumSize * _aspect) / 2.0;
    // 	_camera.right = (_frustumSize * _aspect) / 2.0;
    // 	_camera.top = _frustumSize / 2.0;
    // 	_camera.bottom = -_frustumSize / 2.0;
    // }

    camera.updateProjectionMatrix();
  }

  static getBoundingBoxCenterLine(bb: THREE.Box3): THREE.Line3 {
    const lineLeft = new THREE.Line3(new THREE.Vector3(bb.min.x, bb.min.y, bb.min.z), new THREE.Vector3(bb.min.x, bb.max.y, bb.min.z));
    const lineRight = new THREE.Line3(new THREE.Vector3(bb.max.x, bb.min.y, bb.min.z), new THREE.Vector3(bb.max.x, bb.max.y, bb.min.z));
    const lineBottom = new THREE.Line3(new THREE.Vector3(bb.min.x, bb.min.y, bb.min.z), new THREE.Vector3(bb.max.x, bb.min.y, bb.min.z));
    const lineTop = new THREE.Line3(new THREE.Vector3(bb.min.x, bb.max.y, bb.min.z), new THREE.Vector3(bb.max.x, bb.max.y, bb.min.z));

    const dA = lineLeft.distance();
    const dB = lineBottom.distance();

    if (dA > dB) {
      // vertical line
      return new THREE.Line3(GeometryUtils.getLineMidPoint(lineBottom), GeometryUtils.getLineMidPoint(lineTop));
    } else {
      // horizontal line
      return new THREE.Line3(GeometryUtils.getLineMidPoint(lineLeft), GeometryUtils.getLineMidPoint(lineRight));
    }
  }
  static doBoundingBoxesTouch(box1: THREE.Box3, box2: THREE.Box3): Direction {
    if (MathUtils.areNumbersEqual(box1.max.x, box2.min.x) || MathUtils.areNumbersEqual(box1.min.x, box2.max.x)) {
      return Direction.Horizontal;
    }

    if (MathUtils.areNumbersEqual(box1.max.y, box2.min.y) || MathUtils.areNumbersEqual(box1.min.y, box2.max.y)) {
      return Direction.Vertical;
    }

    return null;
  }
  static getBoundingBoxesTouch(bb1: THREE.Box3, bb2: THREE.Box3): { [key in Side]: boolean } {
    const result = {
      [Side.top]: false,
      [Side.right]: false,
      [Side.bottom]: false,
      [Side.left]: false,
    };

    if (Math.max(bb1.min.y, bb2.min.y) < Math.min(bb1.max.y, bb2.max.y) - EPSILON) {
      if (MathUtils.areNumbersEqual(bb1.max.x, bb2.min.x) || MathUtils.areNumbersEqual(bb1.max.x, bb2.max.x)) {
        result[Side.right] = true;
      }

      if (MathUtils.areNumbersEqual(bb1.min.x, bb2.max.x) || MathUtils.areNumbersEqual(bb1.min.x, bb2.min.x)) {
        result[Side.left] = true;
      }
    }

    if (Math.max(bb1.min.x, bb2.min.x) < Math.min(bb1.max.x, bb2.max.x) - EPSILON) {
      if (MathUtils.areNumbersEqual(bb1.max.y, bb2.min.y) || MathUtils.areNumbersEqual(bb1.max.y, bb2.max.y)) {
        result[Side.top] = true;
      }

      if (MathUtils.areNumbersEqual(bb1.min.y, bb2.max.y) || MathUtils.areNumbersEqual(bb1.min.y, bb2.min.y)) {
        result[Side.bottom] = true;
      }
    }

    return result;
  }
  static doBoundingBoxesIntersect(bbs1: THREE.Box3[], bbs2: THREE.Box3[]): boolean {
    return bbs1.some(bb1 => bbs2.some(bb2 => bb2.intersectsBox(bb1)));
  }
  static getBoundingBoxIntersectionArea(bb1: THREE.Box3, bb2: THREE.Box3): number {
    const xOverlap = Math.max(0, Math.min(bb1.max.x, bb2.max.x) - Math.max(bb1.min.x, bb2.min.x));
    const yOverlap = Math.max(0, Math.min(bb1.max.y, bb2.max.y) - Math.max(bb1.min.y, bb2.min.y));

    return xOverlap * yOverlap;
  }
  static positionToScreenPosition(position: THREE.Vector3, camera: THREE.Camera, container: DOMRect): { x: number; y: number } {
    if (!position || !container) return undefined;

    const screenPosition = new THREE.Vector3();

    screenPosition.copy(position);
    screenPosition.project(camera);

    const widthHalf = 0.5 * container.width;
    const heightHalf = 0.5 * container.height;

    screenPosition.x = screenPosition.x * widthHalf + widthHalf + container.x;
    screenPosition.y = -(screenPosition.y * heightHalf) + heightHalf + container.y;

    return {
      x: screenPosition.x,
      y: screenPosition.y,
    };
  }

  static deepClone(
    obj: THREE.Object3D,
    options: {
      cloneGeometry?: boolean | ((o: THREE.Object3D) => boolean);
      cloneMaterial?: boolean | ((o: THREE.Object3D) => boolean);
    } = { cloneGeometry: true, cloneMaterial: true }
  ): THREE.Object3D {
    const clone = obj.clone();

    if (options) {
      clone.traverse((child: any) => {
        if (
          options.cloneGeometry &&
          child.geometry &&
          (options.cloneGeometry.toString() === "true" || (typeof options.cloneGeometry === "function" && options.cloneGeometry(child)))
        ) {
          child.geometry = child.geometry.clone();
        }

        if (
          options.cloneMaterial &&
          child.material &&
          (options.cloneMaterial.toString() === "true" || (typeof options.cloneMaterial === "function" && options.cloneMaterial(child)))
        ) {
          child.material = child.material.clone();
        }

        // Deep clone userData
        if (child.userData && child.userData.segment && !(child.userData.segment instanceof Segment)) {
          const segData = child.userData.segment;

          child.userData.segment = new Segment(
            new THREE.Vector2(segData.start.x, segData.start.y),
            new THREE.Vector2(segData.end.x, segData.end.y),
            segData.roomId,
            segData.hasWall,
            segData.extras,
            segData.classification,
            segData.FunctionCode
          );
        }
      });
    }

    return clone;
  }
  static soRoomDeepClone(
    obj: soRoom2D,
    options: {
      cloneGeometry?: boolean | ((o: soRoom2D) => boolean);
      cloneMaterial?: boolean | ((o: soRoom2D) => boolean);
    } = { cloneGeometry: true, cloneMaterial: true }
  ): soRoom2D {
    try {
      const clone = obj.clone();

      if (options) {
        clone.traverse((child: any) => {
          if (
            options.cloneGeometry &&
            child.geometry &&
            (options.cloneGeometry.toString() === "true" || (typeof options.cloneGeometry === "function" && options.cloneGeometry(child)))
          ) {
            child.geometry = child.geometry.clone();
          }

          if (
            options.cloneMaterial &&
            child.material &&
            (options.cloneMaterial.toString() === "true" || (typeof options.cloneMaterial === "function" && options.cloneMaterial(child)))
          ) {
            child.material = child.material.clone();
          }

          // Deep clone userData
          if (child.userData && child.userData.segment && !(child.userData.segment instanceof Segment)) {
            const segData = child.userData.segment;

            child.userData.segment = new Segment(
              new THREE.Vector2(segData.start.x, segData.start.y),
              new THREE.Vector2(segData.end.x, segData.end.y),
              segData.roomId,
              segData.hasWall,
              segData.extras,
              segData.classification,
              segData.FunctionCode
            );
          }
        });
      }
      clone.remove(...clone.children.filter(child => child instanceof soRoomBoundary));
      if (obj["roomBoundary"]) {
        const lines = clone.children
          .filter(child => child.userData.type === RoomEntityType.ModelLine)
          .map(child => new soBoundaryLine(child.userData.id, child as THREE.Line));
        clone.setRoomBoundary(soRoomBoundary.fromBoundaryLines(lines));
      }
      if (obj["roomNetBoundary"]) {
        const lines = clone.children
          .filter(child => child.userData.type === RoomEntityType.RoomBoundaryLines)
          .map(child => new soBoundaryLine(child.userData.id, child as THREE.Line));
        clone.setNetRoomBoundary(soRoomBoundary.fromBoundaryLines(lines));
        clone.roomNetBoundary.overrideOriginalBoundingBox(clone.roomBoundary.OriginalBoundingBox);
      }
      // if (obj["roomNetBoundary"]) {
      //   const lines = obj.roomNetBoundary.boundaryLines;
      //   clone.setNetRoomBoundary(soRoomBoundary.fromBoundaryLines(lines));
      // }

      // Remove existing soDataBox instances from the clone
      clone.children.filter(child => child instanceof soDataBox).forEach(clone.remove.bind(clone));

      // Clone and add the dataBoxes array
      if (Array.isArray(obj["dataBoxes"])) {
        clone["dataBoxes"] = obj["dataBoxes"].map((dataBox: soDataBox) => {
          const clonedDataBox = dataBox.DeepCopy();
          clone.add(clonedDataBox); // Add the cloned dataBox to the clone
          return clonedDataBox;
        });
      }

      // Copy the isIndoor property from the original object
      clone.isIndoor = obj.isIndoor;

      return clone;
    } catch (error) {
      console.error(error);
    }
  }

  static createFilledPlane(bb: THREE.Box3, parameters?: THREE.MeshBasicMaterialParameters): THREE.Mesh {
    const size = BoundingBoxUtils.getBoundingBoxSize(bb);
    const center = BoundingBoxUtils.getBoundingBoxCenter(bb);

    const result = new THREE.Mesh(
      new THREE.PlaneBufferGeometry(size.x, size.y),
      new THREE.MeshBasicMaterial(parameters) //transparent: true, opacity: 1.0
    );
    result.position.copy(center);

    return result;
  }

  static getParallelPointsWithOffset(lineStart: THREE.Vector3, lineEnd: THREE.Vector3, offset: number): [THREE.Vector3, THREE.Vector3] {
    const normal = new THREE.Vector3()
      .subVectors(lineStart, lineEnd)
      .applyAxisAngle(new THREE.Vector3(0, 0, 1), -Math.PI * 0.5)
      .normalize()
      .setLength(offset);

    return [normal.clone().add(lineStart), normal.clone().add(lineEnd)];
  }

  // Eliminates elements that differs less than epsilon
  static deduplicateSites(sites: number[], epsilon: number = EPSILON): number[] {
    const orderedSites = sites.sort((a, b) => a - b);
    const uniqueSites: number[] = [];

    let similarSites: number[] = [];
    for (const site of orderedSites) {
      if (similarSites.length == 0 || MathUtils.areNumbersEqual(site, similarSites[0], epsilon)) {
        similarSites.push(site);
      } else {
        const uniqueSite = similarSites.reduce((sum, current) => sum + current, 0) / similarSites.length;
        uniqueSites.push(uniqueSite);
        similarSites = [site];
      }
    }

    // Ensure that we add the last sites as well
    if (similarSites.length > 0) {
      const uniqueSite = similarSites.reduce((sum, current) => sum + current, 0) / similarSites.length;
      uniqueSites.push(uniqueSite);
    }

    return uniqueSites;
  }

  static calculateSignedArea(points: THREE.Vector3[]): number {
    let area = 0;
    for (let i = 0; i < points.length; i++) {
      const start = points[i === 0 ? points.length - 1 : i - 1];
      const end = points[i];
      area += start.x * end.y - start.y * end.x;
    }
    return area * 0.5;
  }

  // assumes CCW polygon
  static assignSidesToPolygon(polygon: THREE.Vector3[]): Side[] {
    const n = polygon.length;
    const nextCyclicIndex = (i: number): number => {
      return (i + 1) % n;
    };
    const prevCyclicIndex = (i: number): number => {
      return i === 0 ? n - 1 : i - 1;
    };
    const areCollinear = (v1: THREE.Vector3, v2: THREE.Vector3, tol: number): boolean => {
      const dot = v1.dot(v2);
      return dot > 0 && dot * dot > tol * tol * v1.lengthSq() * v2.lengthSq();
    };

    // find extreme points (East, West, South, North)
    const extremeIndices = [-1, -1, -1, -1];
    let eastSouthPoint = new THREE.Vector3(Number.NEGATIVE_INFINITY, Number.POSITIVE_INFINITY);
    let westNorthPoint = new THREE.Vector3(Number.POSITIVE_INFINITY, Number.NEGATIVE_INFINITY);
    let southWestPoint = new THREE.Vector3(Number.POSITIVE_INFINITY, Number.POSITIVE_INFINITY);
    let northEastPoint = new THREE.Vector3(Number.NEGATIVE_INFINITY, Number.NEGATIVE_INFINITY);

    for (let i = 0; i < polygon.length; ++i) {
      const point = polygon[i];
      if (point.x > eastSouthPoint.x + EPSILON || (point.x > eastSouthPoint.x - EPSILON && point.y <= eastSouthPoint.y)) {
        eastSouthPoint = point;
        extremeIndices[0] = i;
      }
      if (point.x < westNorthPoint.x - EPSILON || (point.x < westNorthPoint.x + EPSILON && point.y >= westNorthPoint.y)) {
        westNorthPoint = point;
        extremeIndices[1] = i;
      }
      if (point.y < southWestPoint.y - EPSILON || (point.y < southWestPoint.y + EPSILON && point.x <= southWestPoint.x)) {
        southWestPoint = point;
        extremeIndices[2] = i;
      }
      if (point.y > northEastPoint.y + EPSILON || (point.y > northEastPoint.y - EPSILON && point.x >= northEastPoint.x)) {
        northEastPoint = point;
        extremeIndices[3] = i;
      }
    }

    const polygonSides: Side[] = [];
    const tol = 0.70710678; //cos(45deg) rounded down to 8 digit, so we had weakly less than 45 deg for collinearity checks
    // Directions in assignment priority
    const baseDirection = [
      new THREE.Vector3(1, 0), // South
      new THREE.Vector3(-1, 0), // North
      new THREE.Vector3(0, 1), // East
      new THREE.Vector3(0, -1), // West
    ];

    for (let i = 0; i < n; i++) {
      polygonSides[i] = null;
      const edge = polygon[nextCyclicIndex(i)].clone().sub(polygon[i]);
      for (let dirIdx = 0; dirIdx < 4; ++dirIdx) {
        if (areCollinear(edge, baseDirection[dirIdx], tol)) {
          switch (dirIdx) {
            case 0: {
              polygonSides[i] = Side.bottom;
              break;
            }
            case 1: {
              polygonSides[i] = Side.top;
              break;
            }
            case 2: {
              polygonSides[i] = Side.right;
              break;
            }
            case 3: {
              polygonSides[i] = Side.left;
              break;
            }
          }
          break;
        }
      }
    }

    // Fill up the results
    const segmentSides: Side[] = [];

    //Iterate from West through South to the East and find first and last correctly oriented segments
    let startSouthIdx = -1;
    let endSouthIdx = -1;
    for (let i = extremeIndices[1]; i != extremeIndices[0]; i = nextCyclicIndex(i)) {
      if (polygonSides[i] === Side.bottom) {
        endSouthIdx = i;
        if (startSouthIdx === -1) {
          startSouthIdx = i;
        }
      }
    }

    if (startSouthIdx !== -1) {
      for (let i = startSouthIdx, end = nextCyclicIndex(endSouthIdx); i != end; i = nextCyclicIndex(i)) {
        polygonSides[i] = segmentSides[i] = Side.bottom;
      }
    }

    //Iterate from East through North to the West and find first and last correctly oriented segments
    let startNorthIdx = -1;
    let endNorthIdx = -1;
    for (let i = extremeIndices[0]; i != extremeIndices[1]; i = nextCyclicIndex(i)) {
      if (polygonSides[i] === Side.top) {
        endNorthIdx = i;
        if (startNorthIdx === -1) {
          startNorthIdx = i;
        }
      }
    }

    if (startNorthIdx !== -1) {
      for (let i = startNorthIdx, end = nextCyclicIndex(endNorthIdx); i != end; i = nextCyclicIndex(i)) {
        polygonSides[i] = segmentSides[i] = Side.top;
      }
    }

    const westStart = endNorthIdx !== -1 ? nextCyclicIndex(endNorthIdx) : extremeIndices[3];
    const westEnd = prevCyclicIndex(startSouthIdx !== -1 ? startSouthIdx : extremeIndices[2]);

    const eastStart = endSouthIdx !== -1 ? nextCyclicIndex(endSouthIdx) : extremeIndices[2];
    const eastEnd = prevCyclicIndex(startNorthIdx !== -1 ? startNorthIdx : extremeIndices[3]);

    for (let i = westStart, end = nextCyclicIndex(westEnd); i != end; i = nextCyclicIndex(i)) {
      if (segmentSides[i] === undefined) {
        segmentSides[i] = Side.left;
      }
    }

    for (let i = eastStart, end = nextCyclicIndex(eastEnd); i != end; i = nextCyclicIndex(i)) {
      if (segmentSides[i] === undefined) {
        segmentSides[i] = Side.right;
      }
    }

    return segmentSides;
  }

  // assumes CCW polygon
  static assignSidesToLotLinePolygon(polygon: THREE.Vector3[]): Side[] {
    const nextCyclicIndex = (i: number, n: number = polygon.length): number => {
      return (i + 1) % n;
    };

    const hull = GeometryUtils.findConvexHull(polygon);
    const hullAssignments = GeometryUtils.assignSidesToPolygon(hull);

    const segmentSides: Side[] = [];

    for (let i = 0, l = hull.length; i < l; i++) {
      const startIdx = polygon.indexOf(hull[i]);
      const endIdx = polygon.indexOf(hull[nextCyclicIndex(i, l)]);

      for (let j = startIdx; j !== endIdx; j = nextCyclicIndex(j)) {
        segmentSides[j] = hullAssignments[i];
      }
    }

    return segmentSides;
  }

  // returns CCW polygon
  static findConvexHull(points: THREE.Vector3[]): THREE.Vector3[] {
    // Find the leftmost point
    let pointOnHull: THREE.Vector3 = points[0];
    for (const point of points) {
      if (point.x < pointOnHull.x || (point.x === pointOnHull.x && point.y < pointOnHull.y)) {
        pointOnHull = point;
      }
    }

    // Build the convex hull
    const result: THREE.Vector3[] = [];

    while (result[0] !== pointOnHull) {
      let nextPoint = points[0];
      for (let j = 1, l = points.length; j < l; j++) {
        if (nextPoint === pointOnHull || GeometryUtils.isRightOf(points[j], pointOnHull, nextPoint)) {
          nextPoint = points[j];
        }
      }

      result.push(pointOnHull);
      pointOnHull = nextPoint;
    }

    return result;
  }
  static isRightOf(point: THREE.Vector3, start: THREE.Vector3, end: THREE.Vector3): boolean {
    // Check if point is on the right side of the line (start, end)
    const cross = (end.x - start.x) * (point.y - start.y) - (point.x - start.x) * (end.y - start.y);

    // If the point lies on the same line, check if it lies further away from 'start'.
    if (cross === 0) {
      const p = new THREE.Line3(start, end).closestPointToPointParameter(point, false);
      return p > 1;
    }

    return cross < 0;
  }

  static addOffsetToContour(contour: THREE.Vector3[], offset: number): THREE.Vector3[] {
    const result: THREE.Vector3[] = [];

    for (let i = 0; i < contour.length; i++) {
      const p1 = contour[i === 0 ? contour.length - 1 : i - 1];
      const p2 = contour[i];
      const p3 = contour[i === contour.length - 1 ? 0 : i + 1];

      const delta1 = p3.clone().sub(p2);
      const delta2 = p2.clone().sub(p1);
      // 90 degrees clockwise
      const a = new THREE.Vector3(delta1.y, -delta1.x).normalize().multiplyScalar(offset);
      const b = new THREE.Vector3(delta2.y, -delta2.x).normalize().multiplyScalar(offset);

      if (MathUtils.areNumbersEqual(a.clone().cross(b).lengthSq(), 0)) {
        b.multiplyScalar(0);
      }

      const point = a.add(b).add(p2);
      result.push(point);
    }

    return result;
  }

  static addOffsetToContour2D(contour: THREE.Vector2[], offset: number): THREE.Vector2[] {
    const result: THREE.Vector2[] = [];
    // Function to calculate the angle between two vectors
    function angleBetween(v1, v2): number {
      const dot = v1.dot(v2);
      const det = v1.x * v2.y - v1.y * v2.x; // determinant
      const angle = Math.atan2(det, dot);
      return angle;
    }
    for (let i = 0; i < contour.length; i++) {
      const p1 = contour[i === 0 ? contour.length - 1 : i - 1];
      const p2 = contour[i];
      const p3 = contour[i === contour.length - 1 ? 0 : i + 1];
      const delta1 = p3.clone().sub(p2).normalize();
      const delta2 = p2.clone().sub(p1).normalize();
      // Calculating offset vectors
      const a = new THREE.Vector2(delta1.y, -delta1.x).multiplyScalar(offset);
      const b = new THREE.Vector2(delta2.y, -delta2.x).multiplyScalar(offset);
      // Calculate the angle to check for collinearity
      const angle = angleBetween(delta1, delta2);
      // For near-collinear or actual collinear points, just average the offsets
      if (Math.abs(angle) < 0.01) {
        // Arbitrary small angle threshold
        const avgOffset = a.add(b).multiplyScalar(0.5);
        result.push(new THREE.Vector2(p2.x + avgOffset.x, p2.y + avgOffset.y));
      } else {
        // Not collinear, proceed with the usual offset logic
        const offsetPoint = p2.clone().add(a).add(b);
        result.push(offsetPoint);
      }
    }
    return result;
  }

  static addContourToPath(path: THREE.Path, contour: THREE.Vector3[]): void {
    if (contour.length === 0) {
      return;
    }

    path.moveTo(contour[0].x, contour[0].y);
    for (let i = 1; i < contour.length; i++) {
      path.lineTo(contour[i].x, contour[i].y);
    }
    path.closePath();
  }

  static getSpaceIntersectionPoint(mousePosition: THREE.Vector2, camera: THREE.Camera, zoomTarget: THREE.Vector3): THREE.Vector3 {
    const result = new THREE.Vector3();

    const hoveredPoint = new THREE.Vector3(mousePosition.x, mousePosition.y, 1 - EPSILON);
    hoveredPoint.unproject(camera);

    // set plane
    const point = zoomTarget.clone();
    const planeNormal = new THREE.Vector3();
    planeNormal.subVectors(camera.position, point).normalize();
    const plane = new THREE.Plane();
    plane.setFromNormalAndCoplanarPoint(planeNormal, point);

    if (camera instanceof THREE.PerspectiveCamera) {
      // find intersection point of the line and the plane:
      const planeIntersection = new THREE.Vector3();
      plane.intersectLine(new THREE.Line3(camera.position, hoveredPoint), planeIntersection);
      result.copy(planeIntersection);
    } else {
      plane.projectPoint(hoveredPoint, result);
    }

    return result;
  }

  // Assumes start point is to the left/bottom of the end point.
  static getLineSegmentInsideSphere(line: THREE.Line3, sphere: THREE.Sphere): THREE.Line3 | null {
    const isStartInCircle = sphere.containsPoint(line.start);
    const isEndInCircle = sphere.containsPoint(line.end);
    if (isStartInCircle && isEndInCircle) {
      return line;
    }
    let closestLinePointToCenter = line.closestPointToPoint(sphere.center, true, new THREE.Vector3());
    let distance = closestLinePointToCenter.distanceTo(sphere.center);

    if (distance + EPSILON > sphere.radius) {
      return null;
    }

    closestLinePointToCenter = line.closestPointToPoint(sphere.center, false, closestLinePointToCenter);
    distance = closestLinePointToCenter.distanceTo(sphere.center);
    const newLineDistance = Math.sqrt(sphere.radius * sphere.radius - distance * distance);
    const vector = GeometryUtils.isLineHorizontal(line) ? new THREE.Vector3(1, 0, 0) : new THREE.Vector3(0, 1, 0);

    const start = isStartInCircle ? line.start.clone() : closestLinePointToCenter.clone().addScaledVector(vector, -newLineDistance);
    const end = isEndInCircle ? line.end.clone() : closestLinePointToCenter.clone().addScaledVector(vector, +newLineDistance);
    return new THREE.Line3(start, end);
  }

  static lineSegmentIntersectSphere(line: THREE.Line3, sphere: THREE.Sphere): THREE.Vector3[] | null {
    const lineDelta = line.delta(new THREE.Vector3());
    const lineDelta2 = lineDelta.lengthSq();
    if (MathUtils.areNumbersEqual(lineDelta2, 0)) {
      return null;
    }

    const delta = line.start.clone().sub(sphere.center);
    const discriminant = lineDelta.dot(delta) ** 2 - lineDelta2 * (delta.lengthSq() - sphere.radius ** 2);

    if (discriminant < 0) {
      return null;
    }

    if (MathUtils.areNumbersEqual(discriminant, 0)) {
      const t = -lineDelta.dot(delta) / lineDelta2;
      if (!MathUtils.isNumberInRange(t, 0, 1)) {
        return null;
      }

      return [GeometryUtils.evaluateLineParameter(t, line.start, line.end)];
    }

    const b = lineDelta.dot(delta);
    const root = Math.sqrt(discriminant);
    const t1 = (-b - root) / lineDelta2;
    const t2 = (-b + root) / lineDelta2;

    const results: THREE.Vector3[] = [];

    if (MathUtils.isNumberInRange(t1, 0, 1)) {
      results.push(GeometryUtils.evaluateLineParameter(t1, line.start, line.end));
    }

    if (MathUtils.isNumberInRange(t2, 0, 1)) {
      results.push(GeometryUtils.evaluateLineParameter(t2, line.start, line.end));
    }

    return results.length ? results : null;
  }

  static setChildrenLinesColor(obj: THREE.Object3D, color: number, setOriginalColor = false) {
    obj?.traverse(child => {
      if (child instanceof THREE.Line) {
        child.material.color.setHex(color);
        if (setOriginalColor && child.userData.originalColor) {
          child.userData.originalColor = color;
        }
      }
    });
  }
  static setChildrenMeshColor(obj: THREE.Object3D, color: number) {
    obj?.traverse(child => {
      if (child instanceof THREE.Mesh) {
        child.material.color.setHex(color);
      }
    });
  }
  static setStencilMask(obj: THREE.Object3D, stencilRef: number) {
    obj?.traverse(child => {
      if (child instanceof THREE.Mesh || child instanceof THREE.Line) {
        child.material.stencilWrite = true;
        child.material.stencilRef = stencilRef;
        child.material.stencilFunc = THREE.NotEqualStencilFunc;
        child.material.stencilFail = THREE.KeepStencilOp;
        child.material.stencilZFail = THREE.KeepStencilOp;
        child.material.stencilZPass = THREE.KeepStencilOp;
      }
    });
  }
  static setChildrenRenderOrder(obj: THREE.Object3D, renderOrder: number) {
    obj?.traverse(child => {
      if (child instanceof THREE.Mesh || child instanceof THREE.Line || child instanceof THREE.Points) {
        child.renderOrder = renderOrder;
      }
    });
  }
  static setChildrenMeshRenderOrder(obj: THREE.Object3D, renderOrder: number) {
    obj?.traverse(child => {
      if (child instanceof THREE.Mesh) {
        child.renderOrder = renderOrder;
      }
    });
  }

  static swapLineVectors(line: THREE.Line3): THREE.Line3 {
    const tmp = line.start;
    line.start = line.end;
    line.end = tmp;

    return line;
  }

  static alignLine(line: THREE.Line3): THREE.Line3 {
    const axis = GeometryUtils.isLineHorizontal(line) ? "x" : "y";
    if (line.start[axis] > line.end[axis]) {
      GeometryUtils.swapLineVectors(line);
    }

    return line;
  }

  static getFitToSizeFactor2D(dstSize: THREE.Vector2, srcSize: THREE.Vector2): number {
    const kx = dstSize.x / srcSize.x;
    const ky = dstSize.y / srcSize.y;
    return Math.min(kx, ky);
  }

  // quality = 0...1
  static getMaxRenderSize(aspect: number, quality: number = 1.0): THREE.Vector2 {
    const maxSize = 4096 * Math.min(1.0, Math.abs(quality)); // WebGL
    const w = aspect >= 1.0 ? maxSize : maxSize * aspect;
    const h = w / aspect;
    return new THREE.Vector2(w, h);
  }

  static reintroduceCollinearPoints(path: THREE.Vector2[], originalPoints: THREE.Vector2[]): THREE.Vector2[] {
    // Function to check if a point is collinear with a segment
    function isPointCollinear(point: THREE.Vector2, segmentStart: THREE.Vector2, segmentEnd: THREE.Vector2): boolean {
      const crossProduct = (segmentEnd.y - segmentStart.y) * (point.x - segmentStart.x) - (segmentEnd.x - segmentStart.x) * (point.y - segmentStart.y);
      return Math.abs(crossProduct) < Number.EPSILON;
    }
    // Function to check if point is between two points on a line segment
    function isPointBetween(point: THREE.Vector2, segmentStart: THREE.Vector2, segmentEnd: THREE.Vector2): boolean {
      const dotProduct = (point.x - segmentStart.x) * (segmentEnd.x - segmentStart.x) + (point.y - segmentStart.y) * (segmentEnd.y - segmentStart.y);
      const squaredLength = (segmentEnd.x - segmentStart.x) ** 2 + (segmentEnd.y - segmentStart.y) ** 2;
      return dotProduct > 0 && dotProduct < squaredLength;
    }
    // Iterate over each segment in the path
    const newPath = [...path]; // Clone the path to avoid mutating the original
    newPath.push(newPath[0]); // Close the path
    for (let i = 0; i < newPath.length - 1; i++) {
      const segmentStart = newPath[i];
      const segmentEnd = newPath[i + 1];
      // Find original points that are collinear with the current segment
      const collinearPoints = originalPoints.filter(
        point => isPointCollinear(point, segmentStart, segmentEnd) && isPointBetween(point, segmentStart, segmentEnd)
      );
      // Sort collinear points by their distance from the segment start
      collinearPoints.sort((a, b) => {
        const distA = (a.x - segmentStart.x) ** 2 + (a.y - segmentStart.y) ** 2;
        const distB = (b.x - segmentStart.x) ** 2 + (b.y - segmentStart.y) ** 2;
        return distA - distB;
      });
      // Insert collinear points into newPath at the correct position
      newPath.splice(i + 1, 0, ...collinearPoints);
      i += collinearPoints.length; // Adjust index to account for newly inserted points
    }
    newPath.pop(); // Remove the closing point
    return newPath;
  }

  /**
   * @deprecated Use offsetSoLines instead
   */
  static offsetLines(lines: THREE.Object3D[], offsetDistance: number, creationType: string = ""): THREE.Object3D[] {
    const offsettedLines = lines.map(line => {
      // Check if the object is a Line with BufferGeometry

      // If not a line or doesn't have the expected structure, return the original object
      return GeometryUtils.offsetline(line, offsetDistance, creationType);
    });
    return offsettedLines;
  }

  static offsetSoLines(lines: soBoundaryLine[], offsetDistance: number, creationType: string = ""): soBoundaryLine[] {
    const offsettedLines = lines.map(line => {
      // If not a line or doesn't have the expected structure, return the original object
      return new soBoundaryLine(line.soId, GeometryUtils.offsetline(line.line, offsetDistance, creationType));
    });

    return offsettedLines;
  }

  static offsetline(line: THREE.Object3D, offsetDistance: number, creationType: string = ""): THREE.Line {
    if (line instanceof THREE.Line && line.geometry instanceof THREE.BufferGeometry) {
      const positionAttribute = line.geometry.attributes.position;

      // Ensure there are at least two points
      if (positionAttribute.count >= 2) {
        // Extract the start and end points
        const start = new THREE.Vector3(positionAttribute.getX(0), positionAttribute.getY(0), positionAttribute.getZ(0));
        const end = new THREE.Vector3(positionAttribute.getX(1), positionAttribute.getY(1), positionAttribute.getZ(1));

        // Calculate the direction and normal vectors
        const direction = new THREE.Vector3().subVectors(end, start).normalize();
        const normal = new THREE.Vector3(-direction.y, direction.x, 0).normalize();
        const offsetVector = normal.multiplyScalar(offsetDistance);
        const offsetInDirection = direction.multiplyScalar(offsetDistance);
        // Offset the start and end points
        const newStart = new THREE.Vector3().addVectors(start, offsetVector).add(offsetInDirection);
        const newEnd = new THREE.Vector3().addVectors(end, offsetVector).sub(offsetInDirection);
        const material =
          creationType == RoomEntityType.ModelLine
            ? new THREE.LineDashedMaterial({
                color: MODEL_LINE_COLOR,
                dashSize: 0.3 * UnitsUtils.getConversionFactor(),
                gapSize: 0.3 * UnitsUtils.getConversionFactor(),
                transparent: true,
                opacity: 1.0,
              })
            : new THREE.LineBasicMaterial({
                color: MODEL_LINE_COLOR,
                linewidth: line.material.linewidth,
              });
        // Create a new geometry with the offset points
        const result = new THREE.Line(new THREE.BufferGeometry().setFromPoints([newStart, newEnd]), material);
        result.renderOrder = MODEL_LINE_RENDER_ORDER + 10000;
        result.computeLineDistances();

        // Assign name and userData based on the creationType
        result.name = creationType;
        result.userData = {
          id: uuidv4(),
          type: creationType,
        };
        return result;
      }
    }
  }

  /**
   * Creates a bounding box from an array of lines representing a rectangle
   * and generates new lines from the bounding box in a clockwise direction.
   *
   * @param {THREE.Object3D[]} lines - An array of THREE.Object3D objects representing the lines.
   * @returns {THREE.Object3D[]} - An array of new lines created from the bounding box in a clockwise direction.
   * @throws {Error} - Throws an error if the input lines are not properly defined.
   * *@deprecated Use orderRectangleSoLinesClockwise instead
   */
  static orderRectangleLinesClockwise(lines: THREE.Object3D[]): THREE.Object3D[] {
    if (lines.length === 0) {
      throw new Error("The input must contain at least one line to form a bounding box.");
    }

    // Extract all points from the input lines
    const allPoints: THREE.Vector3[] = [];
    lines.forEach(line => {
      if (line instanceof THREE.Line && line.geometry instanceof THREE.BufferGeometry) {
        const position = line.geometry.attributes.position;
        for (let i = 0; i < position.count; i++) {
          allPoints.push(new THREE.Vector3(position.getX(i), position.getY(i), position.getZ(i)));
        }
      } else {
        throw new Error("Line geometry must be of type THREE.BufferGeometry.");
      }
    });

    // Create a bounding box from all points
    const boundingBox = new THREE.Box3().setFromPoints(allPoints);
    const min = boundingBox.min;
    const max = boundingBox.max;

    // Define the corners of the bounding box in a clockwise direction
    const bottomLeft = new THREE.Vector3(min.x, min.y, min.z);
    const bottomRight = new THREE.Vector3(max.x, min.y, min.z);
    const topRight = new THREE.Vector3(max.x, max.y, min.z);
    const topLeft = new THREE.Vector3(min.x, max.y, min.z);

    // Create new lines from the bounding box corners
    const newLines: THREE.Object3D[] = [];
    const corners = [bottomLeft, bottomRight, topRight, topLeft, bottomLeft]; // Loop back to the start to close the rectangle

    for (let i = 0; i < corners.length - 1; i++) {
      const start = corners[i];
      const end = corners[i + 1];
      const geometry = new THREE.BufferGeometry().setFromPoints([start, end]);
      const material = new THREE.LineBasicMaterial({ color: 0x000000 });
      const line = new THREE.Line(geometry, material);
      newLines.push(line);
    }

    return newLines;
  }

  /**
   * Creates a bounding box from an array of lines representing a rectangle
   * and generates new lines from the bounding box in a clockwise direction.
   *
   * @param {THREE.Object3D[]} lines - An array of THREE.Object3D objects representing the lines.
   * @returns {THREE.Object3D[]} - An array of new lines created from the bounding box in a clockwise direction.
   * @throws {Error} - Throws an error if the input lines are not properly defined.
   */
  static orderRectangleSoLinesClockwise(soBoundaryLines: soBoundaryLine[]): soBoundaryLine[] {
    if (soBoundaryLines.length === 0) {
      throw new Error("The input must contain at least one line to form a bounding box.");
    }

    // Extract all points from the input lines
    const allPoints: THREE.Vector3[] = [];
    soBoundaryLines.forEach(so => {
      if (so.line instanceof THREE.Line && so.line.geometry instanceof THREE.BufferGeometry) {
        const position = so.line.geometry.attributes.position;
        for (let i = 0; i < position.count; i++) {
          allPoints.push(new THREE.Vector3(position.getX(i), position.getY(i), position.getZ(i)));
        }
      } else {
        throw new Error("Line geometry must be of type THREE.BufferGeometry.");
      }
    });

    // Create a bounding box from all points
    const boundingBox = new THREE.Box3().setFromPoints(allPoints);
    const min = boundingBox.min;
    const max = boundingBox.max;

    // Define the corners of the bounding box in a clockwise direction
    const bottomLeft = new THREE.Vector3(min.x, min.y, min.z);
    const bottomRight = new THREE.Vector3(max.x, min.y, min.z);
    const topRight = new THREE.Vector3(max.x, max.y, min.z);
    const topLeft = new THREE.Vector3(min.x, max.y, min.z);

    // Create new lines from the bounding box corners
    const newLines: soBoundaryLine[] = [];
    // const newLines: THREE.Object3D[] = [];
    const corners = [bottomLeft, bottomRight, topRight, topLeft, bottomLeft]; // Loop back to the start to close the rectangle

    for (let i = 0; i < corners.length - 1; i++) {
      const start = corners[i];
      const end = corners[i + 1];
      const geometry = new THREE.BufferGeometry().setFromPoints([start, end]);
      const material = new THREE.LineBasicMaterial({ color: 0x000000 });
      const line = new THREE.Line(geometry, material);
      const soLine = new soBoundaryLine(soBoundaryLines[i].soId, line);
      newLines.push(soLine);
    }

    return newLines;
  }
}
