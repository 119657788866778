import React, { useState } from "react";
import { Dropdown, Menu, Tooltip } from "antd";
import AlignVerticalTopRoundedIcon from "@mui/icons-material/AlignVerticalTopRounded";
import AlignVerticalCenterRoundedIcon from "@mui/icons-material/AlignVerticalCenterRounded";
import AlignVerticalBottomRoundedIcon from "@mui/icons-material/AlignVerticalBottomRounded";
import AlignHorizontalLeftRoundedIcon from "@mui/icons-material/AlignHorizontalLeftRounded";
import AlignHorizontalCenterRoundedIcon from "@mui/icons-material/AlignHorizontalCenterRounded";
import AlignHorizontalRightRoundedIcon from "@mui/icons-material/AlignHorizontalRightRounded";

import wallAlignmentManager from "../../../../../editor/managers/SceneManager/wallAlignmentManager";
import { catalogSettings } from "../../../../../entities/catalogSettings";
import { WallLabel, WallType } from "../../../../../entities/catalogSettings/types";
import { formatToInches } from "../../../../../helpers/utilities";
import { appModel } from "../../../../../models/AppModel";
import wallFunctionRules from "../../../../../entities/wallFunctionCode/WallFunctionRules";

import "./WallProperties.sass";
import { soWall2D } from "../../../../../editor/models/SceneObjects/Wall/soWall2D";

interface ItemProps {
  title: string;
  value?: string | number;
  isSelect?: boolean;
}

const wallPropertiesConfig: Record<string, { label: string; errorMessage: string }> = {
  Classification: { label: "Classification", errorMessage: "Unknown wall class" },
  "Core Type": { label: "Core Type", errorMessage: "Cannot retrieve data" },
  "Total Thickness": { label: "Total Thickness", errorMessage: "Cannot retrieve data" },
  Offset: { label: "Offset", errorMessage: "Cannot retrieve data" },
  Alignment: { label: "Alignment", errorMessage: "Cannot retrieve data" },
  FunctionCode: { label: "FunctionCode", errorMessage: "Cannot retrieve data" },
  ID: { label: "Id", errorMessage: "Cannot retrieve data" },
};

interface ButtonGroupProps {
  alignment: "vertical" | "horizontal";
}

interface WallPropertiesProps {
  multiWallMode: boolean;
  isSelectedRoomWallsEligible: boolean;
  isHorizontal: boolean;
}

const iconMap = {
  vertical: [
    { Icon: AlignVerticalTopRoundedIcon, alt: "Horizontal Top", position: "top" },
    { Icon: AlignVerticalCenterRoundedIcon, alt: "Horizontal Center", position: "center" },
    { Icon: AlignVerticalBottomRoundedIcon, alt: "Horizontal Bottom", position: "bottom" },
  ],
  horizontal: [
    { Icon: AlignHorizontalLeftRoundedIcon, alt: "Vertical Left", position: "left" },
    { Icon: AlignHorizontalCenterRoundedIcon, alt: "Vertical Center", position: "center" },
    { Icon: AlignHorizontalRightRoundedIcon, alt: "Vertical Right", position: "right" },
  ],
};

const ButtonGroup: React.FC<ButtonGroupProps> = ({ alignment }) => {
  const handleClick = position => {
    wallAlignmentManager.alignSelectedWallsByPosition(position);
  };

  return (
    <div className="button-group">
      {iconMap[alignment].map((icon, index) => (
        <Tooltip key={index} title={icon.alt}>
          <button key={icon.alt} className="icon-button" onClick={() => handleClick(icon.position)}>
            <icon.Icon className="icon" />
          </button>
        </Tooltip>
      ))}
    </div>
  );
};

const WallProperties: React.FC<WallPropertiesProps> = ({ multiWallMode, isSelectedRoomWallsEligible, isHorizontal }) => {
  const selectedRoomWallSegment = [...appModel.selectedRoomSoWall][0] as soWall2D;

  // Find the first modified segment that matches the selected room wall segment
  const selectedSegmentOrModified = appModel.findModifiedSegmentWall(selectedRoomWallSegment) || selectedRoomWallSegment;
  // round the double value to integer like 0.9999999999999999 to 1
  const offset =
    (isHorizontal ? Math.round(selectedSegmentOrModified.getOffsetVector().y) : Math.round(selectedSegmentOrModified.getOffsetVector().x)) || "No offset";

  const classification = selectedSegmentOrModified?.wallTypeTags?.map(cl => {
    return WallLabel[cl];
  });
  let keyIndex = 1;

  const onSelectFunctionCode = functionCode => {
    appModel.baseManager.roomManager.setWidthToSelectedRooms(functionCode);
  };

  const classificationKey = selectedSegmentOrModified.FunctionCode;
  const coreType = `${catalogSettings.walls[classificationKey]?.frameType || ""} ${catalogSettings.walls[classificationKey]?.frameSize || "Unknown"}`;

  const rulers = wallFunctionRules.rules.filter(rule => {
    const ruleClassifications: WallType[] = rule.conditions.classification;

    return (
      ruleClassifications.length === selectedSegmentOrModified.wallTypeTags.length &&
      ruleClassifications.every((cls: WallType) => selectedSegmentOrModified.wallTypeTags.includes(cls)) &&
      selectedSegmentOrModified.wallTypeTags.every((cls: WallType) => ruleClassifications.includes(cls))
    );
  });

  const coreTypeSizeList = rulers.map(rule => {
    return {
      label: `${catalogSettings.walls[rule.functionCode].frameType} ${catalogSettings.walls[rule.functionCode].frameSize}`,
      value: rule.functionCode,
      key: keyIndex++,
      onClick: () => onSelectFunctionCode(rule.functionCode),
    };
  });

  const thicknessSum =
    catalogSettings.walls[classificationKey]?.initialInteriorThickness +
    catalogSettings.walls[classificationKey]?.initialCoreThickness +
    catalogSettings.walls[classificationKey]?.initialExteriorThickness;

  const properties = [
    { title: "Classification", value: classification.join(", ") },
    { title: "Core Type", value: coreType },
    { title: "Total Thickness", value: !isNaN(thicknessSum) ? formatToInches(thicknessSum) : "Unknown" },
  ];

  const extraProperties = [
    { title: "Offset", value: offset },
    { title: "Alignment", value: !isNaN(Number(offset)) ? (isHorizontal ? (+offset > 0 ? "Top" : "Down") : +offset > 0 ? "Right" : "Left") : "No Alignment" },
    { title: "FunctionCode", value: classificationKey },
    { title: "ID", value: selectedRoomWallSegment.wallId },
  ];

  const isDropdownDisabled = coreTypeSizeList.length === 0 || multiWallMode;
  const toolsSetting = <Menu items={coreTypeSizeList} />;
  const Item = ({ title, value, isSelect }: ItemProps) => {
    const { errorMessage } = wallPropertiesConfig[title];
    value = multiWallMode ? "Mixed" : value;

    return (
      <div className="item">
        {isSelect ? (
          <>
            <span>{title}</span>
            <Tooltip title={isDropdownDisabled ? "No options available" : ""} placement="top">
              <Dropdown
                disabled={isDropdownDisabled}
                getPopupContainer={() => document.querySelector(".editor-left-panel") as HTMLElement}
                className={`wall-width-dropdown ${isDropdownDisabled ? "disabled" : ""}`}
                overlay={toolsSetting}
                placement="bottomRight"
                trigger={["click"]}
              >
                <span onClick={e => e.preventDefault()}>{value}</span>
              </Dropdown>
            </Tooltip>
          </>
        ) : (
          <>
            <span>{title}</span>
            <span>{value || <span className="error">{errorMessage}</span>}</span>
          </>
        )}
      </div>
    );
  };

  function clearModifiedSegments(): void {
    appModel.clearModifiedSegmentWalls();
    appModel.clearSelectedRoomWalls();
    //GraphAnalysisUtils.regenerateRoomsWalls(appModel.baseManager.roomManager, appModel.baseManager.roomManager.getActiveSoFloor());
  }

  // show the alignment buttons only when multiple walls are selected and they are eligible and not with the same thickness or already aligned
  return (
    <div className="wall-properties">
      <div className="properties">
        <span className="section">Wall</span>
      </div>
      <div className="border-bottom"></div>
      {((multiWallMode && isSelectedRoomWallsEligible) || !isNaN(Number(offset))) && (
        <>
          <div className="properties">
            <span className="title">Alignment</span>
            <ButtonGroup alignment={isHorizontal ? "vertical" : "horizontal"} />
          </div>
          <div className="border-bottom"></div>
        </>
      )}
      <div className="properties">
        {properties.map((property, i) => (
          <Item
            key={property.title}
            title={property.title}
            value={property.value}
            isSelect={i === 1 && appModel.featureFlags["wallAlignment"]} // TODO - appModel.featureFlags["refactor"] needs to be removed after refactor
          />
        ))}
      </div>
      <div className="border-bottom"></div>
      {appModel.featureFlags["wallDetails"] && (
        <>
          <div className="properties">
            {extraProperties.map((property, i) => (
              <Item key={property.title} title={property.title} value={property.value} />
            ))}
          </div>
          <div className="border-bottom"></div>
        </>
      )}
    </div>
  );
};

export default WallProperties;
