import * as THREE from "three";
import RoomManager from "../../managers/SceneManager/SceneManager";
import GeometryUtils from "../../utils/GeometryUtils/GeometryUtils";
import SceneUtils from "../../utils/SceneUtils";
import { ReferenceLine } from "../ReferenceLine";
import { CommandTypes } from "./CommandTypes";
import { RoomCommand } from "./RoomCommand";
import { Side } from "../../../models/Side";
import { soFloor2D } from "../SceneObjects/Floor/soFloor2D";
import { soRoom2D } from "../SceneObjects/Room/soRoom2D";
import { soWall2D } from "../SceneObjects/Wall/soWall2D";
import { appModel } from "../../../models/AppModel";

export class StretchRoomCommand extends RoomCommand {
  constructor(
    entityId: string,
    private referenceLine: ReferenceLine,
    private delta: number
  ) {
    super(entityId);

    this.type = CommandTypes.StretchRoomCommand;
  }

  apply(roomManager: RoomManager): void {
    const soRoom = roomManager.getActiveFloorSoRoom(this.entityId);

    const refLine = new THREE.Line3(this.referenceLine.start, this.referenceLine.end);
    const isRefLineHorizontal = GeometryUtils.isLineHorizontal(refLine);
    const sharedWalls = this.getSharedWalls(soRoom, isRefLineHorizontal);

    let sharedRoomIds = [];
    roomManager.getActiveSoFloor().wallManager.removeRooms([soRoom]);

    // if there is a shared wall, then the room should be stretched in the opposite direction from it
    if (sharedWalls.length > 0) {
      const sharedWall = sharedWalls[0];
      sharedRoomIds = sharedWall.parentRoomIds;

      const diff = this.getRoomPositionDiff(sharedWall, refLine);
      soRoom.position.add(diff);
      soRoom.updateMatrixWorld();
    }

    SceneUtils.stretchRoomByReferenceLine(soRoom, this.referenceLine, this.delta);
    soRoom.children.forEach(child => (child.userData.roomPosition = child.position));
    roomManager.getActiveSoFloor().wallManager.addRooms([soRoom]);
    roomManager.updateRoomsProperties([this.entityId]);
    roomManager.checkRoomsSharedObjects([this.entityId], sharedRoomIds);
  }

  undo(roomManager: RoomManager): void {
    const soRoom = roomManager.getActiveFloorSoRoom(this.entityId);

    const refLine = new THREE.Line3(this.referenceLine.start, this.referenceLine.end);
    const isRefLineHorizontal = GeometryUtils.isLineHorizontal(refLine);
    const sharedWalls = this.getSharedWalls(soRoom, isRefLineHorizontal);

    let sharedRoomIds = [];
    roomManager.getActiveSoFloor().wallManager.removeRooms([soRoom]);

    // if there is a shared wall, then the room should be stretched in the opposite direction from it
    if (sharedWalls.length > 0) {
      const sharedWall = sharedWalls[0];
      sharedRoomIds = sharedWall.parentRoomIds;

      const diff = this.getRoomPositionDiff(sharedWall, refLine);
      soRoom.position.add(diff.negate());
      soRoom.updateMatrixWorld();
    }

    SceneUtils.stretchRoomByReferenceLine(soRoom, this.referenceLine, -this.delta);
    soRoom.children.forEach(child => (child.userData.roomPosition = child.position));
    roomManager.getActiveSoFloor().wallManager.addRooms([soRoom]);
    roomManager.updateRoomsProperties([this.entityId]);
    roomManager.checkRoomsSharedObjects([this.entityId], sharedRoomIds);
  }

  getSharedWalls(soRoom: soRoom2D, isRefLineHorizontal: boolean): soWall2D[] {
    // if the wall of one room completely overlaps the wall of another room
    // then soRoom.wallsIds doesn't have walls which soRoom shares
    return Array.from(soRoom.wallOptionalMap.keys())
      .map(wallId => (soRoom.parent as soFloor2D).getWallById(wallId))
      .filter(w => w && w.isHorizontal === isRefLineHorizontal && (w.parentRoomIds.length > 1 || !soRoom.wallsIds.includes(w.wallId)))
      .sort((w1, w2) => this.compareWalls(w1, w2, soRoom));
  }

  compareWalls(wall1: soWall2D, wall2: soWall2D, soRoom: soRoom2D): number {
    const modifiedWalls = appModel.modifiedWallManager.getallModifiedWallsMap();

    const modifiedWall1 = modifiedWalls.get(wall1.wallId);
    const modifiedWall2 = modifiedWalls.get(wall2.wallId);
    if (modifiedWall1 !== modifiedWall2) return modifiedWall1 ? -1 : 1;

    const valueBySide = (w: soWall2D) => {
      const side = soRoom.wallsSideMap.get(w.wallId);
      return side === Side.right || side === Side.bottom ? 0 : 1;
    };

    return valueBySide(wall1) - valueBySide(wall2);
  }

  getRoomPositionDiff(sharedWall: soWall2D, refLine: THREE.Line3): THREE.Vector3 {
    const sharedPoint = new THREE.Vector3(sharedWall.start.x, sharedWall.start.y, refLine.start.z);
    const closestRefPoint = refLine.closestPointToPoint(sharedPoint, false, new THREE.Vector3());
    return closestRefPoint
      .sub(sharedPoint)
      .normalize()
      .multiplyScalar(this.delta / 2);
  }
}
