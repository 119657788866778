import { Box3 } from "three";
import { soGroup } from "../soGroup";
import { soDataBoxLine } from "./soDataBoxLine";
import * as THREE from "three";
import GeometryUtils from "../../../utils/GeometryUtils/GeometryUtils";
import VectorUtils from "../../../utils/GeometryUtils/VectorUtils";
import { appModel } from "../../../../models/AppModel";
import { OFFSET_DISTANCE } from "../../../consts";

/**
 * Class representing the dataBox of a room, including its boundary lines.
 * Inherits from soGroup to group multiple soDataBoxLines for easier management.
 */
export class soDataBox extends soGroup {
  dataBoxLines: soDataBoxLine[]; // Array of lines defining the dataBox boundary
  dataBoxRoomEntityId: string; // ID of the associated room entity

  /**
   * Constructs an instance of soDataBox.
   * @param dataBoxRoomEntityId - The unique ID of the room entity associated with this dataBox.
   * @param dataBoxLines - An array of soDataBoxLine objects representing the boundary lines of the dataBox.
   */
  constructor(dataBoxRoomEntityId: string, dataBoxLines: soDataBoxLine[]) {
    super();
    this.dataBoxRoomEntityId = dataBoxRoomEntityId;
    this.dataBoxLines = dataBoxLines;

    // Add all the lines to the group
    if (dataBoxLines?.length > 0) {
      dataBoxLines.forEach(dataBoxLine => {
        if (dataBoxLine.line) {
          dataBoxLine.line.visible = appModel.showDataBox;
          dataBoxLine.line.renderOrder = 1;
          this.add(dataBoxLine.line);
        }
      });
    }

    // Create the filled region mesh
    const shapePoints: THREE.Vector2[] = [];
    this.children.forEach(child => {
      if (child instanceof THREE.Line) {
        const geometry = child.geometry as THREE.BufferGeometry;

        // Extract the start and end points of the line
        const start = new THREE.Vector3().fromBufferAttribute(geometry.attributes.position, 0);
        const end = new THREE.Vector3().fromBufferAttribute(geometry.attributes.position, 1);

        // Add the points to the shapePoints array
        shapePoints.push(new THREE.Vector2(start.x, start.y));
        shapePoints.push(new THREE.Vector2(end.x, end.y));
      }
    });

    if (shapePoints.length > 0) {
      const shape = new THREE.Shape(shapePoints);
      const shapeGeometry = new THREE.ShapeGeometry(shape);
      const material = new THREE.MeshBasicMaterial({
        color: 0xc4e3fd,
        opacity: 0.5, // Semi-transparent
        transparent: true,
        side: THREE.DoubleSide,
      });

      const filledRegion = new THREE.Mesh(shapeGeometry, material);
      filledRegion.visible = appModel.showDataBox;
      this.add(filledRegion); // Add the filled region mesh to the group
    }
  }

  /**
   * Retrieves the vertices on the boundary of a room, including intersections with the room's bounding box.
   *
   * This method processes each data box line to calculate intersection points for horizontal and vertical lines.
   * For each line, offset lines are generated to detect intersections with the bounding box.
   * The resulting vertices are returned as an array of `THREE.Vector2`.
   *
   * @param roomBoundingBox - The bounding box of the room to process.
   * @returns {THREE.Vector2[]} - An array of vertices located on the room boundary.
   */
  public getVerticesOnRoomBoundary(roomBoundingBox: Box3): THREE.Vector2[] {
    // Get the center of the data box
    const dataBoxCenter = this.getCenter();
    const vertices: THREE.Vector2[] = [];

    // Process each data box line
    this.dataBoxLines.forEach(dataBoxLine => {
      const dataBoxLine3 = dataBoxLine.Line3;

      // Handle horizontal lines
      if (GeometryUtils.isLineHorizontal(dataBoxLine3)) {
        this.processLineIntersection(dataBoxLine3, roomBoundingBox, dataBoxCenter, true, vertices);
      }

      // Handle vertical lines
      if (GeometryUtils.isLineVertical(dataBoxLine3)) {
        this.processLineIntersection(dataBoxLine3, roomBoundingBox, dataBoxCenter, false, vertices);
      }
    });

    return vertices;
  }

  /**
   * Processes line intersections for horizontal or vertical lines and adds the resulting vertices.
   *
   * @param dataBoxLine - The line to process.
   * @param roomBoundingBox - The bounding box of the room.
   * @param dataBoxCenter - The center point of the data box.
   * @param isHorizontal - Whether the line is horizontal.
   * @param vertices - The array to store resulting vertices.
   */
  private processLineIntersection(
    dataBoxLine: THREE.Line3,
    roomBoundingBox: Box3,
    dataBoxCenter: THREE.Vector3,
    isHorizontal: boolean,
    vertices: THREE.Vector2[]
  ): void {
    const directionLine = isHorizontal
      ? new THREE.Line3(dataBoxCenter, new THREE.Vector3(dataBoxCenter.x, dataBoxLine.start.y, 0))
      : new THREE.Line3(dataBoxCenter, new THREE.Vector3(dataBoxLine.start.x, dataBoxCenter.y, 0));

    const normalizedDirection = this.getNormalizedDirection(directionLine);

    this.addIntersectionVertices(dataBoxLine.start, normalizedDirection, roomBoundingBox, vertices);
    this.addIntersectionVertices(dataBoxLine.end, normalizedDirection, roomBoundingBox, vertices);
  }

  /**
   * Calculates the normalized direction vector of a line.
   *
   * @param line - The line to calculate the direction vector for.
   * @returns The normalized direction vector.
   */
  private getNormalizedDirection(line: THREE.Line3): THREE.Vector3 {
    const directionVector = new THREE.Vector3().subVectors(line.end, line.start);
    return directionVector.normalize();
  }

  /**
   * Adds intersection vertices for a given point and direction.
   *
   * @param point - The starting point of the line.
   * @param normalizedDirection - The normalized direction vector of the line.
   * @param roomBoundingBox - The bounding box to check intersections against.
   * @param vertices - The array to store resulting vertices.
   */
  private addIntersectionVertices(point: THREE.Vector3, normalizedDirection: THREE.Vector3, roomBoundingBox: THREE.Box3, vertices: THREE.Vector2[]): void {
    const offsetLine = new THREE.Line3(
      point,
      new THREE.Vector3(point.x + OFFSET_DISTANCE * normalizedDirection.x, point.y + OFFSET_DISTANCE * normalizedDirection.y, 0)
    );

    // Get all intersections with the bounding box
    const intersectionVertices = GeometryUtils.lineIntersectsBoundingBoxLines(offsetLine, roomBoundingBox);

    // Add all intersections to the vertices array
    intersectionVertices.forEach(intersection => {
      vertices.push(VectorUtils.Vector3ToVector2(intersection));
    });
  }

  public getBoundingBox(): Box3 {
    const boundingBox = new Box3();
    this.dataBoxLines.forEach(dataBoxLine => {
      const dataBoxLine3 = dataBoxLine.Line3;
      boundingBox.expandByPoint(dataBoxLine3.start);
      boundingBox.expandByPoint(dataBoxLine3.end);
    });
    return boundingBox;
  }

  public getCenter(): THREE.Vector3 {
    const boundingBox = this.getBoundingBox();
    const center = new THREE.Vector3();
    boundingBox.getCenter(center);
    return center;
  }

  /**
   * Creates a deep copy of this soDataBox instance.
   * @returns A new soDataBox instance with copied properties.
   */
  public DeepCopy(): soDataBox {
    // Clone the dataBoxLines array with deep copies of each soDataBoxLine
    const copiedLines = this.dataBoxLines.map(line => {
      const copiedLine = new soDataBoxLine(
        line.soId, // Copy the unique identifier
        line.start.clone(), // Deep copy the start point
        line.end.clone(), // Deep copy the end point
        line.line.clone(), // Deep copy the THREE.Line
        line.isWetWall, // Copy the boolean property
        line.isPlumbing, // Copy the boolean property
        line.clearence, // Copy the clearance value
        line.type // Copy the type string
      );
      return copiedLine;
    });

    // Create a new soDataBox with the copied properties
    const copy = new soDataBox(this.dataBoxRoomEntityId, copiedLines);
    copy.position.copy(this.position); // Copy the position
    copy.rotation.copy(this.rotation); // Copy the rotation
    copy.scale.copy(this.scale); // Copy the scale

    // Return the deep copy
    return copy;
  }
}
