export const VEEV_ADDRESS = "777 Mariners Island Blvd, San Mateo, CA 94404\ninfo@veev.com\n650.292.0752";
export const VEEV_EMAIL = "info@veev.com";

export const EPSILON = 1e-7;
export const ROUND_PRECISION = 100;

// URLs
export const STRETCH_POINT_TEXTURE_URL = "https://threejs.org/examples/textures/sprites/disc.png";
export const TEXT_FONT_URL = "https://cdn.rawgit.com/mrdoob/three.js/master/examples/fonts/helvetiker_regular.typeface.json";

// misc
export const ZOOM_TO_FIT_SIZE_FACTOR_2D = 2.0;
export const ZOOM_TO_FIT_SIZE_FACTOR_3D = 5.0;
export const ZOOM_TO_FIT_SIZE_FACTOR_PDF_REPORT = 1.1;

export const GRID_MAJOR_CELL_RATIO = 5; // cells in major cell
export const GRID_MINOR_CELL_RATIO = 2; // minor cells in cell
export const GRID_MIN_CELL_SIZE_CUTOFF_2D = 15.0; // minimum cell detalization, in pixels
export const GRID_MIN_CELL_SIZE_CUTOFF_3D = 15.0 * 2; // minimum cell detalization, in pixels

export const ARC_AREA_VALIDATION_LABEL_SIZE = 1;
export const LOT_LINE_LABEL_TEXT_SIZE = 1;
export const LOT_LINE_LABEL_TEXT_OFFSET = 1;
export const LOT_LINE_LABEL_DISPLAY_SIDE_THRESHOLD = 5;

export const FLOOR_CAPACITY_COEFFICIENT = 0.7;
export const MIN_SHEAR_WALL_LENGTH_FACTOR = 3.5;
export const SHOW_BASE_POINT = false;
export const ROOM_OPENING_BORDER_SIZE = 1.5;

export const OPENING_OFFSET_FROM_ROOM_FACE = 1;
export const OPENING_DEPTH = 1;
export const OPENING_FRAME_DEPTH = 3;

export const OPENING_CUT_MASK_REF_ID = 1;

// colors
export const RENDERER_CLEAR_COLOR = 0xffffff;
export const SCENE_AMBIENT_LIGHT_COLOR = 0xffffff;
export const SCENE_BACKGROUND_COLOR = 0xf5f5f5;
export const SCENE_SUN_COLOR = 0xffffff;
export const INTERSECTED_COLOR = 0xe04242;
export const MODEL_LINE_COLOR = 0x7ec2b6;
export const INACTIVE_MODEL_LINE_COLOR = 0xbfbfbf;
export const FLOOR_CONTOUR_COLOR = 0x6a6a6a;
export const BASE_POINT_COLOR = 0xff0000;
export const PLUMBING_POINT_COLOR = 0x00ff43;
export const FURNITURE_COLOR = 0x6a6a6a;
export const STRETCH_POINT_COLOR = 0xffffff;
export const DIMENSION_LINE_COLOR = 0x8f8f8f;
export const DIMENSION_LABEL_COLOR = 0x0d2727;
export const FLOOR_BACKGROUND_SCALING_LINE_COLOR = 0x8f8f8f;
export const LOT_LINE_SELECTED_COLOR = 0xd0fe92;
export const LOT_LINE_LABEL_TEXT_COLOR = 0x0d2727;
export const LOT_LINE_LABEL_OFFSET_LINE_COLOR = 0x8f8f8f;
export const RULER_BACKGROUND_COLOR = 0xefefef; //0xdfdfdf;GRID_MEDIUM_COLOR;
export const RULER_MARKER_COLOR = 0xdddddd; //0xbfbfbf;GRID_MAJOR_COLOR;
export const RULER_TEXT_COLOR = 0x212121;
export const ROOM_3D_SWEEP_COLOR = 0x363533;
export const ROOM_3D_EDGE_COLOR = 0x599e99;
export const ROOM_3D_BOX_FALLBACK_COLOR = 0xc8c8c8;
export const PLM_VALID_PLUMBING_POINT_COLOR = 0x03cb6a;
export const PLM_INVALID_PLUMBING_POINT_COLOR = 0xff3b47;
export const PLM_PIPE_EXTEND_CIRCLE_COLOR = 0x7b7b7b;
export const PLM_ROOM_BELOW_PLANE_COLOR = 0xffbd00;
export const PLM_BLOCKED_EXTERIOR_WALL_COLOR = 0xffbd00;
export const PLM_OPTIONAL_EXTERIOR_WALL_COLOR = 0xbdfb6e;
export const ARC_LIVEABLE_AREA_BORDER_COLOR = 0xbb0000;
export const ARC_OUTDOOR_AREA_BORDER_COLOR = 0xbb0000;
export const ARC_LIVEABLE_AREA_FILL_COLOR = 0xf516f9;
export const ARC_GARAGE_AREA_FILL_COLOR = 0xef9610;
export const ARC_OUTDOOR_AREA_FILL_COLOR = 0x3351f1;
export const CLADDING_LINE_COLOR = 0xa0a0a0;
export const ROOM_OPENING_SELECTED_COLOR = 0x00f0f0;
export const ROOM_OPENING_SELECTED_RANGE_COLOR = 0x00f71a;
export const SHORT_WALL_SEGMENT_INDICATOR_PRIMARY_COLOR = 0xff3b47;
export const SHORT_WALL_SEGMENT_INDICATOR_SECONDARY_COLOR = 0xf6bcbf;
export const SELECTED_WALL = 0x008dff;
export const HOVERD_WALL = 0xd5d5d5;

// Wall types validation colors
export const DDL_WALL_SEGMENT_COLOR = 0x00ffff;
export const GRG_WALL_SEGMENT_COLOR = 0xff9900;
export const INT_WALL_SEGMENT_COLOR = 0x78909c;
export const PLM_WALL_SEGMENT_COLOR = 0x00ff00;
export const EXT_WALL_SEGMENT_COLOR = 0x0000ff;

// Gaps validation colors
export const ARC_GAP_HIGHT_COLOR = 0xff3b47;
export const ARC_GAP_MEDIUM_COLOR = 0xff9900;
export const ARC_GAP_LOW_COLOR = 0xffed4e;
export const ARC_GAP_UNEVALUATED_COLOR = 0x9747ff;

// messages
export const MESSAGE_DURATION = 1000;
export const TOOLTIP_DELAY = 100;
export const SNAP_WARNING_MESSAGE = "There are objects on snapping walls!";
export const SNAP_ERROR_MESSAGE = "There are intersected objects on snapping walls!";
export const OUT_OF_OFFSET_ERROR_MESSAGE = "Room is outside of lot line offset!";
export const OUT_OF_LOTLINE_ERROR_MESSAGE = "Room is outside of lot line!";
export const NO_FLOORS_ERROR = "There are no floors in this corePlan!";
export const ROOM_OVERLAP_MESSAGE = "Some rooms are overlapping!";
export const OBSOLETE_ROOMS_REPLACEMENT_ERROR_MESSAGE = "Error when replacing obsolete rooms!";
export const ROOMS_TOO_CLOSE_MESSAGE = "Some rooms are too close!";
export const LOT_LINE_NOT_ADDED_MESSAGE = "Lot line not added!";
export const LOT_LINE_VERTEX_REMOVE_MESSAGE = "Vertices count cannot be less than 3!";
export const ROOM_PLUMBING_SANITY_WARNING = "Wall bellow plumbing point has an opening";
export const ROOM_PLUMBING_SANITY_ERROR = "MEP placement validation failed";
export const STRETCH_TO_FIT_WARNING_MESSAGE = `The room's size does not allow snapping`;
export const CLADDING_INTERSECTION_ERROR = "Some cladding lines intersect each other!";
export const CLADDING_OUTSIDE_LOT_OFFSET_ERROR = "Some cladding lines are outside lot line offset!";
export const GENERATE_EXCEL_ERROR_MESSAGE = "Failed to generate excel!";

// render orders
export const GLOBAL_GRID_PLANE_RENDER_ORDER = -101;
export const GLOBAL_GRID_RENDER_ORDER = -100;
export const GLOBAL_BACKGROUND_RENDER_ORDER = -90;

export const FLOOR_BACKGROUND_RENDER_ORDER = -80;

export const LOT_LINE_OFFSET_RENDER_ORDER = -72;
export const LOT_LINE_EDGE_RENDER_ORDER = -71;
export const LOT_LINE_VERTEX_RENDER_ORDER = -70;
export const LOT_LINE_LABEL_RENDER_ORDER = -69;
export const CLADDING_LINE_RENDER_ORDER = -60;

export const ROOM_FLOOR_RENDER_ORDER = 0;

export const OUTDOOR_WALL_RENDER_ORDER = 360;
export const WALL_RENDER_ORDER = 360;
export const SELECTED_WALL_RENDER_ORDER = 6999;
export const OPENING_RENDER_ORDER = 400;
export const OPENING_CLIPPER_RENDER_ORDER = 500;

export const OBSOLETE_ROOM_INDICATOR_RENDER_ORDER = 700;

export const MODEL_LINE_RENDER_ORDER = 7000;
export const ROOF_CONTOUR_RENDER_ORDER = 7000;

export const OPENING_SELECTED_RANGE_RENDER_ORDER = 7010;
export const OPENING_SELECTED_RENDER_ORDER = 7011;

export const REFERENCE_LINE_RENDER_ORDER = 7100;

export const STRETCH_TRIANGLES_RENDER_ORDER = 7200;

export const DIMENSION_INDICATOR_RENDER_ORDER = 7300;

export const FURNITURE_HATCH_RENDER_ORDER = 7350;
export const FURNITURE_RENDER_ORDER = 7360;
export const PLM_POINT_RENDER_ORDER = 7370;

export const STR_VALIDATION_WALL_SEGMENT_RENDER_ORDER = 7400;
export const ARC_AREA_VALIDATION_FILL_RENDER_ORDER = 7500;
export const ARC_AREA_VALIDATION_OUTDOOR_BORDER_RENDER_ORDER = 7510;
export const ARC_AREA_VALIDATION_BORDER_RENDER_ORDER = 7511;
export const ARC_AREA_VALIDATION_ERROR_BORDER_RENDER_ORDER = 7512;
export const ARC_AREA_VALIDATION_LABELS_RENDER_ORDER = 7550;

export const SHORT_WALL_SEGMENT_INDICATOR_RENDER_ORDER = 7600;

export const BASE_POINT_RENDER_ORDER = 9000;
export const GLOBAL_RULERS_RENDER_ORDER = 10000;

export const US_STATES = [
  ["Alabama", "AL"],
  ["Alaska", "AK"],
  ["Arizona", "AZ"],
  ["Arkansas", "AR"],
  ["California", "CA"],
  ["Colorado", "CO"],
  ["Connecticut", "CT"],
  ["Delaware", "DE"],
  ["Florida", "FL"],
  ["Georgia", "GA"],
  ["Hawaii", "HI"],
  ["Idaho", "ID"],
  ["Illinois", "IL"],
  ["Indiana", "IN"],
  ["Iowa", "IA"],
  ["Kansas", "KS"],
  ["Kentucky", "KY"],
  ["Louisiana", "LA"],
  ["Maine", "ME"],
  ["Maryland", "MD"],
  ["Massachusetts", "MA"],
  ["Michigan", "MI"],
  ["Minnesota", "MN"],
  ["Mississippi", "MS"],
  ["Missouri", "MO"],
  ["Montana", "MT"],
  ["Nebraska", "NE"],
  ["Nevada", "NV"],
  ["New Hampshire", "NH"],
  ["New Jersey", "NJ"],
  ["New Mexico", "NM"],
  ["New York", "NY"],
  ["North Carolina", "NC"],
  ["North Dakota", "ND"],
  ["Ohio", "OH"],
  ["Oklahoma", "OK"],
  ["Oregon", "OR"],
  ["Pennsylvania", "PA"],
  ["Rhode Island", "RI"],
  ["South Carolina", "SC"],
  ["South Dakota", "SD"],
  ["Tennessee", "TN"],
  ["Texas", "TX"],
  ["Utah", "UT"],
  ["Vermont", "VT"],
  ["Virginia", "VA"],
  ["Washington", "WA"],
  ["West Virginia", "WV"],
  ["Wisconsin", "WI"],
  ["Wyoming", "WY"],
];

export const OFFSET_DISTANCE = 4;
