import { Side } from "../../../../models/Side";

export class OffsetOperation {
  side: Side;
  distance: number;
  constructor(Side: Side, Distance: number) {
    this.side = Side;
    this.distance = Distance;
  }
}
