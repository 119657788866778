import * as THREE from "three";

import GeometryUtils from "../../../utils/GeometryUtils/GeometryUtils";
import SceneUtils from "../../../utils/SceneUtils";
import UnitsUtils from "../../../utils/UnitsUtils";
import MathUtils from "../../../utils/MathUtils";
import RoomUtils from "../../../utils/RoomUtils";

import { soGroup } from "../soGroup";
import { soWall2D } from "../Wall/soWall2D";
import { soOpening } from "../Openings/soOpening";
import { soRoomItem2D } from "../RoomItem/soRoomItem2D";
import { SceneEntityType } from "../../SceneEntityType";
import { soRoomBoundary } from "../RoomBoundary/soRoomBoundary";
import { Side } from "../../../../models/Side";
import { appModel } from "../../../../models/AppModel";
import { Direction } from "../../Direction";
import { MessageKindsEnum, showToastMessage } from "../../../../helpers/messages";
import { MESSAGE_DURATION, STRETCH_TO_FIT_WARNING_MESSAGE } from "../../../consts";
import { RoomEntityType } from "../../../../models/RoomEntityType";
import { FuncCode } from "../../../../entities/catalogSettings/types";
import { soFloor2DRoot } from "../Floor/soFloor2DRoot";
import soRoomSideInfo from "./soRoomSideInfo";
import { soFloor2D } from "../Floor/soFloor2D";
import { soBoundaryLine } from "../RoomBoundary/soBoundaryLine";
import WallOverrides from "../Wall/wallOverrides";
import { soDataBox } from "../DataBox/soDataBox";
import { OffsetOperation } from "../RoomBoundary/OffsetOperation";
import { GeneralUtils } from "../../../utils/GeneralUtils";

/**
 * Class representing a 2D room in the scene.
 * Inherits from soGroup to allow handling multiple child components.
 */
export class soRoom2D extends soGroup {
  private parentFloorId: string;
  parentRoomId: string;
  isSelected: boolean;
  moveOffset: THREE.Vector3;
  roomTypeId: string;
  startPosition: THREE.Vector3;
  wallsIds: string[];
  wallsSideMap: Map<string, string> = new Map<string, string>();
  wallOptionalMap: Map<string, boolean> = new Map<string, boolean>();
  openings: soOpening[] = [];
  roomItems: soRoomItem2D[];
  stretchObjects: any[];
  roomNetBoundary: soRoomBoundary;
  roomBoundary: soRoomBoundary;
  dataBoxes: soDataBox[] = [];
  RoomFloorSlab: THREE.Object3D;
  isIndoor: boolean;
  roomType: SceneEntityType;
  wallOverrides: Map<string, WallOverrides> = new Map<string, WallOverrides>();
  roomSideInfo: Map<string, soRoomSideInfo> = new Map<string, soRoomSideInfo>();

  constructor(
    isIndoor: boolean = true,
    parentFloorId: string = "0",
    parentRoomId: string = "0",
    isSelected: boolean = false,
    moveOffset: THREE.Vector3 = new THREE.Vector3(0, 0, 0),
    roomTypeId: string = "0",
    startPosition: THREE.Vector3 = new THREE.Vector3(0, 0, 0),
    walls: soWall2D[] = [],
    openings: soOpening[] = [],
    roomItems: any[] = [],
    stretchObjects: any[] = [],
    roomNetBoundary: soRoomBoundary = null,
    roomBoundary: soRoomBoundary = null,
    roomSideInfo: Map<string, soRoomSideInfo> = new Map<string, soRoomSideInfo>(),
    roomFloorSlab: THREE.Object3D = new THREE.Object3D()
  ) {
    super();
    this.roomBoundary = roomBoundary;
    this.roomNetBoundary = roomNetBoundary;
    this.roomItems = roomItems;
    this.isIndoor = isIndoor;
    this.initRoomSideInfo();
  }
  get boundingBoxByModelLine(): THREE.Box3 {
    return this.getSoRoomBoundingBoxByModelLines();
  }
  get GrossBoundingBox(): THREE.Box3 {
    return this.getSoRoomBoundingBox();
  }
  get NetBoundingBox(): THREE.Box3 {
    return this.getSoRoomNetBoundingBox();
  }
  get BoundingBox(): THREE.Box3 {
    return GeometryUtils.getGeometryBoundingBox3D(this);
  }
  set ParentFloorId(parentFloorId: string) {
    this.parentFloorId = parentFloorId;
  }
  get ParentFloorId() {
    return this.parentFloorId;
  }
  get walls(): soWall2D[] {
    return this.wallsIds.map(wallId => (this.parent as soFloor2D).getWallById(wallId)).filter(wall => wall);
  }
  private initRoomSideInfo(): void {
    Object.values(Side).forEach(side => {
      this.roomSideInfo.set(side, new soRoomSideInfo(side));
    });
  }
  /**
   * Calculates the bounding box of a given room object.
   * The bounding box is determined by expanding to include all child objects
   * that are of type `RoomEntityType.Wall` or `SceneEntityType.SyntheticWall`.
   * Additionally, it unions with the bounding box derived from the room's model lines.
   * The resulting bounding box has its `z` dimension set to 0.
   *
   * @param room - The room object for which the bounding box is to be calculated.
   * @returns The bounding box of the room as a `THREE.Box3` object.
   */
  getSoRoomBoundingBox(): THREE.Box3 {
    const bb = new THREE.Box3();

    this.wallsIds.forEach(wallId => {
      bb.expandByObject((this.parent as soFloor2D).getWallById(wallId));
    });
    const modelLineBB = this.boundingBoxByModelLine;
    if (modelLineBB) bb.union(modelLineBB);

    bb.min.z = bb.max.z = 0;
    return bb;
  }
  /**
   * Disposes of all walls within a room by removing them from the room object
   * and properly disposing of their associated geometry and resources.
   * @param {soRoom2D} soRoom - The room object containing synthetic wall entities.
   */
  public disposeRoomWalls(): void {
    const walls = this.wallsIds;
    if (walls.length) {
      walls.forEach(wall => GeometryUtils.disposeObject(wall));
      this.wallsIds = [];
    }
  }
  addItem(item: soRoomItem2D): void {
    this.roomItems.push(item);
    this.add(item);
  }
  removeItem(item: soRoomItem2D): void {
    const index = this.roomItems.indexOf(item);
    if (index > -1) {
      this.roomItems.splice(index, 1);
      this.remove(item);
      GeometryUtils.disposeObject(item);
    }
  }

  addItems(soItems: soRoomItem2D[]): void {
    if (soItems.length) {
      this.roomItems.push(...soItems);
      this.add(...soItems);
    }
  }
  removeItems(soItems: soRoomItem2D[]): void {
    if (soItems.length) {
      soItems.forEach(item => this.removeItem(item));
    }
  }
  HasWall(wallId: string): boolean {
    return this.wallsIds.includes(wallId);
  }
  addWall(soWall: soWall2D): void {
    if (this.HasWall(soWall.wallId)) return;
    try {
      this.wallsIds.push(soWall.wallId);
      const side = GeneralUtils.getEnumValue(Side, this.getRoomOriginalSideByLine(soWall.toLine3()));
      if (side) {
        this.wallsSideMap.set(soWall.wallId, side);
        this.wallOptionalMap.set(soWall.wallId, this.roomSideInfo.get(side).wallOptional);
        //this.roomNetBoundary.resetEdge(side);
        // this.roomNetBoundary.applyOffsetOperation(new OffsetOperation(side, 1),false);
        // this.roomNetBoundary.updateAllEdges();
        //this.roomNetBoundary.applyOffsetOperation(new OffsetOperation(side, this.getMaxWallThicknessBySide(side)));
      }
    } catch (e) {
      console.error(e);
    }
  }
  removeWall(wallId: string): void {
    if (!this.HasWall(wallId)) return;
    const index = this.wallsIds.indexOf(wallId);
    if (index > -1) {
      this.wallsIds.splice(index, 1);
    }
    if (this.wallsSideMap.has(wallId)) {
      this.wallsSideMap.delete(wallId);
    }
    if (this.wallOptionalMap.has(wallId)) {
      this.wallOptionalMap.delete(wallId);
    }
  }
  removeAllWalls(): void {
    this.wallsIds = [];
    this.wallsSideMap.clear();
    this.wallOptionalMap.clear();
  }
  addFloorSlab(roomFloorSlab: THREE.Object3D): void {
    this.RoomFloorSlab = roomFloorSlab;
    this.add(roomFloorSlab);
  }

  setRoomBoundary(roomBoundary: soRoomBoundary): void {
    if (roomBoundary.boundaryLines.length) {
      //roomBoundary.setBoundaryLinesParentRoomSide();
      this.roomBoundary = roomBoundary;
      roomBoundary.attachLinesToSoRoom(this);
      this.add(roomBoundary);
    }
  }

  setRoomDataBox(dataBoxes: soDataBox[]): void {
    if (!dataBoxes?.length) return;

    dataBoxes.forEach(dataBox => {
      if (dataBox.dataBoxLines.length) {
        this.addDataBox(dataBox);

        // The soDataBox itself is fully constructed, including the mesh
        dataBox.renderOrder = 1;
        dataBox.visible = appModel.showDataBox;
        this.add(dataBox);
      }
    });
  }

  setNetRoomBoundary(roomNetBoundary: soRoomBoundary): void {
    if (roomNetBoundary.boundaryLines.length) {
      //roomNetBoundary.setBoundaryLinesParentRoomSide();
      this.roomNetBoundary = roomNetBoundary;
      roomNetBoundary.attachLinesToSoRoom(this);
      if (this.roomNetBoundary) {
        this.add(roomNetBoundary);
      }
    }
  }

  getRoomModelLineByLine(line: THREE.Line3): soBoundaryLine {
    this.updateMatrixWorld(true);
    const modelLines = this.roomBoundary.boundaryLines;
    const modelLine = modelLines.find(modelLine => GeometryUtils.doLinesOverlap(line, modelLine.line3));
    if (!modelLine) {
      throw new Error("Model line not found.");
    } else {
      return modelLine;
    }
  }
  getRoomOriginalSideByLine(line: THREE.Line3): string {
    const modelLine = this.getRoomModelLineByLine(line);
    return modelLine.ParentRoomSide;
  }

  getWallOveride(wallId: string): WallOverrides {
    return this.wallOverrides.get(wallId) ?? new WallOverrides(wallId);
  }
  getMaxWallThicknessBySide(side: Side): number {
    return Math.max(...this.walls.filter(wall => this.wallsSideMap.get(wall.wallId) === side).map(w => (w.HasGeometry ? w.getWallThicknessForRoom(this) : 0)));
  }
  /**
   * Moves the specified room side by a given distance.
   * This method adjusts the vertices of intersecting objects (lines or meshes)
   * that fall within the bounding box of the model line corresponding to the given side.
   *
   * @param side - The side of the room to move.
   * @param distance - The distance to move the side.
   */
  moveRoomSideByDistance(side: Side, distance: number): void {
    // Retrieve the room model lines corresponding to each side
    const modelLine = RoomUtils.getSoRoomSideByType(this, RoomEntityType.ModelLine, side);

    // Compute the room's center
    const roomBoundingBox = new THREE.Box3().setFromObject(this);
    const roomCenter = new THREE.Vector3();
    roomBoundingBox.getCenter(roomCenter);

    // Compute the center of the model line
    const modelLineCenter = modelLine.getCenter(new THREE.Vector3());

    // Determine the direction vector from the model line center to the room center
    const direction = new THREE.Vector3().subVectors(roomCenter, modelLineCenter).normalize();

    // Create an axis-aligned vector based on the dominant axis (x or y)
    const axisVector = new THREE.Vector3(
      Math.abs(direction.x) > Math.abs(direction.y) ? Math.sign(direction.x) : 0,
      Math.abs(direction.y) > Math.abs(direction.x) ? Math.sign(direction.y) : 0,
      0 // Assuming movement only occurs along the x or y axis
    );
    axisVector.negate(); // Invert the axis vector for proper direction

    // Find objects that intersect with the model line's bounding box
    const items = this.children.filter(child => GeometryUtils.lineIntersectsBoundingBox(modelLine, GeometryUtils.getGeometryBoundingBox3D(child), 0.001));

    // Process each intersecting item
    items.forEach(item => {
      try {
        const localModelLine = modelLine.clone().applyMatrix4(item.matrixWorld.clone().invert());
        const localModelLineBox = new THREE.Box3().setFromPoints([localModelLine.start, localModelLine.end]);
        // Handle lines (THREE.Line or similar geometry types)
        if (item.type === "Line") {
          SceneUtils.MoveIntersectingPoints(item, localModelLineBox, axisVector, distance);
        }
        // Handle meshes (THREE.Mesh or similar geometry types)
        else if (item.type === "Mesh" && item.userData.type != "StretchTriangle") {
          SceneUtils.MoveIntersectingPoints(item, localModelLineBox, axisVector, distance);
        }
        // For non-line, non-mesh objects, move the object directly
        else {
          item.position.addScaledVector(axisVector, distance);
          // const geom = (item as any).geometry;
          // geom.needsUpdate = true;
          // geom.computeBoundingBox();
          // geom.computeBoundingSphere();
        }
      } catch (e) {
        console.error(e);
      }
    });
  }

  /**
   * Moves the specified side of a room by the delta distance required for the given wall type.
   * @param room - The 3D room object.
   * @param side - The side of the room to move.
   * @param wallType - The wall type determining the move distance.
   */
  adjustRoomSideByWallType(side: Side, funcCode: FuncCode): void {
    const moveDelta = RoomUtils.calculateSideMoveDelta(this, side, funcCode);
    this.moveRoomSideByDistance(side, moveDelta);
    this.userData.RoomSidesWallTypes[side] = funcCode;
    //room.updateMatrixWorld();
  }

  /**
   * Moves the entire room away from the specified side by the delta distance for the given wall type.
   * @param room - The 3D room object.
   * @param side - The side to move away from.
   * @param wallType - The wall type determining the move distance.
   */
  moveRoomAwayFromSideByWallType(side: Side, funcCode: FuncCode, offset: number = 0): void {
    if (!side) return;
    const moveDelta = RoomUtils.calculateSideMoveDelta(this, side, funcCode);
    this.moveRoomBySideAndDistance(side, moveDelta + offset);
    this.moveRoomSideByDistance(side, moveDelta + offset);
    this.userData.RoomSidesWallTypes[side] = funcCode;
    this.userData.RoomSidesWallOffset[side] = offset;
    //room.updateMatrixWorld();
  }

  /**
   * Moves the entire room by a specified distance in the direction of the given side.
   * @param room - The 3D room object.
   * @param side - The side to move in the direction of.
   * @param distance - The distance to move the room.
   */
  moveRoomBySideAndDistance(side: Side, distance: number): void {
    if (Math.abs(distance) > 0) {
      switch (side) {
        case Side.top:
          this.position.y -= distance;
          break;
        case Side.right:
          this.position.x -= distance;
          break;
        case Side.bottom:
          this.position.y += distance;
          break;
        case Side.left:
          this.position.x += distance;
          break;
        default:
          throw new Error(`Invalid side specified: ${side}. Must be one of: Top, Right, Bottom, Left.`);
      }
    }
    this.updateMatrixWorld();
  }

  /**
   * Initializes the wall types for all sides of a room.
   * This method sets the wall type for each side of the room based on the provided wall type.
   *
   * @param room - The 3D room object.
   * @param wallType - The wall type to set for all sides of the room.
   */
  initRoomWallTypes(funcCode: FuncCode = FuncCode.EXT_2X4): void {
    this.userData.RoomSidesWallTypes = {};
    Object.values(Side).forEach(side => {
      this.userData.RoomSidesWallTypes[side] = funcCode;
    });
  }

  initRoomWallOffset(offset: number = 0): void {
    this.userData.RoomSidesWallOffset = {};
    Object.values(Side).forEach(side => {
      this.userData.RoomSidesWallOffset[side] = offset;
    });
  }

  /**
   * Calculates the bounding box of a room based on its model lines.
   * The bounding box is created using the start points of the left, right, bottom, and top lines.
   * The `z` dimension of the bounding box is set to 0.
   *
   * @returns The bounding box of the room based on its model lines as a `THREE.Box3` object.
   */
  public getSoRoomBoundingBoxByModelLines(): THREE.Box3 {
    const lines = RoomUtils.getSoRoomLinesByType(this, RoomEntityType.ModelLine);
    return new THREE.Box3(new THREE.Vector3(lines.left.start.x, lines.bottom.start.y, 0), new THREE.Vector3(lines.right.start.x, lines.top.start.y, 0));
  }
  public getSoRoomNetBoundingBox(): THREE.Box3 {
    return this.roomNetBoundary.boundingBox;
  }
  /**
   * Stretches the room to fit against neighboring rooms by adjusting its bounding box.
   *
   * @param soFloorsRoot - The 3D object containing visible floors and rooms.
   * @param roomSnapTool - The tool used to calculate snapping and stretching distances.
   * @throws Error if the `soFloorsRoot` is invalid or does not contain child elements.
   */
  public stretchToFit(soFloorsRoot: soFloor2DRoot, roomSnapTool): void {
    const precision = 2 * UnitsUtils.getSyntheticWallHalfSize() + UnitsUtils.getStretchingAllowance();
    const soRooms = RoomUtils.getSoRoomsByFloorsRoot(this, soFloorsRoot);
    const bb = this.getSoRoomBoundingBoxByModelLines();
    const boundingBoxes = new Map<string, THREE.Box3>();

    soRooms.forEach(soRoom => boundingBoxes.set(soRoom.soId, soRoom.getSoRoomBoundingBoxByModelLines()));

    const snappedSoRooms: soRoom2D[] = [];
    const snappedSides = {
      [Side.top]: false,
      [Side.right]: false,
      [Side.bottom]: false,
      [Side.left]: false,
    };

    for (const soOther of soRooms) {
      // Check for shared object intersection for rooms
      if (appModel.activeFloor.rooms.some(room => room.id === soOther.soId)) {
        const result = SceneUtils.hasRoomsSharedObjects(this, soOther);
        if (!result || result.hasIntersectedSharedObjects) {
          continue;
        }
      }

      const soOtherBox = soOther.getSoRoomBoundingBoxByModelLines();
      const touchingSides = GeometryUtils.getBoundingBoxesTouch(bb, soOtherBox);
      if (Object.values(touchingSides).every(it => !it)) {
        continue;
      }

      snappedSoRooms.push(soOther);
      snappedSides[Side.top] = snappedSides[Side.top] || touchingSides[Side.top];
      snappedSides[Side.right] = snappedSides[Side.right] || touchingSides[Side.right];
      snappedSides[Side.bottom] = snappedSides[Side.bottom] || touchingSides[Side.bottom];
      snappedSides[Side.left] = snappedSides[Side.left] || touchingSides[Side.left];

      if (Object.values(snappedSides).every(it => it)) {
        break;
      }
    }

    // not snapped
    if (Object.values(snappedSides).every(it => !it)) {
      return;
    }

    const unsnappedSoRooms = soRooms.filter(soRoom => !snappedSoRooms.includes(soRoom));

    const stretchDistance = {
      [Side.top]: 0,
      [Side.right]: 0,
      [Side.bottom]: 0,
      [Side.left]: 0,
    };

    const direction = {
      [Side.top]: Direction.Vertical,
      [Side.right]: Direction.Horizontal,
      [Side.bottom]: Direction.Vertical,
      [Side.left]: Direction.Horizontal,
    };

    const sign = {
      [Side.top]: 1,
      [Side.right]: 1,
      [Side.bottom]: -1,
      [Side.left]: -1,
    };

    Object.entries(snappedSides).forEach(([side, isSnapped]: [string, boolean]) => {
      if (!isSnapped) {
        let snapData = roomSnapTool.checkSnappingWhileMovingStretchTriangles(this, direction[side], sign[side], precision, unsnappedSoRooms);
        if (snapData.absDistance > precision) {
          snapData = roomSnapTool.checkSnappingWhileMovingStretchTriangles(this, direction[side], sign[side], precision, snappedSoRooms);
        }
        if (snapData.absDistance <= precision) {
          stretchDistance[side] = sign[side] * snapData.distance;
        }
      }
    });

    let showWarningMessage = false;
    Object.entries(stretchDistance).forEach(([side, distance]: [string, number]) => {
      if (!MathUtils.areNumbersEqual(distance, 0)) {
        const axis = direction[side] === Direction.Horizontal ? "x" : "y";
        const rooms = appModel.activeCorePlan?.getRooms(appModel.selectedRoomsIds);
        const roomId = rooms?.length && rooms[0].id;

        const lockedRoomDimensions = appModel.activeCorePlan.lockedRoomDimensions && appModel.activeCorePlan.lockedRoomDimensions[roomId];

        const isStrechUnlocked =
          (axis === "x" && lockedRoomDimensions && !lockedRoomDimensions.x) || (axis === "y" && lockedRoomDimensions && !lockedRoomDimensions.y);

        if (isStrechUnlocked) {
          const stretchedDistance = SceneUtils.stretchRoom(this, distance, direction[side]);
          this.position[axis] += (sign[side] * stretchedDistance) / 2;
          this.updateMatrixWorld();

          if (!MathUtils.areNumbersEqual(distance, stretchedDistance)) {
            showWarningMessage = true;
          }
        }
      }
    });

    if (showWarningMessage) {
      showToastMessage(MessageKindsEnum.Warning, STRETCH_TO_FIT_WARNING_MESSAGE, { autoClose: MESSAGE_DURATION });
    }
  }

  /**
   * Adds a new soDataBox to the room.
   * @param dataBox - The soDataBox instance to add.
   */
  addDataBox(dataBox: soDataBox): void {
    this.dataBoxes.push(dataBox);
    this.add(dataBox); // Add the dataBox to the scene graph
  }

  /**
   * Removes an existing soDataBox from the room.
   * @param dataBox - The soDataBox instance to remove.
   */
  removeDataBox(dataBox: soDataBox): void {
    const index = this.dataBoxes.indexOf(dataBox);
    if (index !== -1) {
      this.dataBoxes.splice(index, 1);
      this.remove(dataBox); // Remove the dataBox from the scene graph
    }
  }

  /**
   * Clears all soDataBoxes from the room.
   */
  clearDataBoxes(): void {
    this.dataBoxes.forEach(dataBox => this.remove(dataBox));
    this.dataBoxes = [];
  }

  updateNetBoundary(): void {
    this.roomNetBoundary.resetAllEdges();
    Object.values(Side).forEach(side => this.roomNetBoundary.addOffsetOperation(side, this.getMaxWallThicknessBySide(side)));
    this.roomNetBoundary.applyOffsetOperations();
  }
  // public DeepCopy(): soRoom2D {
  //   const result: soRoom2D = GeometryUtils.soRoomDeepClone(this);
  //   result.walls= this.walls.map(wall=>wall.DeepCopy());
  //   result.RoomFloorSlab = this.RoomFloorSlab;
  //   result.roomType = this.roomType;
  //   result.wallsIds = this.wallsIds;
  //   result.name = this.name;
  //   result.userData.id = this.soId;
  //   result.soId = this.soId;
  //   result.userData.roomTypeId = this.roomTypeId;
  //   result.roomSideInfo = this.roomSideInfo;
  //   return result;
  // }
}
