import GeometryUtils from "../../utils/GeometryUtils/GeometryUtils";
import VectorUtils from "../../utils/GeometryUtils/VectorUtils";
import MathUtils from "../../utils/MathUtils";
import { soWall2D } from "../SceneObjects/Wall/soWall2D";

export default class soSpace {
  spaceType: string;
  private _contour: Map<string, soWall2D> = new Map();
  private _innerSegments: Map<string, soWall2D> = new Map();
  private _contourPoints: Set<THREE.Vector3> = new Set();
  containedRoomsIds: string[] = [];
  constructor() {
    // Initialize default properties here
  }
  public addContourWall(wall: soWall2D): void {
    if (!this._contour.has(wall.wallId)) {
      this._contour.set(wall.wallId, wall);
      this._contourPoints.add(VectorUtils.Vector2ToVector3(wall.start));
      this._contourPoints.add(VectorUtils.Vector2ToVector3(wall.end));
    }
  }
  public addinternalWall(wall: soWall2D): void {
    if (!this._contour.has(wall.wallId)) this._innerSegments.set(wall.wallId, wall);
  }
  public addContainedRoom(roomId: string): void {
    if (!this.containedRoomsIds.includes(roomId)) this.containedRoomsIds.push(roomId);
  }
  public addContainedRooms(roomIds: string[]): void {
    roomIds.forEach(roomId => this.addContainedRoom(roomId));
  }
  get contour(): soWall2D[] {
    return Array.from(this._contour.values());
  }
  get innerSegments(): soWall2D[] {
    return Array.from(this._innerSegments.values());
  }
  get contourPoints(): THREE.Vector3[] {
    return Array.from(this._contourPoints);
  }
  public containsSpace(space: soSpace): boolean {
    return space.contourPoints.every(point => this.containsPoint(point));
  }
  public containsPoint(point: THREE.Vector3): boolean {
    return GeometryUtils.isPointInsidePolygon(this.contourPoints, point);
  }
  public containsSegment(segment: soWall2D): boolean {
    return this.containsPoint(VectorUtils.Vector2ToVector3(segment.start)) || this.containsPoint(VectorUtils.Vector2ToVector3(segment.end));
  }
  /**
   * Retrieves the contour points of the space in clockwise direction.
   * This method iterates through the contour walls of the space and constructs
   * an array of points based on the direction of the walls.
   * @returns {THREE.Vector3[]} An array of `THREE.Vector3` points representing the contour of the space.
   */
  public getContourPointsByClockwiseDirection(): THREE.Vector3[] {
    const points = [];
    this.contour.forEach((wall, idx) => {
      if (!points.length) {
        // Add the first point to the array and continue
        points.push(VectorUtils.Vector2ToVector3(wall.end));
        return;
      }
      const prevPoint = points[idx - 1];
      // We match the previously added point with the end point of the wall
      const isEndPointEqualToPrev = MathUtils.areNumbersEqual(prevPoint.x, wall.end.x) && MathUtils.areNumbersEqual(prevPoint.y, wall.end.y);
      // If it's equal, we add the start point of the wall to the array, otherwise we add the end point
      // This way we ensure that the points are added in clockwise direction since wall directions are from bottom to top and from left to right
      const vector = isEndPointEqualToPrev ? wall.start : wall.end;
      points.push(VectorUtils.Vector2ToVector3(vector));
    });
    return points;
  }
}
