import { Box3, Vector3 } from "three";
import { WallType } from "../../../../entities/catalogSettings/types";
import { appModel } from "../../../../models/AppModel";
import { soDataBoxLine } from "../DataBox/soDataBoxLine";
import { soRoom2D } from "../Room/soRoom2D";
import GeometryUtils from "../../../utils/GeometryUtils/GeometryUtils";
import { OFFSET_DISTANCE } from "../../../consts";
import MathUtils from "../../../utils/MathUtils";

export default class WallSideInfo {
  private roomId: string;
  private roomCategoryName: string = "";
  private wallSideTypeTags: WallType[] = [];
  constructor(soRoom: soRoom2D) {
    this.roomId = soRoom.soId;
    this.setRoomCategoryName(soRoom);
  }
  get RoomId(): string {
    return this.roomId;
  }
  get RoomCategoryName(): string {
    return this.roomCategoryName;
  }
  get WallSideTypeTags(): WallType[] {
    return this.wallSideTypeTags;
  }
  private setRoomCategoryName(soRoom: soRoom2D): void {
    const roomType = appModel.getRoomType(soRoom.userData.roomTypeId);
    if (!roomType) return;

    if (roomType.isShaft) {
      this.addWallClassification(WallType.SFT);
    }

    const roomCategory = appModel.getRoomCategory(roomType.roomCategoryId);
    if (roomCategory) {
      if (roomCategory.isGarage) {
        this.addWallClassification(WallType.GRG);
        this.roomCategoryName = "garage";
      } else if (roomCategory.isBedroom) {
        this.roomCategoryName = "bedroom";
      } else if (roomCategory.isBathroom) {
        this.roomCategoryName = "bathroom";
      } else if (roomCategory.isKitchen) {
        this.roomCategoryName = "kitchen";
      } else if (roomCategory.isOutdoor) {
        this.roomCategoryName = "outdoor";
      } else if (roomCategory.isStairs) {
        this.roomCategoryName = "stairs";
      } else if (roomCategory.isStorage) {
        this.roomCategoryName = "storage";
      } else {
        this.roomCategoryName = "internal";
      }
    }
  }
  public addWallClassification(wallType: WallType) {
    if (!this.wallSideTypeTags.includes(wallType)) this.wallSideTypeTags.push(wallType);
  }

  /**
   * Classifies this wall side by determining its type based on intersections with data box lines.
   *
   * @param wallLine3 - The 3D representation of the wall line.
   * @param dataBoxesLines - The lines of the room's data boxes.
   * @param isRightSide - Whether the side being processed is the right side.
   */
  public addDataBoxClassification(wallLine3: THREE.Line3, dataBoxesLines: soDataBoxLine[], isRightSide: boolean): void {
    const boundingBox = new Box3();
    boundingBox.expandByPoint(wallLine3.start);
    boundingBox.expandByPoint(wallLine3.end);

    // Determine if the wall is horizontal, vertical, or neither
    const isHorizontal = MathUtils.areNumbersEqual(wallLine3.start.y, wallLine3.end.y);
    const isVertical = MathUtils.areNumbersEqual(wallLine3.start.x, wallLine3.end.x);

    if (isHorizontal) {
      // Expand bounding box for horizontal wall
      const yOffset = isRightSide ? -OFFSET_DISTANCE : OFFSET_DISTANCE;
      boundingBox.expandByPoint(new Vector3(wallLine3.start.x, wallLine3.start.y + yOffset, 0));
      boundingBox.expandByPoint(new Vector3(wallLine3.end.x, wallLine3.end.y + yOffset, 0));
    } else if (isVertical) {
      // Expand bounding box for vertical wall
      const xOffset = isRightSide ? OFFSET_DISTANCE : -OFFSET_DISTANCE;
      boundingBox.expandByPoint(new Vector3(wallLine3.start.x + xOffset, wallLine3.start.y, 0));
      boundingBox.expandByPoint(new Vector3(wallLine3.end.x + xOffset, wallLine3.end.y, 0));
    } else {
      // Skip processing for non-orthogonal walls
      console.error("Wall is not horizontal or vertical");
      return;
    }

    // Filter data box lines
    const filteredDataBoxLines = dataBoxesLines.filter(dataBoxLine => {
      // Ensure the dataBoxLine is parallel to wallLine3
      const wallDirection = new Vector3().subVectors(wallLine3.end, wallLine3.start).normalize();
      const dataBoxDirection = new Vector3().subVectors(dataBoxLine.Line3.end, dataBoxLine.Line3.start).normalize();
      const isParallel = Math.abs(wallDirection.dot(dataBoxDirection)) > 0.999; // Dot product close to ±1

      if (!isParallel) {
        return false; // Skip non-parallel lines
      }

      // Find all intersection points
      const intersections = GeometryUtils.lineIntersectsBoundingBoxLines(dataBoxLine.Line3, boundingBox);

      const startContained = boundingBox.containsPoint(dataBoxLine.Line3.start);
      const endContained = boundingBox.containsPoint(dataBoxLine.Line3.end);

      // Return true if there are intersections satisfying the conditions
      const validIntersections = intersections.some(intersection => {
        // Check if only one point is contained and if the distance from the contained point to the intersection is > 1 (threshold)
        if (startContained && !endContained) {
          return dataBoxLine.Line3.start.distanceTo(intersection) > 1;
        }
        if (!startContained && endContained) {
          return dataBoxLine.Line3.end.distanceTo(intersection) > 1;
        }
        return false;
      });

      // If both points are contained, return true
      if (startContained && endContained) {
        return true;
      }

      return validIntersections;
    });

    // Classify wall sides based on filtered data box lines
    filteredDataBoxLines.forEach(dataBoxLine => {
      if (dataBoxLine.isPlumbing) {
        this.addWallClassification(WallType.PLM);
      }
      if (dataBoxLine.isWetWall) {
        this.addWallClassification(WallType.SHW);
      }
    });
  }
}
