import * as THREE from "three";
import { v4 as uuid } from "uuid";
import { catalogSettings } from "../../entities/catalogSettings";
import { FuncCode, WallType } from "../../entities/catalogSettings/types";
import { settings } from "../../entities/settings";
import { Floor } from "../../models/Floor";
import { RoomEntityProperties } from "../../models/RevitRoomType";
import { RoomEntity } from "../../models/RoomEntity";
import { RoomEntityType } from "../../models/RoomEntityType";
import { Vector3V } from "../../models/Vector3V";
import {
  ARC_AREA_VALIDATION_LABELS_RENDER_ORDER,
  ARC_AREA_VALIDATION_LABEL_SIZE,
  BASE_POINT_COLOR,
  BASE_POINT_RENDER_ORDER,
  CLADDING_LINE_COLOR,
  CLADDING_LINE_RENDER_ORDER,
  DIMENSION_INDICATOR_RENDER_ORDER,
  EPSILON,
  FLOOR_BACKGROUND_RENDER_ORDER,
  FLOOR_BACKGROUND_SCALING_LINE_COLOR,
  FLOOR_CONTOUR_COLOR,
  FURNITURE_COLOR,
  FURNITURE_HATCH_RENDER_ORDER,
  FURNITURE_RENDER_ORDER,
  GLOBAL_BACKGROUND_RENDER_ORDER,
  INTERSECTED_COLOR,
  MODEL_LINE_COLOR,
  MODEL_LINE_RENDER_ORDER,
  OBSOLETE_ROOM_INDICATOR_RENDER_ORDER,
  OPENING_CUT_MASK_REF_ID,
  OPENING_RENDER_ORDER,
  OUTDOOR_WALL_RENDER_ORDER,
  PLM_POINT_RENDER_ORDER,
  PLUMBING_POINT_COLOR,
  REFERENCE_LINE_RENDER_ORDER,
  ROOF_CONTOUR_RENDER_ORDER,
  ROOM_3D_BOX_FALLBACK_COLOR,
  ROOM_3D_SWEEP_COLOR,
  ROOM_FLOOR_RENDER_ORDER,
  SELECTED_WALL,
  SHOW_BASE_POINT,
  STRETCH_POINT_COLOR,
  STRETCH_POINT_TEXTURE_URL,
  STR_VALIDATION_WALL_SEGMENT_RENDER_ORDER,
  TEXT_FONT_URL,
  WALL_RENDER_ORDER,
} from "../consts";
import { DXFLoader } from "../libs/DXFLoader";
import { Direction } from "../models/Direction";
import { FloorSpace, Space } from "../models/FloorSpaceTree";
import { GraphManager } from "../models/GraphManager";
import { ReferenceLine } from "../models/ReferenceLine";
import RoomColorScheme from "../models/RoomColorScheme";
import { SceneEntityType } from "../models/SceneEntityType";
import { MergeSegmentsMode } from "../models/SegmentsMergeMode";
import { ThreeFontData } from "../models/ThreeFontData";
import { SoStretchTriangle } from "../models/scene/SoStretchTriangle";
import { ISegments } from "../models/segments/ISegments";
import { Segment } from "../models/segments/Segment";
import GeometryUtils from "./GeometryUtils/GeometryUtils";
import MathUtils from "./MathUtils";
import SegmentsUtils from "./SegmentsUtils";
import UnitsUtils from "./UnitsUtils";
import { WallAnalysisUtils } from "./WallAnalysisUtils";
import axios from "axios";
import { getAxiosRequestConfig } from "../../services/api/utilities";
import apiProvider from "../../services/api/utilities/Provider";
import { Roof } from "../../models/Roof";
import { SoPreviewGable } from "../models/scene/SoPreviewGable";
import { Room } from "../../models/Room";
import PreviewManager from "../managers/PreviewManager/PreviewManager";
import { appModel } from "../../models/AppModel";
import RoomEditToolPosition from "../tools/RoomEditToolPosition";
import { Side } from "../../models/Side";
import RoomUtils from "./RoomUtils";
import VectorUtils from "./GeometryUtils/VectorUtils";
import { WebAppUISettingsKeys } from "../../entities/settings/types";
import { soFloor2D } from "../models/SceneObjects/Floor/soFloor2D";
import { soRoomItem2D } from "../models/SceneObjects/RoomItem/soRoomItem2D";
import { soBoundaryLine } from "../models/SceneObjects/RoomBoundary/soBoundaryLine";
import { soRoom2D } from "../models/SceneObjects/Room/soRoom2D";
import { soStretchTriangle } from "../models/SceneObjects/StretchObject/soStrechTriangle";
import { GraphAnalysisUtils } from "./GraphAnalysisUtils";
import { Graph } from "../models/graph/Graph";
import SceneManager from "../managers/SceneManager/SceneManager";
import FloorUtils from "./FloorUtils";
import RoomManager from "../managers/RoomManager/RoomManager";
import { soWall2D } from "../models/SceneObjects/Wall/soWall2D";
import { soOpening } from "../models/SceneObjects/Openings/soOpening";
import WallUtils from "./WallUtils";
import BoundingBoxUtils from "./GeometryUtils/BoundingBoxUtils";
import { soDataBox } from "../models/SceneObjects/DataBox/soDataBox";
import { soDataBoxLine } from "../models/SceneObjects/DataBox/soDataBoxLine";
import soSpace from "../models/graph/Space";

export default class SceneUtils {
  private static readonly dxfLoader: any = new DXFLoader();
  private static readonly fontLoader: THREE.FontLoader = new THREE.FontLoader();
  private static font: THREE.Font = null;

  static get Font(): THREE.Font {
    return SceneUtils.font;
  }
  static async ensureFontLoaded(): Promise<void> {
    if (!SceneUtils.font) {
      SceneUtils.font = await SceneUtils.fontLoader.loadAsync(TEXT_FONT_URL);
      SceneUtils.dxfLoader.setFont(SceneUtils.font);
    }
  }

  static createTextMesh(text: string, color: number, size: number) {
    const material = new THREE.MeshBasicMaterial({ color, transparent: true, opacity: 1.0 });
    const geometry = new THREE.ShapeBufferGeometry(SceneUtils.Font.generateShapes(text, size));
    return new THREE.Mesh(geometry, material);
  }

  /**
   * Generates an object with text labels positioned within a specified bounding box. Text may be omitted if it exceeds the available space.
   */
  static createTextLabels(
    title: string,
    subtitle: string,
    titleFontSize: number,
    subtitleFontSize: number,
    lineSpacing: number,
    fontColor: number,
    bb: THREE.Box3
  ): THREE.Group {
    const result = new THREE.Group();

    const containerSize = bb.getSize(new THREE.Vector3());
    const containerCenter = bb.getCenter(new THREE.Vector3());
    const data: ThreeFontData = SceneUtils.Font.data as any;

    const splitToLines = (text: string, fontSize: number): [string[], number] => {
      const lineHeight = fontSize * ((data.boundingBox.yMax - data.boundingBox.yMin) / data.resolution);
      const lines = SceneUtils.wordWrap(text, containerSize.x, fontSize);
      const textHeight = lines.length * (lineHeight + lineSpacing);

      return [lines, textHeight];
    };

    const addSoLines = (lines: string[], fontSize: number, offsetY: number = 0) => {
      const lineHeight = fontSize * ((data.boundingBox.yMax - data.boundingBox.yMin) / data.resolution);

      for (let i = 0; i < lines.length; i++) {
        const soLine = SceneUtils.createTextMesh(lines[i], fontColor, fontSize);
        const lineCenter = GeometryUtils.getGeometryBoundingBox2D(soLine).getCenter(new THREE.Vector3());

        soLine.position.sub(lineCenter);
        soLine.position.y += containerSize.y / 2 - lineHeight / 2 - i * (lineHeight + lineSpacing) + offsetY;

        result.add(soLine);
      }
    };

    const [titleLines, titleHeight] = splitToLines(title, titleFontSize);
    const [subtitleLines, subtitleHeight] = splitToLines(subtitle, subtitleFontSize);

    // Line spacing can be omitted for the last line.
    if (titleHeight + subtitleHeight - lineSpacing <= containerSize.y) {
      addSoLines(titleLines, titleFontSize);
      addSoLines(subtitleLines, subtitleFontSize, -titleHeight);
    } else if (subtitleHeight - lineSpacing <= containerSize.y) {
      addSoLines(subtitleLines, subtitleFontSize);
    }

    const resultCenter = GeometryUtils.getGeometryBoundingBox2D(result).getCenter(new THREE.Vector3());
    result.position.copy(containerCenter.sub(resultCenter));

    return result;
  }

  static async loadDxf(fileKey: string): Promise<THREE.Object3D> {
    await this.ensureFontLoaded();

    if (!fileKey) {
      return Promise.resolve(null);
    }

    const items = await SceneUtils.dxfLoader.loadAsync(fileKey);
    if (items.length > 1) {
      const container = new THREE.Group();
      container.add(...items);
      return container;
    } else {
      return items[0];
    }
  }
  static async loadRoomColorSchemes(roomBoxes: RoomColorScheme[]): Promise<RoomColorScheme[]> {
    // api call
    // roomBoxes.forEach(rb => rb.colorIndex = Math.round(Math.random() * 3).toString());
    roomBoxes.forEach(rb => (rb.colorIndex = -1));
    const data = JSON.parse(JSON.stringify(roomBoxes));

    return data.map(it => new RoomColorScheme().fromJS(it));
  }

  static createRoofContour(indoorBoxes: THREE.Box3[], outdoorBoxes: THREE.Box3[], floorId: string): THREE.Group {
    const result = new THREE.Group();
    result.userData.type = SceneEntityType.RoofContour;
    result.userData.floorId = floorId;

    const offset = UnitsUtils.getSweepOffset();
    const material = new THREE.LineBasicMaterial({ color: FLOOR_CONTOUR_COLOR });

    const { boundingSpaces, holeSpaces } = SegmentsUtils.findRoofSpaces(indoorBoxes, outdoorBoxes);

    boundingSpaces.forEach(boundingSpace => {
      const contour = SegmentsUtils.segmentsToPoints(boundingSpace.contour);
      contour.push(contour[0]);
      result.add(new THREE.Line(new THREE.BufferGeometry().setFromPoints(contour), material));
    });

    holeSpaces.forEach(holeSpace => {
      const points = GeometryUtils.addOffsetToContour(SegmentsUtils.segmentsToPoints(holeSpace.contour), -offset);
      points.push(points[0]);
      result.add(new THREE.Line(new THREE.BufferGeometry().setFromPoints(points), material));
    });

    GeometryUtils.setRenderOrder(result, ROOF_CONTOUR_RENDER_ORDER);

    return result;
  }

  static createRoofOld(indoorBoxes: THREE.Box3[], outdoorBoxes: THREE.Box3[]): THREE.Group {
    const result = new THREE.Group();
    const offset = UnitsUtils.getSweepOffset();

    const { boundingSpaces, holeSpaces } = SegmentsUtils.findRoofSpaces(indoorBoxes, outdoorBoxes);

    boundingSpaces.forEach(boundingSpace => {
      const contour = SegmentsUtils.segmentsToPoints(boundingSpace.contour);
      const shape = new THREE.Shape();
      GeometryUtils.addContourToPath(shape, contour);

      holeSpaces.forEach(holeSpace => {
        if (boundingSpace.containsPoint(holeSpace.contour[0].getCenter3())) {
          const points = GeometryUtils.addOffsetToContour(SegmentsUtils.segmentsToPoints(holeSpace.contour), -offset);
          const hole = new THREE.Path();
          GeometryUtils.addContourToPath(hole, points);
          shape.holes.push(hole);
        }
      });

      const mesh = new THREE.Mesh(
        new THREE.ExtrudeBufferGeometry(shape, { depth: UnitsUtils.getRoofDepth(), bevelEnabled: false }),
        new THREE.MeshStandardMaterial({
          color: ROOM_3D_SWEEP_COLOR,
          polygonOffset: true,
          polygonOffsetFactor: 1.0,
          polygonOffsetUnits: 5.0,
          metalness: 0.092,
          roughness: 0.3,
        })
      );
      mesh.castShadow = true;
      mesh.receiveShadow = true;
      result.add(mesh);
    });

    return result;
  }

  static createMeshesFromRoof(roof: Roof, rooms: Room[], prev: PreviewManager): THREE.Group {
    const edgesDataJson = JSON.stringify(roof.roofEdges, null, null);
    const roofGroup = new THREE.Group();

    const createRoofGeo = async () => {
      try {
        const url = `${apiProvider.host}create-roof`;
        const jsonBody = { json: edgesDataJson };
        const axiosConfig = getAxiosRequestConfig();
        const response = await axios.post(url, jsonBody, axiosConfig);
        const jsonObject = JSON.stringify(response.data);
        roofGroup.add(SceneUtils.createSlopedExtrudeGeometries(jsonObject, rooms));

        // update soPreviewGables finishes
        const gables = prev.getSoPreviewGables();
        gables.forEach(gable => {
          gable.updateExteriorFinish(gable.rooms[0].gableExteriorFinishes?.[gable.index] ?? -1);
        });

        //initialize soPreviewGables in soPreviewRooms
        const soPreviewRooms = prev.getSoPreviewRooms();
        soPreviewRooms.forEach(soRoom => {
          soRoom.gables = [];
        });
        gables.forEach(gable => {
          gable.rooms.forEach(room => {
            const soRoom = soPreviewRooms.find(soR => soR.roomId === room.id);
            soRoom.gables.push(gable);
          });
        });
      } catch (error) {
        console.error("Error sending data:", error);
      }
    };
    createRoofGeo();

    return roofGroup;
  }

  static createSlopedExtrudeGeometries(jsonData: string, rooms: Room[]): THREE.Group {
    const roofSurfacesData = JSON.parse(jsonData);
    const roofGroup = new THREE.Group();

    // Helper function to find the lowest Z value from the nodes
    const findLowestZValue = nodes => {
      return Math.min(...nodes.map(node => node.Node.Z));
    };

    const processSurface = surface => {
      const slopeDirection = VectorUtils.directionToVector3(surface.Direction);
      if (surface.Slope !== 0) {
        const shape = this.createShapeFromNodes(surface.Nodes);
        const slopeSteepness = parseFloat(surface.Slope);
        const surfaceBottomZ = findLowestZValue(surface.Nodes); // Get the lowest Z value from nodes
        const extrudedGeometry = this.createSlopedExtrudeGeometry(shape, slopeDirection, slopeSteepness, surfaceBottomZ);
        roofGroup.add(extrudedGeometry);
      } else {
        const verticalGeometry = this.createVerticalGeometry(surface, slopeDirection, rooms);
        if (verticalGeometry != null) roofGroup.add(verticalGeometry);
      }
    };

    roofSurfacesData.RoofSurface.forEach(processSurface);
    return roofGroup;
  }

  static async getGables(jsonData: string, rooms: Room[]): Promise<SoPreviewGable[]> {
    const gables: SoPreviewGable[] = [];
    const roofSurfacesData = JSON.parse(jsonData);

    const processSurface = async surface => {
      const slopeDirection = VectorUtils.directionToVector3(surface.Direction);
      if (surface.Slope === 0) {
        const gable = this.createGableFromSurface(surface, slopeDirection, rooms);
        if (gable != null) gables.push(gable);
      }
    };

    await Promise.all(roofSurfacesData.RoofSurface.map(processSurface));
    return gables;
  }

  static createShapeFromNodes(nodes): THREE.Shape {
    const shape = new THREE.Shape();
    nodes.forEach((node, index) => {
      const point = new THREE.Vector2(parseFloat(node.Node.X), parseFloat(node.Node.Y));
      if (index === 0) {
        shape.moveTo(point.x, point.y);
      } else {
        shape.lineTo(point.x, point.y);
      }
    });
    return shape;
  }

  static createGeometry(surface): THREE.Geometry {
    const geometry = new THREE.Geometry();

    surface.Nodes.forEach(node => {
      const vertex = new THREE.Vector3(parseFloat(node.Node.X), parseFloat(node.Node.Y), parseFloat(node.Node.Z));
      geometry.vertices.push(vertex);
    });

    for (let i = 1; i < surface.Nodes.length - 1; i++) {
      geometry.faces.push(new THREE.Face3(0, i, i + 1));
    }

    geometry.computeFaceNormals();
    geometry.computeVertexNormals();

    return geometry;
  }

  static createGableMaterial(): THREE.MeshStandardMaterial {
    return new THREE.MeshStandardMaterial({
      color: ROOM_3D_BOX_FALLBACK_COLOR,
      side: THREE.DoubleSide,
      polygonOffset: true,
      polygonOffsetFactor: 1.0,
      polygonOffsetUnits: 5.0,
      metalness: 0.092,
      roughness: 0.3,
    });
  }

  static createGableFromSurface(surface, slopeDirection: THREE.Vector3, rooms: Room[]): SoPreviewGable {
    const offset = settings.values.validationSettings.roofDefaultOverhang;
    const geometry = this.createGeometry(surface);
    const material = this.createGableMaterial();

    const soGable = new SoPreviewGable(geometry, material, surface.Direction - 1);
    soGable.roofBaseSegment = soGable.CalcRoofBaseSegment(geometry, offset);

    // If dutch gable, update the roofBaseSegment in order to be able to find overlapping rooms
    if (surface.GableDepth > 0) {
      soGable.roofBaseSegment.start.x -= surface.GableDepth * slopeDirection.x;
      soGable.roofBaseSegment.start.y -= surface.GableDepth * slopeDirection.y;
      soGable.roofBaseSegment.end.x -= surface.GableDepth * slopeDirection.x;
      soGable.roofBaseSegment.end.y -= surface.GableDepth * slopeDirection.y;
    }

    const overlapRooms = soGable.getOverlappingRooms(rooms);

    if (overlapRooms.length == 0) return null;

    soGable.rooms = overlapRooms;
    soGable.castShadow = true;
    soGable.receiveShadow = true;

    return soGable;
  }

  static createVerticalGeometry(surface, slopeDirection: THREE.Vector3, rooms: Room[]): THREE.Group {
    const offset = settings.values.validationSettings.roofDefaultOverhang;
    const geometry = this.createGeometry(surface);
    const material = this.createGableMaterial();

    const soGable = new SoPreviewGable(geometry, material, surface.Direction - 1);
    soGable.roofBaseSegment = soGable.CalcRoofBaseSegment(geometry, offset);

    // If dutch gable, update the roofBaseSegment in order to be able to find overlapping rooms
    if (surface.GableDepth > 0) {
      soGable.roofBaseSegment.start.x -= surface.GableDepth * slopeDirection.x;
      soGable.roofBaseSegment.start.y -= surface.GableDepth * slopeDirection.y;
      soGable.roofBaseSegment.end.x -= surface.GableDepth * slopeDirection.x;
      soGable.roofBaseSegment.end.y -= surface.GableDepth * slopeDirection.y;
    }

    const overlapRooms = soGable.getOverlappingRooms(rooms);

    if (overlapRooms.length == 0) return null;

    soGable.rooms = overlapRooms;

    const result = new THREE.Group();
    soGable.castShadow = true;
    soGable.receiveShadow = true;
    result.add(soGable);

    const edgeGeometry = new THREE.EdgesGeometry(soGable.geometry);
    const edgeMaterial = new THREE.LineBasicMaterial({ color: settings.getColorNumber(WebAppUISettingsKeys.roofEdge) });
    const edgeMesh = new THREE.LineSegments(edgeGeometry, edgeMaterial);
    result.add(edgeMesh);

    return result;
  }

  static createSlopedExtrudeGeometry(geometryShape: THREE.Shape, slopeDirection: THREE.Vector3, slopeSteepness: number, surfaceBottomZ: number): THREE.Group {
    const result = new THREE.Group();
    const shape = geometryShape;
    const geometry = new THREE.ExtrudeBufferGeometry(shape, { depth: UnitsUtils.getRoofDepth(), bevelEnabled: false });
    const mesh = new THREE.Mesh(
      geometry,
      new THREE.MeshStandardMaterial({
        color: settings.getColorNumber(WebAppUISettingsKeys.roofSurface),
        polygonOffset: true,
        polygonOffsetFactor: 1.0,
        polygonOffsetUnits: 5.0,
        metalness: 0.092,
        roughness: 0.3,
      })
    );
    // Normalize the slopeDirection to ensure consistent application of the slopeSteepness
    const normalizedSlopeDirection = slopeDirection.clone().normalize();
    // Adjust the geometry to add a slope based on the slopeDirection and slopeSteepness
    const positions = mesh.geometry.attributes.position;
    let lowestZ = Infinity;
    const vertex = new THREE.Vector3();
    for (let i = 0; i < positions.count; i++) {
      vertex.fromBufferAttribute(positions, i);
      // Calculate the dot product to determine how much the vertex should be moved along the slopeDirection
      const slopeAdjustment = vertex.dot(normalizedSlopeDirection) * slopeSteepness;
      // Apply the slope adjustment along the Z-axis
      positions.setZ(i, positions.getZ(i) + slopeAdjustment);
      if (positions.getZ(i) < lowestZ) {
        lowestZ = positions.getZ(i);
      }
    }
    // Calculate the offset needed to make the lowest Z value 0
    const zOffset = -lowestZ + surfaceBottomZ;
    // Apply the Z offset to all vertices
    for (let i = 0; i < positions.count; i++) {
      const currentZ = positions.getZ(i);
      positions.setZ(i, currentZ + zOffset);
    }
    positions.needsUpdate = true; // Important: Mark the positions as needing update
    mesh.castShadow = true;
    mesh.receiveShadow = true;
    result.add(mesh);
    // Create an edge geometry from the mesh
    const edgeGeometry = new THREE.EdgesGeometry(mesh.geometry);
    const edgeMaterial = new THREE.LineBasicMaterial({ color: settings.getColorNumber(WebAppUISettingsKeys.roofEdge) });
    const edgeMesh = new THREE.LineSegments(edgeGeometry, edgeMaterial);
    // Add the edge mesh to the result group to visualize the edges with the specified color
    result.add(edgeMesh);
    return result;
  }

  /**
   * Creates Object3d that represents a roof drainage system
   * @param {THREE.Box3[]} boxes - Room boxes
   * @returns {THREE.Group}
   */
  static createSweep(boxes: THREE.Box3[]): THREE.Group {
    const result = new THREE.Group();
    const depth = UnitsUtils.getSweepDepth();
    const offset = UnitsUtils.getSweepOffset();
    const spaces = SegmentsUtils.findSweepSpaces(boxes);

    spaces.forEach(space => {
      let contour = GeometryUtils.addOffsetToContour(SegmentsUtils.segmentsToPoints(space.contour), offset);

      // Remove self intersections
      const externalContourSpaces = SegmentsUtils.findBoundingSpaces(SegmentsUtils.pointsToSegments(contour));
      // Self intersections can't produce new bounding space
      contour = SegmentsUtils.segmentsToPoints(externalContourSpaces[0].contour);
      const innerContour = GeometryUtils.addOffsetToContour(contour, -2 * offset);

      const shape = new THREE.Shape();
      GeometryUtils.addContourToPath(shape, contour);

      const hole = new THREE.Path();
      GeometryUtils.addContourToPath(hole, innerContour);
      shape.holes.push(hole);

      const mesh = new THREE.Mesh(
        new THREE.ExtrudeBufferGeometry(shape, { depth, bevelEnabled: false }),
        new THREE.MeshStandardMaterial({
          color: ROOM_3D_SWEEP_COLOR,
          polygonOffset: true,
          polygonOffsetFactor: 1.0,
          polygonOffsetUnits: 5.0,
          metalness: 0.092,
          roughness: 0,
        })
      );
      mesh.castShadow = true;
      mesh.receiveShadow = true;
      result.add(mesh);
    });

    return result;
  }
  static createIntersectionsPlane(): THREE.Mesh {
    const result = new THREE.Mesh(
      new THREE.PlaneBufferGeometry(UnitsUtils.getIntersectionsPlaneSize(), UnitsUtils.getIntersectionsPlaneSize()),
      new THREE.MeshBasicMaterial({ visible: false, transparent: true, opacity: 1.0 })
    );
    result.name = "Global Background Plane";
    result.renderOrder = GLOBAL_BACKGROUND_RENDER_ORDER;
    result.geometry.computeBoundingBox();

    return result;
  }
  static createBasePoint(position: THREE.Vector3): THREE.Mesh {
    const result = new THREE.Mesh(
      new THREE.CircleBufferGeometry(UnitsUtils.getBasePointSize(), 32),
      new THREE.MeshBasicMaterial({ color: BASE_POINT_COLOR, transparent: true, opacity: 1.0 })
    );
    result.name = "Base Point";
    result.userData.type = SceneEntityType.BasePoint;
    result.renderOrder = BASE_POINT_RENDER_ORDER;
    result.visible = SHOW_BASE_POINT;
    result.position.copy(position);

    return result;
  }
  static async createFloorBackground(backgroundUrl: string): Promise<THREE.Mesh> {
    const texture = await new THREE.TextureLoader().loadAsync(backgroundUrl);
    const aspect = texture.image.width / texture.image.height;

    const material = new THREE.MeshBasicMaterial({ map: texture });
    const initSize = UnitsUtils.getFloorBackgroundInitialSize();
    const result = new THREE.Mesh(new THREE.PlaneBufferGeometry(initSize, initSize), material);
    result.name = "Floor Background";
    result.scale.set(aspect, 1.0, 1.0);

    result.renderOrder = FLOOR_BACKGROUND_RENDER_ORDER;
    result.userData.type = SceneEntityType.FloorBackground;

    return result;
  }
  static createObsoleteRoomIndicator(size: THREE.Vector3): THREE.Mesh {
    const result = new THREE.Mesh(
      new THREE.PlaneBufferGeometry(size.x, size.y),
      new THREE.MeshBasicMaterial({ visible: true, transparent: true, opacity: 0.55, color: settings.getColorNumber(WebAppUISettingsKeys.obsoleteRoomsColor) })
    );

    result.renderOrder = OBSOLETE_ROOM_INDICATOR_RENDER_ORDER;
    result.userData.type = SceneEntityType.ObsoleteRoomIndicator;

    return result;
  }
  static createFloorBackgroundScalingLine(start: THREE.Vector3, end: THREE.Vector3): THREE.Line {
    const line = new THREE.Line(
      new THREE.BufferGeometry().setFromPoints([start, end]),
      new THREE.LineDashedMaterial({
        color: FLOOR_BACKGROUND_SCALING_LINE_COLOR,
        dashSize: 0.15 * UnitsUtils.getConversionFactor(),
        gapSize: 0.2 * UnitsUtils.getConversionFactor(),
        transparent: true,
        opacity: 1.0,
      })
    );
    line.name = "Floor Background Scaling Line";
    line.renderOrder = DIMENSION_INDICATOR_RENDER_ORDER;
    line.userData.type = SceneEntityType.FloorBackgroundScalingLine;

    return line;
  }
  static createFloorByModelLines(soRoom: THREE.Object3D, roomEntity?: RoomEntity): THREE.Mesh {
    const bb = RoomUtils.getRoomBoundingBoxByModelLines(soRoom);
    const result = GeometryUtils.createFilledPlane(bb, { color: settings.getColorNumber(WebAppUISettingsKeys.floorsColor) });
    result.name = RoomEntityType.Floor;
    result.userData.id = roomEntity?.id ?? uuid();
    result.userData.type = RoomEntityType.Floor;
    result.renderOrder = ROOM_FLOOR_RENDER_ORDER;
    return result;
  }

  /**
   * @Deprecated deprecated in favor of createRoomEntity
   */
  static processRoomEntity(roomEntity: RoomEntity, soEntity?: any, isRoomIndoor?: boolean): THREE.Object3D | THREE.Object3D[] {
    switch (roomEntity.type) {
      case RoomEntityType.ReferenceLine:
        return SceneUtils.createReferenceLine(roomEntity);
      case RoomEntityType.ModelLine: {
        const modelLines = SceneUtils.createLines(roomEntity);
        soEntity = modelLines;
        return modelLines;
      }
      case RoomEntityType.RoomBoundaryLines: {
        const rooBounderyLines = SceneUtils.createLines(roomEntity);
        soEntity = rooBounderyLines;
        return rooBounderyLines;
      }
      case RoomEntityType.Floor: {
        const bb = GeometryUtils.getGeometryBoundingBox2D(soEntity);
        GeometryUtils.disposeObject(soEntity);
        soEntity = GeometryUtils.createFilledPlane(bb, { color: settings.getColorNumber(WebAppUISettingsKeys.floorsColor) });
        soEntity.name = roomEntity.type;
        soEntity.userData.id = roomEntity.id;
        soEntity.userData.type = roomEntity.type;
        GeometryUtils.setChildrenRenderOrder(soEntity, ROOM_FLOOR_RENDER_ORDER);
        break;
      }
      case RoomEntityType.Wall: {
        GeometryUtils.setChildrenRenderOrder(soEntity, isRoomIndoor ? WALL_RENDER_ORDER : OUTDOOR_WALL_RENDER_ORDER);
        GeometryUtils.setChildrenMeshColor(soEntity, settings.getColorNumber(WebAppUISettingsKeys.wallsColor));
        GeometryUtils.setChildrenLinesColor(soEntity, settings.getColorNumber(WebAppUISettingsKeys.wallsColor));
        GeometryUtils.setStencilMask(soEntity, OPENING_CUT_MASK_REF_ID);
        break;
      }
      case RoomEntityType.PlumbingWall: {
        GeometryUtils.setChildrenRenderOrder(soEntity, WALL_RENDER_ORDER);
        GeometryUtils.setChildrenMeshColor(soEntity, settings.getColorNumber(WebAppUISettingsKeys.wallsColor));
        GeometryUtils.setChildrenLinesColor(soEntity, settings.getColorNumber(WebAppUISettingsKeys.wallsColor));
        GeometryUtils.setStencilMask(soEntity, OPENING_CUT_MASK_REF_ID);
        break;
      }
      case RoomEntityType.PlumbingPoint: {
        GeometryUtils.setChildrenRenderOrder(soEntity, PLM_POINT_RENDER_ORDER);
        GeometryUtils.setChildrenMeshColor(soEntity, PLUMBING_POINT_COLOR);
        GeometryUtils.setChildrenLinesColor(soEntity, PLUMBING_POINT_COLOR);
        roomEntity.properties?.forEach(prop => {
          if (prop.name === RoomEntityProperties.PlumbingRangeCollinear) {
            soEntity.userData.collinearRange = prop.value;
          }
          if (prop.name === RoomEntityProperties.PlumbingRangePerpendicular) {
            soEntity.userData.perpendicularRange = prop.value;
          }
        });
        break;
      }
      case RoomEntityType.Window:
      case RoomEntityType.Door: {
        GeometryUtils.setChildrenLinesColor(soEntity, settings.getColorNumber(WebAppUISettingsKeys.openingsColor));
        GeometryUtils.setChildrenRenderOrder(soEntity, OPENING_RENDER_ORDER);
        break;
      }
      case RoomEntityType.Furniture: {
        GeometryUtils.setChildrenRenderOrder(soEntity, FURNITURE_RENDER_ORDER);
        GeometryUtils.setChildrenMeshRenderOrder(soEntity, FURNITURE_HATCH_RENDER_ORDER);
        GeometryUtils.setChildrenMeshColor(soEntity, settings.getColorNumber(WebAppUISettingsKeys.floorsColor));
        GeometryUtils.setChildrenLinesColor(soEntity, FURNITURE_COLOR);
        break;
      }
    }

    if (soEntity) {
      soEntity.name = roomEntity.type;
      soEntity.userData.id = roomEntity.id;
      soEntity.userData.type = roomEntity.type;
      if (roomEntity.revit.id) {
        soEntity.userData.revitId = roomEntity.revit.id;
      }
      if ([RoomEntityType.Furniture, RoomEntityType.Door, RoomEntityType.Window].includes(roomEntity.type)) {
        soEntity.userData.wallBindingRevitId = roomEntity.bindingId;
      }

      roomEntity.properties?.forEach(property => {
        if (property.name === RoomEntityProperties.IsOpening) {
          soEntity.userData.isOpening = property.value;
        }
        if (property.name === RoomEntityProperties.IsStretchable) {
          soEntity.userData.isStretchable = property.value;
        }
        if (property.name === RoomEntityProperties.MaxOpeningRangeRight) {
          soEntity.userData.maxOpeningRangeRight = isNaN(+property.value) ? 0 : +property.value;
        }
        if (property.name === RoomEntityProperties.MaxOpeningRangeLeft) {
          soEntity.userData.maxOpeningRangeLeft = isNaN(+property.value) ? 0 : +property.value;
        }
        if (property.name === RoomEntityProperties.sillHeight) {
          soEntity.userData.sillHeight = property.value;
        }
        if (property.name === RoomEntityProperties.roughWidth) {
          soEntity.userData.roughWidth = property.value;
        }
        if (property.name === RoomEntityProperties.roughHeight) {
          soEntity.userData.roughHeight = property.value;
        }
      });
    }

    return soEntity;
  }
  static processSoRoomEntity(roomEntity: RoomEntity, soEntity?: any, isRoomIndoor?: boolean): THREE.Object3D | THREE.Object3D[] {
    let soDataBox = null;

    switch (roomEntity.type) {
      case RoomEntityType.ReferenceLine:
        return SceneUtils.createReferenceLine(roomEntity);
      case RoomEntityType.ModelLine: {
        const modelLines = SceneUtils.createLines(roomEntity);
        const boundaryLine = new soBoundaryLine(roomEntity.id, modelLines);

        soEntity = boundaryLine;
        break;
      }
      case RoomEntityType.RoomBoundaryLines: {
        const rooBounderyLines = SceneUtils.createLines(roomEntity);
        const boundaryLine = new soBoundaryLine(roomEntity.id, rooBounderyLines);

        soEntity = boundaryLine;
        break;
      }
      case RoomEntityType.Floor: {
        const bb = GeometryUtils.getGeometryBoundingBox2D(soEntity);
        GeometryUtils.disposeObject(soEntity);
        soEntity = GeometryUtils.createFilledPlane(bb, { color: settings.getColorNumber(WebAppUISettingsKeys.floorsColor) });
        soEntity.name = roomEntity.type;
        soEntity.userData.id = roomEntity.id;
        soEntity.soId = roomEntity.id;
        soEntity.userData.type = roomEntity.type;
        GeometryUtils.setChildrenRenderOrder(soEntity, ROOM_FLOOR_RENDER_ORDER);

        soEntity = new soFloor2D(soEntity.userData.id, soEntity.name, 0);
        break;
      }
      case RoomEntityType.Wall: {
        GeometryUtils.setChildrenRenderOrder(soEntity, isRoomIndoor ? WALL_RENDER_ORDER : OUTDOOR_WALL_RENDER_ORDER);
        GeometryUtils.setChildrenMeshColor(soEntity, settings.getColorNumber(WebAppUISettingsKeys.wallsColor));
        GeometryUtils.setChildrenLinesColor(soEntity, settings.getColorNumber(WebAppUISettingsKeys.wallsColor));
        GeometryUtils.setStencilMask(soEntity, OPENING_CUT_MASK_REF_ID);
        break;
      }
      case RoomEntityType.PlumbingWall: {
        GeometryUtils.setChildrenRenderOrder(soEntity, WALL_RENDER_ORDER);
        GeometryUtils.setChildrenMeshColor(soEntity, settings.getColorNumber(WebAppUISettingsKeys.wallsColor));
        GeometryUtils.setChildrenLinesColor(soEntity, settings.getColorNumber(WebAppUISettingsKeys.wallsColor));
        GeometryUtils.setStencilMask(soEntity, OPENING_CUT_MASK_REF_ID);
        break;
      }
      case RoomEntityType.PlumbingPoint: {
        GeometryUtils.setChildrenRenderOrder(soEntity, PLM_POINT_RENDER_ORDER);
        GeometryUtils.setChildrenMeshColor(soEntity, PLUMBING_POINT_COLOR);
        GeometryUtils.setChildrenLinesColor(soEntity, PLUMBING_POINT_COLOR);
        roomEntity.properties?.forEach(prop => {
          if (prop.name === RoomEntityProperties.PlumbingRangeCollinear) {
            soEntity.userData.collinearRange = prop.value;
          }
          if (prop.name === RoomEntityProperties.PlumbingRangePerpendicular) {
            soEntity.userData.perpendicularRange = prop.value;
          }
        });
        break;
      }
      case RoomEntityType.Window:
      case RoomEntityType.Door: {
        GeometryUtils.setChildrenLinesColor(soEntity, settings.getColorNumber(WebAppUISettingsKeys.openingsColor));
        GeometryUtils.setChildrenRenderOrder(soEntity, OPENING_RENDER_ORDER);

        break;
      }
      case RoomEntityType.Furniture: {
        const dataBox = roomEntity.properties.find(prop => prop.name === RoomEntityProperties.DataBox);
        if (dataBox) {
          soDataBox = SceneUtils.createDataBoxLines(dataBox.value, roomEntity);
        }
        GeometryUtils.setChildrenRenderOrder(soEntity, FURNITURE_RENDER_ORDER);
        GeometryUtils.setChildrenMeshRenderOrder(soEntity, FURNITURE_HATCH_RENDER_ORDER);
        GeometryUtils.setChildrenMeshColor(soEntity, settings.getColorNumber(WebAppUISettingsKeys.floorsColor));
        GeometryUtils.setChildrenLinesColor(soEntity, FURNITURE_COLOR);
        const roomItem = new soRoomItem2D(soEntity, roomEntity.type, roomEntity.id, soEntity.userData.isStretchable);
        soEntity = roomItem;

        break;
      }
    }

    if (soEntity) {
      soEntity.name = roomEntity.type;
      soEntity.userData.id = roomEntity.id;
      soEntity.userData.type = roomEntity.type;
      if (roomEntity.revit.id) {
        soEntity.userData.revitId = roomEntity.revit.id;
      }
      if ([RoomEntityType.Furniture, RoomEntityType.Door, RoomEntityType.Window].includes(roomEntity.type)) {
        soEntity.userData.wallBindingRevitId = roomEntity.bindingId;
      }

      roomEntity.properties?.forEach(property => {
        if (property.name === RoomEntityProperties.IsOpening) {
          soEntity.userData.isOpening = property.value;
        }
        if (property.name === RoomEntityProperties.IsStretchable) {
          soEntity.userData.isStretchable = property.value;
        }
        if (property.name === RoomEntityProperties.MaxOpeningRangeRight) {
          soEntity.userData.maxOpeningRangeRight = isNaN(+property.value) ? 0 : +property.value;
        }
        if (property.name === RoomEntityProperties.MaxOpeningRangeLeft) {
          soEntity.userData.maxOpeningRangeLeft = isNaN(+property.value) ? 0 : +property.value;
        }
        if (property.name === RoomEntityProperties.sillHeight) {
          soEntity.userData.sillHeight = property.value;
        }
        if (property.name === RoomEntityProperties.roughWidth) {
          soEntity.userData.roughWidth = property.value;
        }
        if (property.name === RoomEntityProperties.roughHeight) {
          soEntity.userData.roughHeight = property.value;
        }
      });
    }

    return soDataBox ? [soEntity, soDataBox] : soEntity;
  }

  /**
   * Get model line for each opening in the room limited by nearest wall or other objects on the same wall as opening
   * @param {THREE.Object3D} soRoom Room
   * @returns {Map<string, THREE.Line3>} Map of opening id and model line (Line3)
   */
  static getNetOpeningModelLine(soRoom: THREE.Object3D): Map<string, THREE.Line3> {
    const result = new Map<string, THREE.Line3>();
    const modelLines: THREE.Object3D[] = [];
    const walls: THREE.Object3D[] = [];
    const openings: THREE.Object3D[] = [];
    const wallsBb = new Map<THREE.Object3D, THREE.Box3>();

    soRoom.children.forEach(child => {
      if (child.userData.type === RoomEntityType.ModelLine) {
        modelLines.push(child);
      } else if (child.userData.type === RoomEntityType.Door || child.userData.type === RoomEntityType.Window) {
        openings.push(child);
      } else if (child.userData.type === RoomEntityType.Wall || child.userData.type === RoomEntityType.PlumbingWall) {
        walls.push(child);
        wallsBb.set(child, GeometryUtils.getGeometryBoundingBox2D(child));
      }
    });

    openings.forEach(opn => {
      const soWall = soRoom.children.find(child => child.userData.type === RoomEntityType.Wall && child.userData.revitId === opn.userData.wallBindingRevitId);
      if (!soWall) {
        return;
      }

      const opnBb = GeometryUtils.getGeometryBoundingBox2D(opn);
      const opnCenter = opnBb.getCenter(new THREE.Vector3());
      const wallBb = wallsBb.get(soWall);
      if (!wallBb) {
        return;
      }

      const direction = GeometryUtils.getLineDirection(GeometryUtils.getBoundingBoxCenterLine(wallBb));
      const wallLine = modelLines
        .map(so => SceneUtils.getLine3(so))
        .find(l => GeometryUtils.getLineDirection(l) === direction && GeometryUtils.lineIntersectsBoundingBox(l, wallBb));
      if (!wallLine) {
        return;
      }

      // Limit by connected wall & plumbing walls to main opening wall
      const axis = direction === Direction.Horizontal ? "x" : "y";
      walls.every(wall => {
        if (wall === soWall) {
          return true;
        }
        const bb = wallsBb.get(wall);
        if (!bb) {
          return true;
        }
        if (bb.intersectsBox(wallBb) || GeometryUtils.doBoundingBoxesTouch(bb, wallBb)) {
          if (bb.max[axis] < opnBb.min[axis] && wallLine.start[axis] < bb.max[axis]) {
            wallLine.start[axis] = bb.max[axis];
          }
          if (bb.min[axis] > opnBb.max[axis] && wallLine.end[axis] > bb.min[axis]) {
            wallLine.end[axis] = bb.min[axis];
          }
        }
        return true;
      });

      // Limit by other objects on opening wall
      soRoom.children
        .filter(
          child =>
            child.userData.wallBindingRevitId === opn.userData.wallBindingRevitId && ![RoomEntityType.Door, RoomEntityType.Window].includes(child.userData.type)
        )
        .forEach(child => {
          const bb = GeometryUtils.getGeometryBoundingBox3D(child);
          if (GeometryUtils.lineIntersectsBoundingBox(wallLine, bb)) {
            const centerBb = bb.getCenter(new THREE.Vector3());
            if (centerBb[axis] > opnCenter[axis]) {
              if (bb.min[axis] < wallLine.end[axis]) {
                wallLine.end[axis] = bb.min[axis];
              }
            } else {
              if (bb.max[axis] > wallLine.start[axis]) {
                wallLine.start[axis] = bb.max[axis];
              }
            }
          }
        });

      wallLine.end[axis] -= 2 * EPSILON;
      wallLine.start[axis] += 2 * EPSILON;

      result.set(opn.userData.id, wallLine);
    });

    return result;
  }

  static addOpeningZonePoints(soRoom: THREE.Object3D): void {
    const roomBb = GeometryUtils.getGeometryBoundingBox3D(soRoom);
    const roomCenter = roomBb.getCenter(new THREE.Vector3());

    const openingWallLines = SceneUtils.getNetOpeningModelLine(soRoom);

    soRoom.children
      .filter(child => child.userData.type === RoomEntityType.Door || child.userData.type === RoomEntityType.Window)
      .every(opn => {
        const soWall = soRoom.children.find(child => child.userData.type === RoomEntityType.Wall && child.userData.revitId === opn.userData.wallBindingRevitId);
        if (!soWall) {
          return true;
        }

        const opnBb = GeometryUtils.getGeometryBoundingBox2D(opn);
        const wallBb = GeometryUtils.getGeometryBoundingBox2D(soWall);
        if (!wallBb) {
          return true;
        }

        const direction = GeometryUtils.getLineDirection(GeometryUtils.getBoundingBoxCenterLine(wallBb));
        const wallLine = openingWallLines.get(opn.userData.id);
        if (!wallLine) {
          return true;
        }

        const openingLine = SceneUtils.getOpeningProjectionOnWallLine(opnBb, wallLine);
        if (!openingLine) {
          return true;
        }

        const [axis, axis2] = direction === Direction.Horizontal ? ["x", "y"] : ["y", "x"];
        const lineDiv = openingLine.distance() / 2;
        const center = openingLine.getCenter(new THREE.Vector3());

        let rLeft = Number.POSITIVE_INFINITY;
        let rRight = Number.POSITIVE_INFINITY;

        if (rLeft < lineDiv) {
          rLeft = lineDiv;
        }

        if (rRight < lineDiv) {
          rRight = lineDiv;
        }

        const zone = openingLine.clone();
        const isTopLeft =
          (direction === Direction.Horizontal && openingLine.start[axis2] >= roomCenter[axis2]) ||
          (direction === Direction.Vertical && openingLine.start[axis2] < roomCenter[axis2]);

        if (isTopLeft) {
          zone.start[axis] = center[axis] - rLeft;
          zone.end[axis] = center[axis] + rRight;

          if (zone.start[axis] < wallLine.start[axis]) {
            zone.start[axis] = wallLine.start[axis];
          }

          if (zone.end[axis] > wallLine.end[axis]) {
            zone.end[axis] = wallLine.end[axis];
          }
        } else {
          zone.start[axis] = center[axis] + rLeft;
          zone.end[axis] = center[axis] - rRight;

          if (zone.start[axis] > wallLine.end[axis]) {
            zone.start[axis] = wallLine.end[axis];
          }

          if (zone.end[axis] < wallLine.start[axis]) {
            zone.end[axis] = wallLine.start[axis];
          }
        }

        // add points
        const types = ["left", "center", "right"];
        [zone.start, center, zone.end].forEach((point, index) => {
          const geometry = new THREE.BufferGeometry().setFromPoints([point]);
          geometry.computeBoundingBox();
          const points = new THREE.Points(
            geometry,
            new THREE.PointsMaterial({
              color: new THREE.Color(0xff0000),
              size: 1,
              transparent: true,
              opacity: 1.0,
            })
          );

          points.renderOrder = Number.MAX_SAFE_INTEGER;
          points.visible = false;
          points.userData.openingId = opn.userData.id;
          points.userData.openingType = types[index];
          points.userData.type = RoomEntityType.OpeningZone;

          soRoom.add(points);
        });

        // mark wall vertices and lines
        if (!soWall.userData.orderedPoints) {
          const points = [];
          // collect all points in the wall
          soWall.traverse((child: THREE.Object3D) => {
            child.userData.childId = child.uuid;
            const geometry = (child as any).geometry;
            if ((child as THREE.Mesh).isMesh && geometry instanceof THREE.BufferGeometry) {
              for (let i = 0; i < geometry.attributes.position.count; i++) {
                const p = new THREE.Vector3().fromBufferAttribute(geometry.attributes.position, i);
                points.push({ p: p[axis], index: i, id: child.uuid, offset: 0 });
              }
              return;
            }
            if ((child as THREE.Mesh).isMesh && geometry instanceof THREE.Geometry) {
              geometry.vertices.forEach((vertex, index) => {
                points.push({ p: vertex[axis], index, id: child.uuid, offset: 0 });
              });
              return;
            }
            if (child.type === "Line" && geometry.attributes.position.count === 2) {
              const start = new THREE.Vector3().fromBufferAttribute(geometry.attributes.position, 0);
              const end = new THREE.Vector3().fromBufferAttribute(geometry.attributes.position, 1);
              [start, end].forEach((vertex, index) => {
                points.push({ p: vertex[axis], index, id: child.uuid, offset: 0 });
              });
              return;
            }
          });

          if (points.length > 0) {
            // sort points
            points.sort((a, b) => {
              if (a.p < b.p) {
                return -1;
              } else if (a.p > b.p) {
                return 1;
              }
              return 0;
            });
            if (!isTopLeft) {
              points.reverse();
            }

            const wallPoints = [];
            let lastIndex = 0;
            // Group points with the same positions (take only indices).
            points.forEach((p, i) => {
              if (i === 0) {
                const wPoint = { points: { [p.id]: [p.index] }, offset: p.offset, p: p.p };
                wallPoints.push(wPoint);
              } else {
                if (wallPoints[lastIndex].p === p.p) {
                  if (!wallPoints[lastIndex].points[p.id]) {
                    wallPoints[lastIndex].points[p.id] = [];
                  }
                  wallPoints[lastIndex].points[p.id].push(p.index);
                } else {
                  const wPoint = { points: { [p.id]: [p.index] }, offset: p.offset, p: p.p };
                  wallPoints.push(wPoint);
                  lastIndex++;
                }
              }
            });
            wallPoints.forEach((wp, i) => {
              if (wallPoints[i + 1]) {
                wp.offset = Math.abs(wallPoints[i + 1].p - wp.p);
              }
            });

            soWall.userData.orderedPoints = wallPoints;
          }
        }
        let wallStart = wallBb.min;
        if (!isTopLeft) {
          wallStart = wallBb.max;
        }
        const left = [];
        const right = [];
        let distLeft = Number.MAX_SAFE_INTEGER;
        let distRight = Number.MAX_SAFE_INTEGER;
        let lastOutIndex = -1;
        for (let i = 0; i < soWall.userData.orderedPoints.length; i++) {
          const p = soWall.userData.orderedPoints[i];
          const dist = Math.abs(center[axis] - p.p);
          if (MathUtils.isNumberInRange(p.p, wallStart[axis], center[axis])) {
            //left
            if (dist < distLeft) {
              distLeft = dist;
            }
            if (MathUtils.isNumberInRange(p.p, center[axis], zone.start[axis])) {
              left.push(i);
              continue;
            }
            lastOutIndex = i;
          } else {
            if (dist < distRight) {
              distRight = dist;
            }
            if (left.length === 0 && lastOutIndex > -1) {
              left.push(lastOutIndex);
            }
            if (MathUtils.isNumberInRange(p.p, center[axis], zone.end[axis])) {
              right.push(i);
              continue;
            }
            if (right.length === 0) {
              right.push(i);
              break;
            }
          }
        }
        opn.userData.wallPointIndexes = [left, right];
        opn.userData.wallPointDistances = [distLeft, distRight];
        soRoom.updateMatrixWorld();
        return true;
      });
  }

  static addOpeningClippingBox(soRoom: THREE.Object3D): void {
    const soOpenings = soRoom.children.filter(child => [RoomEntityType.Door, RoomEntityType.Window].includes(child.userData.type));
    soOpenings.forEach(soOpening => {
      const soWall = soRoom.children.find(
        child => child.userData.type === RoomEntityType.Wall && child.userData.revitId === soOpening.userData.wallBindingRevitId
      );
      if (soWall) {
        const openingBb = GeometryUtils.getGeometryBoundingBox3D(soOpening);
        const wallBb = GeometryUtils.getGeometryBoundingBox3D(soWall);
        const wallLine = GeometryUtils.getBoundingBoxCenterLine(wallBb);
        const axis = GeometryUtils.isLineHorizontal(wallLine) ? "y" : "x";

        // clipping patch
        const patchExtend = 2.8; // 2.8 currently has the best coverage for maximum zoom out
        const clipBb = openingBb.intersect(wallBb);
        clipBb.min[axis] = wallBb.min[axis] - patchExtend;
        clipBb.max[axis] = wallBb.max[axis] + patchExtend;
        const clipBbSize = clipBb.getSize(new THREE.Vector3());
        const mesh = new THREE.Mesh(
          new THREE.PlaneBufferGeometry(clipBbSize.x, clipBbSize.y),
          new THREE.MeshPhongMaterial({
            colorWrite: false,
            depthWrite: false,
            stencilWrite: true,
            stencilRef: THREE.ZeroStencilOp,
            stencilFunc: THREE.AlwaysStencilFunc,
            stencilFail: THREE.ReplaceStencilOp,
            stencilZFail: THREE.ReplaceStencilOp,
            stencilZPass: THREE.ReplaceStencilOp,
          })
        );
        mesh.applyMatrix4(soOpening.matrixWorld);
        mesh.position.copy(new THREE.Vector3(0.0, 0.0, 0.0));
        mesh.renderOrder = OPENING_RENDER_ORDER - 1;
        soOpening.add(mesh);
      }
    });
  }
  static getOpeningZoneAndLine(soOpening: THREE.Object3D): { zone: THREE.Line3; line: THREE.Line3; center: THREE.Vector3 } | null {
    const soRoom = soOpening.parent;
    const soZones = soRoom.children.filter(child => child.userData.type === RoomEntityType.OpeningZone && soOpening.userData.id === child.userData.openingId);

    if (!soZones.length) {
      return null;
    }

    const points = { left: null, right: null, center: null };

    soZones.forEach(soZone => {
      const geometry = (soZone as any).geometry;
      points[soZone.userData.openingType] = new THREE.Vector3().fromBufferAttribute(geometry.attributes.position, 0).applyMatrix4(soZone.matrixWorld);
    });

    if (!points.left || !points.right || !points.center) {
      return null;
    }

    const originalCenter = points.center;
    const zone = new THREE.Line3(points.left, points.right);

    if (soRoom.scale.x === -1) {
      GeometryUtils.swapLineVectors(zone);
    }

    const axis = GeometryUtils.isLineHorizontal(zone) ? "x" : "y";
    const bb = GeometryUtils.getGeometryBoundingBox2D(soOpening);
    const openingLine = SceneUtils.getOpeningProjectionOnWallLine(bb, zone);

    if (!openingLine) {
      return null;
    }

    if (
      (zone.start[axis] < zone.end[axis] && openingLine.start[axis] > openingLine.end[axis]) ||
      (zone.start[axis] > zone.end[axis] && openingLine.start[axis] < openingLine.end[axis])
    ) {
      GeometryUtils.swapLineVectors(openingLine);
    }

    return { zone, line: openingLine, center: originalCenter };
  }

  static moveOpening(soOpening: THREE.Object3D, shiftDistance: number): void {
    const soRoom = soOpening.parent;
    const soWall = soRoom.children.find(child => child.userData.revitId === soOpening.userData.wallBindingRevitId);
    if (!soWall) {
      return;
    }

    const openingData = SceneUtils.getOpeningZoneAndLine(soOpening);
    if (!openingData?.zone) {
      return;
    }
    const netWallLine = SceneUtils.getNetOpeningModelLine(soRoom).get(soOpening.userData.id);
    if (!netWallLine) {
      return;
    }

    if (MathUtils.areNumbersEqual(shiftDistance, 0)) {
      shiftDistance = 0;
    }

    if (!soOpening.userData.shiftDistance) {
      soOpening.userData.shiftDistance = 0;
    }

    const sign = Math.sign(soRoom.scale.x);
    shiftDistance *= sign;
    if (soOpening.userData.shiftDistance && Math.abs(soOpening.userData.shiftDistance) !== 0) {
      if (MathUtils.areNumbersEqual(soOpening.userData.shiftDistance, shiftDistance)) {
        return;
      }

      shiftDistance = shiftDistance - soOpening.userData.shiftDistance;
    }
    shiftDistance *= sign;

    const wallPoints = soWall.userData.orderedPoints ?? [];
    const [left, right] = soOpening.userData.wallPointIndexes ?? [[], []];
    const [distLeft, distRight] = soOpening.userData.wallPointDistances ?? [null, null];
    const merged = [...[...left].reverse(), ...right];

    let plane: THREE.Plane;
    let delta: THREE.Vector3;
    let firstPress: THREE.Vector3;

    const wallPointDelta = new Map<number, THREE.Vector3>();
    soWall.traverse((child: THREE.Object3D) => {
      const zone = openingData.zone.clone().applyMatrix4(child.matrixWorld.clone().invert());
      const line = openingData.line.clone().applyMatrix4(child.matrixWorld.clone().invert());

      const axis = GeometryUtils.isLineHorizontal(line) ? "x" : "y";
      const line2 = line.clone();
      if (line2.start[axis] > line2.end[axis]) {
        const temp = line2.start;
        line2.start = line2.end;
        line2.end = temp;
      }

      const normal = zone.end.clone().sub(zone.start.clone()).normalize();
      plane = new THREE.Plane().setFromNormalAndCoplanarPoint(normal, zone.start);
      delta = plane.normal.clone().multiplyScalar(shiftDistance);

      const deltaDir = Math.sign(delta[axis]);

      const isDeltaSameDirection = (delta[axis] < 0 && plane.normal[axis] < 0) || (delta[axis] > 0 && plane.normal[axis] > 0) ? true : false;
      //open left, press right
      let openRange = [left[0], left[left.length - 1]];
      let pressRange = [right[0], right[right.length - 1]];
      if (!isDeltaSameDirection) {
        //open right, press left
        openRange = [right[0], right[right.length - 1]];
        pressRange = [left[0], left[left.length - 1]];
      }

      const centerLine = line2.getCenter(new THREE.Vector3());
      const netWallLocal = netWallLine.clone().applyMatrix4(child.matrixWorld.clone().invert());
      const wallNormal = netWallLocal.end.clone().sub(netWallLocal.start.clone()).normalize();
      if (Math.sign(wallNormal[axis]) !== Math.sign(normal[axis])) {
        GeometryUtils.swapLineVectors(netWallLocal);
      }
      const wallStart = netWallLocal.start;
      const wallEnd = netWallLocal.end;

      const moveVertices = (vertices: THREE.Vector3[]) => {
        let prevPoint: THREE.Vector3 = null;
        merged.forEach(wpIndex => {
          const isRight = wpIndex >= right[0] ? true : false;
          let firstDistToCenter = distLeft;
          if (wpIndex === right[0]) {
            //first right
            prevPoint = null;
            firstDistToCenter = distRight;
          }
          const wp = wallPoints[wpIndex];
          let lastVertex: THREE.Vector3;
          const points = wp.points[child.userData.childId];
          if (points && points.length > 0) {
            points.forEach(index => {
              const vertex = vertices[index];
              const delta2 = delta.clone();

              if (wallPointDelta.get(wpIndex)) {
                vertex.add(wallPointDelta.get(wpIndex));
              } else {
                //inside opening
                if (MathUtils.isNumberInRange(vertex[axis], line2.start[axis], line2.end[axis])) {
                  vertex.add(delta2);
                  wallPointDelta.set(wpIndex, delta2);
                } else {
                  if (wpIndex >= openRange[0] && wpIndex <= openRange[1]) {
                    //open
                    let afterMove = vertex[axis] + delta2[axis];
                    const curDist = prevPoint ? Math.abs(prevPoint[axis] - afterMove) : Math.abs(centerLine[axis] + delta2[axis] - afterMove);
                    const offset = prevPoint ? (!isRight ? wp.offset : wallPoints[wpIndex - 1].offset) : firstDistToCenter ? firstDistToCenter : curDist;
                    if (curDist < offset) {
                      afterMove += (offset - curDist) * deltaDir;
                    }

                    if (MathUtils.isNumberInRange(afterMove, vertex[axis], isDeltaSameDirection ? wallEnd[axis] : wallStart[axis])) {
                      delta2[axis] = Math.abs(afterMove - vertex[axis]) * deltaDir;
                      vertex.add(delta2);
                      wallPointDelta.set(wpIndex, delta2);
                    }
                  }

                  if (wpIndex >= pressRange[0] && wpIndex <= pressRange[1]) {
                    //press
                    if (!firstPress) {
                      if (!MathUtils.isNumberInRange(vertex[axis] + delta2[axis], wallStart[axis], wallEnd[axis])) {
                        delta2[axis] = Math.abs((isDeltaSameDirection ? wallEnd[axis] : wallStart[axis]) - vertex[axis]) * deltaDir;
                        vertex.add(delta2);
                      } else {
                        vertex.add(delta2);
                      }

                      firstPress = vertex;
                    } else {
                      if (!MathUtils.isNumberInRange(vertex[axis], firstPress[axis], centerLine[axis])) {
                        if (!MathUtils.isNumberInRange(vertex[axis] + delta2[axis], wallStart[axis], wallEnd[axis])) {
                          delta2[axis] = Math.abs((isDeltaSameDirection ? wallEnd[axis] : wallStart[axis]) - vertex[axis]) * deltaDir;
                          vertex.add(delta2);
                        } else {
                          vertex.add(delta2);
                        }
                      } else {
                        delta2[axis] = Math.abs(firstPress[axis] - vertex[axis]) * deltaDir;
                        vertex.add(delta2);
                      }
                    }
                    wallPointDelta.set(wpIndex, delta2);
                  }
                }
              }
              lastVertex = vertex;
            });
          }
          if (lastVertex) {
            prevPoint = lastVertex;
          }
        });
      };

      const geometry = (child as any).geometry;
      if ((child as THREE.Mesh).isMesh && geometry instanceof THREE.BufferGeometry) {
        const vertices = [];
        for (let i = 0; i < geometry.attributes.position.count; i++) {
          vertices.push(new THREE.Vector3().fromBufferAttribute(geometry.attributes.position, i));
        }
        moveVertices(vertices);

        geometry.setFromPoints(vertices);
        geometry.computeBoundingBox();
        geometry.computeBoundingSphere();
        return;
      }

      if ((child as THREE.Mesh).isMesh && geometry instanceof THREE.Geometry) {
        moveVertices(geometry.vertices);

        geometry.verticesNeedUpdate = true;
        geometry.computeBoundingBox();
        geometry.computeBoundingSphere();
        return;
      }

      if (child.type === "Line" && geometry.attributes.position.count === 2) {
        const lineStart = new THREE.Vector3().fromBufferAttribute(geometry.attributes.position, 0);
        const lineEnd = new THREE.Vector3().fromBufferAttribute(geometry.attributes.position, 1);
        const linePoints = [lineStart, lineEnd];
        moveVertices(linePoints);
        geometry.dispose();
        (child as THREE.Line).geometry = new THREE.BufferGeometry().setFromPoints(linePoints);
        if ((child as THREE.Line).material instanceof THREE.LineDashedMaterial) {
          (child as THREE.Line).computeLineDistances();
        }
        return;
      }
    });

    soOpening.position.add(delta);
    soOpening.userData.shiftDistance += shiftDistance * sign;
    soOpening.updateMatrixWorld();
  }
  /**
   *@deprecated
   */
  static addStretchTriangles(soRoom: THREE.Object3D): void {
    const bb = RoomUtils.getRoomBoundingBoxByModelLines(soRoom);
    const hasHorizontal = Object.keys(SceneUtils.collectReferenceLines(soRoom, Direction.Horizontal)).length !== 0;
    const hasVertical = Object.keys(SceneUtils.collectReferenceLines(soRoom, Direction.Vertical)).length !== 0;

    const trianglePairOffset = 0.7 * UnitsUtils.getConversionFactor();

    const triangle = new SoStretchTriangle([
      new THREE.Vector3(0, 0.6 * UnitsUtils.getConversionFactor(), 0),
      new THREE.Vector3(0, 0, 0),
      new THREE.Vector3(0.6 * UnitsUtils.getConversionFactor(), 0, 0),
    ]);

    const topTriangle = triangle.clone();
    topTriangle.rotateZ((-3 * Math.PI) / 4);
    topTriangle.position.set(0, trianglePairOffset, 0);

    const bottomTriangle = triangle.clone();
    bottomTriangle.rotateZ(Math.PI / 4);
    bottomTriangle.position.set(0, -trianglePairOffset, 0);

    const trianglePair = new THREE.Group();
    trianglePair.name = "Stretch Triangles Pair";
    trianglePair.visible = false;
    trianglePair.userData.type = SceneEntityType.StretchTriangle;
    trianglePair.add(topTriangle, bottomTriangle);

    triangle.visible = false;
    triangle.userData.type = SceneEntityType.StretchTriangle;
    triangle.userData.vertical = true;
    triangle.userData.horizontal = true;

    const topRight = triangle.clone();
    topRight.position.set(bb.max.x, bb.max.y, 0);
    topRight.rotateZ(Math.PI);

    const topLeft = triangle.clone();
    topLeft.position.set(bb.min.x, bb.max.y, 0);
    topLeft.rotateZ(-Math.PI / 2);

    const bottomRight = triangle.clone();
    bottomRight.position.set(bb.max.x, bb.min.y, 0);
    bottomRight.rotateZ(Math.PI / 2);

    const bottomLeft = triangle;
    bottomLeft.position.copy(bb.min);

    const left = trianglePair.clone();
    left.rotateZ(Math.PI / 2);
    left.position.copy(GeometryUtils.getLineMidPoint2(new THREE.Vector3(bb.min.x, bb.max.y, 0), bb.min));
    left.userData.horizontal = true;

    const right = trianglePair.clone();
    right.rotateZ(Math.PI / 2);
    right.position.copy(GeometryUtils.getLineMidPoint2(new THREE.Vector3(bb.max.x, bb.min.y, 0), bb.max));
    right.userData.horizontal = true;

    const bottom = trianglePair.clone();
    bottom.position.copy(GeometryUtils.getLineMidPoint2(new THREE.Vector3(bb.max.x, bb.min.y, 0), bb.min));
    bottom.userData.vertical = true;

    const top = trianglePair;
    top.position.copy(GeometryUtils.getLineMidPoint2(new THREE.Vector3(bb.min.x, bb.max.y, 0), bb.max));
    top.userData.vertical = true;

    if (hasHorizontal) {
      soRoom.add(left, right);
    }

    if (hasVertical) {
      soRoom.add(top, bottom);
    }

    if (hasHorizontal && hasVertical) {
      soRoom.add(topLeft, topRight, bottomLeft, bottomRight);
    }
  }

  static addStretchSoTriangles(soRoom: THREE.Object3D): void {
    const bb = RoomUtils.getRoomBoundingBoxByModelLines(soRoom);
    const hasHorizontal = Object.keys(SceneUtils.collectReferenceLines(soRoom, Direction.Horizontal)).length !== 0;
    const hasVertical = Object.keys(SceneUtils.collectReferenceLines(soRoom, Direction.Vertical)).length !== 0;

    const trianglePairOffset = 0.7 * UnitsUtils.getConversionFactor();

    const triangle = new SoStretchTriangle([
      new THREE.Vector3(0, 0.6 * UnitsUtils.getConversionFactor(), 0),
      new THREE.Vector3(0, 0, 0),
      new THREE.Vector3(0.6 * UnitsUtils.getConversionFactor(), 0, 0),
    ]);

    const topTriangle = triangle.clone();
    topTriangle.rotateZ((-3 * Math.PI) / 4);
    topTriangle.position.set(0, trianglePairOffset, 0);

    const bottomTriangle = triangle.clone();
    bottomTriangle.rotateZ(Math.PI / 4);
    bottomTriangle.position.set(0, -trianglePairOffset, 0);
    const trianglePair = new soStretchTriangle();

    trianglePair.name = "Stretch Triangles Pair";
    trianglePair.visible = false;
    trianglePair.userData.type = SceneEntityType.StretchTriangle;
    trianglePair.add(topTriangle, bottomTriangle);

    triangle.visible = false;
    triangle.userData.type = SceneEntityType.StretchTriangle;
    triangle.userData.vertical = true;
    triangle.userData.horizontal = true;

    const topRight = triangle.clone();
    topRight.position.set(bb.max.x, bb.max.y, 0);
    topRight.rotateZ(Math.PI);

    const topLeft = triangle.clone();
    topLeft.position.set(bb.min.x, bb.max.y, 0);
    topLeft.rotateZ(-Math.PI / 2);

    const bottomRight = triangle.clone();
    bottomRight.position.set(bb.max.x, bb.min.y, 0);
    bottomRight.rotateZ(Math.PI / 2);

    const bottomLeft = triangle;
    bottomLeft.position.copy(bb.min);

    const left = trianglePair.clone();
    left.rotateZ(Math.PI / 2);
    left.position.copy(GeometryUtils.getLineMidPoint2(new THREE.Vector3(bb.min.x, bb.max.y, 0), bb.min));
    left.userData.horizontal = true;

    const right = trianglePair.clone();
    right.rotateZ(Math.PI / 2);
    right.position.copy(GeometryUtils.getLineMidPoint2(new THREE.Vector3(bb.max.x, bb.min.y, 0), bb.max));
    right.userData.horizontal = true;

    const bottom = trianglePair.clone();
    bottom.position.copy(GeometryUtils.getLineMidPoint2(new THREE.Vector3(bb.max.x, bb.min.y, 0), bb.min));
    bottom.userData.vertical = true;

    const top = trianglePair;
    top.position.copy(GeometryUtils.getLineMidPoint2(new THREE.Vector3(bb.min.x, bb.max.y, 0), bb.max));
    top.userData.vertical = true;

    if (hasHorizontal) {
      soRoom.add(left, right);
    }

    if (hasVertical) {
      soRoom.add(top, bottom);
    }

    if (hasHorizontal && hasVertical) {
      soRoom.add(topLeft, topRight, bottomLeft, bottomRight);
    }
  }

  /**
   * Stretch line by moving its points using plane
   * @param {THREE.Vector3} start - line start
   * @param {THREE.Vector3} end - line end
   * @param {THREE.Plane} plane
   * @param {number} distance
   * @returns {THREE.Vector3[] | null} - line points
   */
  static createStretchedLine(start: THREE.Vector3, end: THREE.Vector3, plane: THREE.Plane, distance: number): THREE.Vector3[] | null {
    const intersectionPoint = plane.intersectLine(new THREE.Line3(start, end), new THREE.Vector3());
    if (intersectionPoint) {
      const delta = plane.normal.clone().multiplyScalar(distance / 2);

      if (plane.distanceToPoint(start) < 0) {
        delta.negate();
      }

      start.add(delta);
      end.sub(delta);

      return [start, end];
    }

    return null;
  }
  static stretchRoomWallWithOpening(
    soWall: THREE.Object3D,
    soOpening: THREE.Object3D,
    plane: THREE.Plane,
    distance: number,
    movementDelta: THREE.Vector3
  ): void {
    let stretchPlane = plane;
    const openData = SceneUtils.getOpeningZoneAndLine(soOpening);
    if (!openData) {
      return;
    }

    const { line, center, zone } = openData;
    let axis = GeometryUtils.isLineHorizontal(line) ? "x" : "y";
    //move line back to original place
    line.start[axis] += movementDelta[axis] * -1;
    line.end[axis] += movementDelta[axis] * -1;

    const centerLocal = center.clone().applyMatrix4(soWall.matrixWorld.clone().invert());
    const lineLocal = line.clone().applyMatrix4(soWall.matrixWorld.clone().invert());
    const lineLocalNormal = lineLocal.end.clone().sub(lineLocal.start.clone()).normalize();
    const prevPlanePoint = plane.coplanarPoint(new THREE.Vector3());
    const prevPlanePointLocal = prevPlanePoint.clone().applyMatrix4(soWall.matrixWorld.clone().invert());

    axis = GeometryUtils.isLineHorizontal(lineLocal) ? "x" : "y";

    const wallPoints = soWall.userData.orderedPoints ?? [];
    const [left, right] = soOpening.userData.wallPointIndexes ?? [[], []];
    const closestPointsMesh: [THREE.Vector3, THREE.Vector3] = [null, null];
    const closestPointsLine: [THREE.Vector3, THREE.Vector3] = [null, null];
    if (left.length > 0 && right.length > 0) {
      //find closest point
      const minMax = [Math.max(...left), Math.min(...right)];
      soWall.traverse((child: THREE.Object3D) => {
        const geometry = (child as any).geometry;

        if ((child as THREE.Mesh).isMesh && geometry instanceof THREE.BufferGeometry) {
          closestPointsMesh.every((cp, index) => {
            //if (cp) return true;
            const wp = wallPoints[minMax[index]].points[child.userData.childId];
            if (wp) {
              closestPointsMesh[index] = new THREE.Vector3().fromBufferAttribute(geometry.attributes.position, wp[0]);
            }
            return true;
          });
          return;
        }
        if ((child as THREE.Mesh).isMesh && geometry instanceof THREE.Geometry) {
          closestPointsMesh.every((cp, index) => {
            //if (cp) return true;
            const wp = wallPoints[minMax[index]].points[child.userData.childId];
            if (wp) {
              closestPointsMesh[index] = geometry.vertices[wp[0]];
            }
            return true;
          });
          return;
        }
        if (child.type === "Line" && geometry.attributes.position.count === 2) {
          closestPointsLine.every((cp, index) => {
            //if (cp) return true;
            const wp = wallPoints[minMax[index]].points[child.userData.childId];
            if (wp) {
              closestPointsLine[index] = new THREE.Vector3().fromBufferAttribute(geometry.attributes.position, wp[0]);
            }
            return true;
          });
          return;
        }
      });
    }
    let newPoint: THREE.Vector3;
    let cp: THREE.Vector3;
    if (centerLocal[axis] > prevPlanePointLocal[axis]) {
      if (lineLocalNormal[axis] > 0) {
        newPoint = lineLocal.start.clone();
        if (closestPointsMesh[0]) {
          cp = closestPointsMesh[0];
        }
      } else {
        newPoint = lineLocal.end.clone();
        if (closestPointsMesh[1]) {
          cp = closestPointsMesh[1];
        }
      }
      if (cp) {
        if (MathUtils.isNumberLessOrEqual(cp[axis], newPoint[axis])) {
          newPoint = cp;
        }
      }
    } else {
      if (lineLocalNormal[axis] > 0) {
        newPoint = lineLocal.end.clone();
        if (closestPointsMesh[1]) {
          cp = closestPointsMesh[1];
        }
      } else {
        newPoint = lineLocal.start.clone();
        if (closestPointsMesh[0]) {
          cp = closestPointsMesh[0];
        }
      }
      if (cp) {
        if (MathUtils.isNumberGreaterOrEqual(cp[axis], newPoint[axis])) {
          newPoint = cp;
        }
      }
    }
    const planeNewPoint = newPoint.clone().applyMatrix4(soWall.matrixWorld.clone());
    stretchPlane = new THREE.Plane().setFromNormalAndCoplanarPoint(plane.normal.clone(), planeNewPoint);

    const stretchDistance = distance / 2;
    soWall.traverse((child: THREE.Object3D) => {
      const localPlane = stretchPlane.clone().applyMatrix4(child.matrixWorld.clone().invert());
      const zoneLocal = zone.clone().applyMatrix4(child.matrixWorld.clone().invert());
      axis = GeometryUtils.isLineHorizontal(zoneLocal) ? "x" : "y";

      const moveVertices = (vertices: THREE.Vector3[]) => {
        wallPoints.forEach((wp, wpIndex) => {
          const points = wp.points[child.userData.childId];
          if (points && points.length > 0) {
            points.forEach(pIndex => {
              const vertex = vertices[pIndex];
              if (localPlane.distanceToPoint(vertex) < 0) {
                localPlane.negate();
              }
              if (left.includes(wpIndex) || right.includes(wpIndex)) {
                vertex.add(movementDelta);
              } else {
                const localDelta = localPlane.normal.clone().multiplyScalar(stretchDistance);
                vertex.add(localDelta);
              }
            });
          }
        });
      };

      const geometry = (child as any).geometry;
      if ((child as THREE.Mesh).isMesh && geometry instanceof THREE.BufferGeometry) {
        const vertices = [];
        for (let i = 0; i < geometry.attributes.position.count; i++) {
          vertices.push(new THREE.Vector3().fromBufferAttribute(geometry.attributes.position, i));
        }
        moveVertices(vertices);
        geometry.setFromPoints(vertices);
        geometry.computeBoundingBox();
        geometry.computeBoundingSphere();
        return;
      }
      if ((child as THREE.Mesh).isMesh && geometry instanceof THREE.Geometry) {
        moveVertices(geometry.vertices);
        geometry.verticesNeedUpdate = true;
        geometry.computeBoundingBox();
        geometry.computeBoundingSphere();
        return;
      }
      if (child.type === "Line" && geometry.attributes.position.count === 2) {
        const start = new THREE.Vector3().fromBufferAttribute(geometry.attributes.position, 0);
        const end = new THREE.Vector3().fromBufferAttribute(geometry.attributes.position, 1);
        const linePoints = [start, end];
        moveVertices(linePoints);
        geometry.dispose();
        (child as THREE.Line).geometry = new THREE.BufferGeometry().setFromPoints(linePoints);
        if ((child as THREE.Line).material instanceof THREE.LineDashedMaterial) {
          (child as THREE.Line).computeLineDistances();
        }
        return;
      }
    });
    soWall.updateMatrixWorld();
  }
  static stretchRoomEntity(entity: THREE.Object3D, plane: THREE.Plane, distance: number): void {
    entity.traverse((child: THREE.Object3D) => {
      const localPlane = plane.clone().applyMatrix4(child.matrixWorld.clone().invert());

      const geometry = (child as any).geometry;
      if ((child as THREE.Mesh).isMesh && geometry instanceof THREE.BufferGeometry) {
        const points = [];
        for (let i = 0; i < geometry.attributes.position.count; i++) {
          points.push(new THREE.Vector3().fromBufferAttribute(geometry.attributes.position, i));
        }

        points.forEach(point => {
          if (localPlane.distanceToPoint(point) < 0) {
            localPlane.negate();
          }
          point.add(localPlane.normal.clone().multiplyScalar(distance / 2));
        });

        geometry.setFromPoints(points);
        geometry.computeBoundingBox();
        geometry.computeBoundingSphere();
        return;
      }

      if ((child as THREE.Mesh).isMesh && geometry instanceof THREE.Geometry) {
        geometry.vertices.forEach(vertex => {
          if (localPlane.distanceToPoint(vertex) < 0) {
            localPlane.negate();
          }
          vertex.add(localPlane.normal.clone().multiplyScalar(distance / 2));
        });

        geometry.verticesNeedUpdate = true;
        geometry.computeBoundingBox();
        geometry.computeBoundingSphere();
        return;
      }

      if (child.type === "Line" && geometry.attributes.position.count === 2) {
        const start = new THREE.Vector3().fromBufferAttribute(geometry.attributes.position, 0);
        const end = new THREE.Vector3().fromBufferAttribute(geometry.attributes.position, 1);

        const points = SceneUtils.createStretchedLine(start, end, localPlane, distance);
        if (points) {
          geometry.dispose();
          (child as THREE.Line).geometry = new THREE.BufferGeometry().setFromPoints(points);
        } else {
          if (localPlane.distanceToPoint(start) < 0) {
            localPlane.negate();
          }
          child.translateOnAxis(localPlane.normal, distance / 2);
        }
        if ((child as THREE.Line).material instanceof THREE.LineDashedMaterial) {
          (child as THREE.Line).computeLineDistances();
        }
        return;
      }

      if (child.children.length === 0) {
        const point = GeometryUtils.getPointOnObject(child);
        if (point) {
          const signedDistance = plane.distanceToPoint(point);
          if (signedDistance < 0) {
            localPlane.negate();
          }
          child.translateOnAxis(localPlane.normal, distance / 2);
        }
      }
    });
  }

  /**
   * Moves vertices of a geometry within the bounding box along the given axis vector.
   *
   * @param object - The Object whose vertices need to be adjusted.
   * @param boundingBox - The bounding box to check for intersections.
   * @param axisVector - The axis vector along which to move vertices.
   * @param distance - The distance to move the vertices.
   */
  static MoveIntersectingPoints(object: THREE.Object3D, boundingBox: THREE.Box3, axisVector: THREE.Vector3, distance: number): void {
    const geometry = (object as any).geometry;

    if (object.type == "Mesh" && !geometry.attributes.position) return;
    // Iterate through each vertex of the geometry
    for (let i = 0; i < geometry.attributes.position.count; i++) {
      const point = new THREE.Vector3().fromBufferAttribute(geometry.attributes.position, i);

      // If the vertex intersects with the bounding box, move it along the axis vector
      if (GeometryUtils.isPointInsideBoundingBox(point, boundingBox, 0.0001)) {
        point.addScaledVector(axisVector, distance);

        // Update the geometry with the new position
        geometry.attributes.position.setXYZ(i, point.x, point.y, point.z);
      }
    }

    // Mark the geometry as updated and recalculate its bounding box and sphere
    geometry.attributes.position.needsUpdate = true;
    geometry.computeBoundingBox();
    geometry.computeBoundingSphere();
  }

  static stretchRoomByPlane(
    soRoom: THREE.Object3D,
    plane: THREE.Plane,
    distance: number,
    referenceLineId: string = null,
    isPointDragging: boolean = false
  ): void {
    const bb = RoomUtils.getRoomBoundingBoxByModelLines(soRoom);
    const soRoomCenter = bb.getCenter(new THREE.Vector3());
    const soRoomSize = bb.getSize(new THREE.Vector3());
    const soRoomDiagonal = 2 * Math.sqrt(Math.pow(soRoomSize.x, 2) + Math.pow(soRoomSize.y, 2));
    const wallLines = soRoom.children.reduce((map, child) => {
      if (child.userData.type === RoomEntityType.Wall || child.userData.type === RoomEntityType.PlumbingWall) {
        map.set(child.userData.revitId, GeometryUtils.getBoundingBoxCenterLine(GeometryUtils.getGeometryBoundingBox2D(child)));
      }
      return map;
    }, new Map<string, THREE.Line3>());

    const { openings, otherChildren, zones } = soRoom.children.reduce(
      (array, child) => {
        if (child.userData.type === RoomEntityType.Window || child.userData.type === RoomEntityType.Door) {
          array.openings.push(child);
        } else if (child.userData.type === RoomEntityType.OpeningZone) {
          array.zones.push(child);
        } else {
          array.otherChildren.push(child);
        }
        return array;
      },
      { openings: [], otherChildren: [], zones: [] }
    );

    //stretch W zone first
    zones.forEach(child => SceneUtils.stretchRoomEntity(child, plane, distance));
    soRoom.updateMatrixWorld();

    //stretch openings
    const openingDeltas = new Map<string, THREE.Vector3>();
    const openingIds = new Map<string, string>();
    const wallOpeningsMap = new Map<string, THREE.Line3>();
    openings.forEach(child => {
      const localPlane = plane.clone().applyMatrix4(child.matrixWorld.clone().invert());
      const openingBox = GeometryUtils.getGeometryBoundingBox2D(child);

      let point = openingBox.clone().getCenter(new THREE.Vector3());
      const openData = SceneUtils.getOpeningZoneAndLine(child);
      const hostingWallLine = wallLines.get(child.userData.wallBindingRevitId);
      if (hostingWallLine) {
        point = hostingWallLine.closestPointToPoint(point, false, new THREE.Vector3());
        if (openingBox.intersectsPlane(plane)) {
          if (openData) {
            wallOpeningsMap.set(child.userData.wallBindingRevitId, openData.line);
          } else {
            wallOpeningsMap.set(child.userData.wallBindingRevitId, SceneUtils.getOpeningProjectionOnWallLine(openingBox, hostingWallLine));
          }
          openingIds.set(child.userData.wallBindingRevitId, child.userData.id);
        }
      }

      if (plane.distanceToPoint(point) < 0) {
        localPlane.negate();
      }
      const delta = localPlane.normal.clone().multiplyScalar(distance / 2);

      if (openData) {
        let axis = GeometryUtils.isLineHorizontal(openData.zone) ? "x" : "y";
        if (MathUtils.areNumbersEqual(Math.abs(plane.normal[axis]), 1)) {
          const { zone, line, center } = openData;
          const centerLocal = center.clone().applyMatrix4(child.matrixWorld.clone().invert());
          const lineLocal = line.clone().applyMatrix4(child.matrixWorld.clone().invert());
          const lineLocalCenter = lineLocal.getCenter(new THREE.Vector3());
          const zoneLocal = zone.clone().applyMatrix4(child.matrixWorld.clone().invert());
          const zoneLocalNormal = zoneLocal.end.clone().sub(zoneLocal.start.clone()).normalize();

          axis = GeometryUtils.isLineHorizontal(zoneLocal) ? "x" : "y";

          const nextBbMin = lineLocal.start.clone().add(delta);
          const nextBbMax = lineLocal.end.clone().add(delta);
          const isZoneAndDeltaSameDirection = (zoneLocalNormal[axis] > 0 && delta[axis] > 0) || (zoneLocalNormal[axis] < 0 && delta[axis] < 0) ? true : false;
          if (isZoneAndDeltaSameDirection) {
            if (delta[axis] > 0) {
              if (nextBbMax[axis] > zoneLocal.end[axis]) {
                delta[axis] -= nextBbMax[axis] - zoneLocal.end[axis];
              }
            }
            if (delta[axis] < 0) {
              if (nextBbMax[axis] < zoneLocal.end[axis]) {
                delta[axis] += zoneLocal.end[axis] - nextBbMax[axis];
              }
            }
          } else {
            if (delta[axis] > 0) {
              if (nextBbMin[axis] > zoneLocal.start[axis]) {
                delta[axis] -= nextBbMin[axis] - zoneLocal.start[axis];
              }
            }
            if (delta[axis] < 0) {
              if (nextBbMin[axis] < zoneLocal.start[axis]) {
                delta[axis] += zoneLocal.start[axis] - nextBbMin[axis];
              }
            }
          }
          const newCenter = lineLocalCenter.clone().add(delta.clone());
          let shiftDistance = newCenter[axis] - centerLocal[axis];
          if (zoneLocal.start[axis] > zoneLocal.end[axis]) {
            shiftDistance = shiftDistance * -1;
          }
          child.userData.shiftDistance = shiftDistance * child.parent.scale.x;
        }
      }
      delta.applyQuaternion(child.quaternion);
      child.position.add(delta);
      openingDeltas.set(child.userData.id, delta);
    });

    otherChildren.forEach(child => {
      if (child.userData.type === RoomEntityType.Wall) {
        const line = wallOpeningsMap.get(child.userData.revitId);
        let stretchDone = false;
        if (line && MathUtils.areNumbersEqual(Math.abs(plane.normal[GeometryUtils.isLineHorizontal(line) ? "x" : "y"]), 1)) {
          const openingId = openingIds.get(child.userData.revitId);
          if (openingId) {
            const soOpening = child.parent.children.find(c => c.userData.id === openingId);
            if (soOpening) {
              const delta = openingDeltas.get(soOpening.userData.id);
              SceneUtils.stretchRoomWallWithOpening(child, soOpening, plane, distance, delta);
              stretchDone = true;
            }
          }
        }
        if (!stretchDone) {
          SceneUtils.stretchRoomEntity(child, plane, distance);
        }
        // if (!stretchDone && !GeometryUtils.planeIntersectsObject3D(plane, child)) {
        //   SceneUtils.stretchRoomEntity(child, plane, distance);
        // }
        return;
      }

      if (
        child.userData.type === RoomEntityType.PlumbingWall ||
        child.userData.type === RoomEntityType.Floor ||
        child.userData.type === SceneEntityType.ObsoleteRoomIndicator ||
        child.userData.type === RoomEntityType.ModelLine ||
        child.userData.type === RoomEntityType.RoomBoundaryLines ||
        (child.userData.type === RoomEntityType.Furniture && child.userData.isStretchable)
      ) {
        SceneUtils.stretchRoomEntity(child, plane, distance);
        return;
      }

      const localPlane = plane.clone().applyMatrix4(child.matrixWorld.clone().invert());

      if (child.userData.type === RoomEntityType.ReferenceLine) {
        const pointsGeometry = (child as THREE.Points).geometry as THREE.BufferGeometry;
        const points = GeometryUtils.getPointsPositions(pointsGeometry);
        const delta = localPlane.normal.clone().multiplyScalar(distance / 2);

        if (localPlane.distanceToPoint(points[0]) < 0) {
          delta.negate();
        }

        if (child.userData.id !== referenceLineId) {
          pointsGeometry.translate(delta.x, delta.y, delta.z);
        } else if (!isPointDragging) {
          points[0].add(delta);
          points[1].sub(delta);
          pointsGeometry.setFromPoints(points);
          pointsGeometry.computeBoundingSphere();
        }
        return;
      }

      if (child.userData.type === SceneEntityType.StretchTriangle && child.children.some(it => it instanceof SoStretchTriangle)) {
        let isHorizontal = child.userData.horizontal;
        let isVertical = child.userData.vertical;
        if (MathUtils.areNumbersEqual(Math.abs(soRoom.rotation.z), Math.PI / 2)) {
          isHorizontal = child.userData.vertical;
          isVertical = child.userData.horizontal;
        }

        if ((isHorizontal && MathUtils.areNumbersEqual(plane.normal.x, 0)) || (isVertical && MathUtils.areNumbersEqual(plane.normal.y, 0))) {
          return;
        }
      }

      let point = null;
      if (child.userData.type === SceneEntityType.StretchTriangle) {
        const triangleDirection = GeometryUtils.getPointOnObject(child).sub(soRoomCenter).normalize();
        point = triangleDirection.multiplyScalar(soRoomDiagonal).add(soRoomCenter); // point on stretch triangle should be out of the soRoom
      } else {
        point = new THREE.Box3().setFromObject(child).getCenter(new THREE.Vector3());
        const hostingWallLine = wallLines.get(child.userData.wallBindingRevitId);
        if (hostingWallLine) {
          point = hostingWallLine.closestPointToPoint(point, false, new THREE.Vector3());
        }
      }

      if (point) {
        if (plane.distanceToPoint(point) < 0) {
          localPlane.negate();
        }
        if (child.userData.type !== RoomEntityType.Wall) {
          child.translateOnAxis(localPlane.normal, distance / 2);
        }
      }
    });

    soRoom.updateMatrixWorld();
  }

  static stretchRoomByReferenceLine(soRoom: THREE.Object3D, referenceLine: ReferenceLine, distance: number, isPointDragging: boolean = false): number {
    const finalStretch = referenceLine.currentStretch + distance;
    let rest = 0;
    if (finalStretch > referenceLine.max) {
      rest = finalStretch - referenceLine.max;
    }
    if (finalStretch < referenceLine.min) {
      rest = finalStretch - referenceLine.min;
    }
    distance -= rest;
    if (MathUtils.areNumbersEqual(distance, 0)) {
      return rest;
    }

    // build plane in world space
    const start = referenceLine.start;
    const end = referenceLine.end;
    const normal = end
      .clone()
      .sub(start)
      .cross(new THREE.Vector3(0, 0, 1))
      .normalize();
    const plane = new THREE.Plane().setFromNormalAndCoplanarPoint(normal, start);

    SceneUtils.stretchRoomByPlane(soRoom, plane, distance, referenceLine.id, isPointDragging);

    // save stretch
    const newStretch = referenceLine.currentStretch + distance;
    referenceLine.currentStretch = newStretch;
    referenceLine.startParent.userData.currentStretch = newStretch;
    referenceLine.endParent.userData.currentStretch = newStretch;

    return rest;
  }

  static getFloorRooms(soFloor: THREE.Object3D): THREE.Object3D[] {
    return soFloor ? FloorUtils.getFloorSoRooms(soFloor) : [];
  }

  static getRoomOpenings(soRoom: THREE.Object3D): THREE.Object3D[] {
    return soRoom.children.filter(so => so.userData.type === RoomEntityType.Door || so.userData.type === RoomEntityType.Window || so.userData.isOpening);
  }
  static getRoomsOpeningsAsLines(soRooms: THREE.Object3D[], onlyDoors = false): Segment[] {
    const openingsFilter = onlyDoors
      ? (child: THREE.Object3D) => child.userData.type === RoomEntityType.Door
      : (child: THREE.Object3D) => child.userData.type === RoomEntityType.Window || child.userData.type === RoomEntityType.Door || child.userData.isOpening;

    return soRooms.flatMap(soRoom => {
      const openingBboxes = soRoom.children.filter(openingsFilter).map(child => GeometryUtils.getGeometryBoundingBox2D(child));

      if (!openingBboxes.length) {
        return [];
      }

      const modelLines = soRoom.children
        .filter(child => child.userData.type === RoomEntityType.ModelLine || child.userData.type === RoomEntityType.RoomBoundaryLines)
        .map(ml => {
          const [start, end] = GeometryUtils.getPointsPositions((ml as any).geometry);
          return new THREE.Line3(start, end).applyMatrix4(ml.matrixWorld);
        });

      const openingLines: Segment[] = [];
      openingBboxes.forEach(bbox => {
        for (const modelLine of modelLines) {
          const openingIntersection = SceneUtils.getOpeningAndWallIntersection(modelLine, bbox);
          if (openingIntersection) {
            const segment = Segment.fromLine3(openingIntersection);
            segment.roomId = soRoom.userData.id;
            openingLines.push(segment);
            break;
          }
        }
      });

      return openingLines;
    });
  }

  static getRoomWallLines(soRoom: THREE.Object3D, direction: Direction, shift: THREE.Vector3 = null) {
    const lines: THREE.Line3[] = [];

    soRoom?.children.forEach(child => {
      if (child.userData.type !== RoomEntityType.Wall) {
        return;
      }

      const wallLine = GeometryUtils.getBoundingBoxCenterLine(GeometryUtils.getGeometryBoundingBox2D(child));
      const wallDirection = GeometryUtils.isLineHorizontal(wallLine) ? Direction.Horizontal : Direction.Vertical;

      if (direction !== wallDirection) {
        return;
      }

      if (shift) {
        wallLine.start.add(shift);
        wallLine.end.add(shift);
      }

      lines.push(wallLine);
    });

    return lines;
  }

  /**
   * Determines the wall type based on a given wall model line.
   * @param segment - The wall segment to analyze. Segment should have CCW orientation relative to hosting room.
   * @param isGarage - Indicates if the hosting room is a garage.
   * @param segments - Room segments from the entire floor.
   */
  static getRoomSegmentWallTypeData(segment: Segment, isGarage: boolean, segments: ISegments): { type: WallType; segment: Segment } {
    let typeSegment: Segment = segments.grg.find(s => SegmentsUtils.segmentsOverlap(segment, s));

    if (typeSegment) {
      return { type: WallType.GRG, segment: typeSegment };
    }

    if (!isGarage) {
      // DDL segment should has same direction.
      typeSegment = segments.ddl.find(s => SegmentsUtils.segmentsOverlap(segment, s) && segment.delta().dot(s.delta()) > 0);
      if (typeSegment) {
        return { type: WallType.DDL, segment: typeSegment };
      }

      typeSegment = segments.internal.find(s => SegmentsUtils.segmentsOverlap(segment, s));
      if (typeSegment) {
        return { type: WallType.INT, segment: typeSegment };
      }
    }

    typeSegment = segments.external.find(s => SegmentsUtils.segmentsOverlap(segment, s));
    return { type: WallType.EXT, segment: typeSegment };
  }

  /**
   *@deprecated
   */
  static getRoomBoundingBoxByWallType(soRoom: THREE.Object3D, isGarage: boolean, isNet: boolean, segments: ISegments) {
    const box = RoomUtils.getRoomBoundingBoxByModelLines(soRoom);

    if (isNet) {
      // Segments oriented in CCW.

      // const bottom = new Segment(new THREE.Vector2(box.min.x, box.min.y), new THREE.Vector2(box.max.x, box.min.y));
      // const right = new Segment(new THREE.Vector2(box.max.x, box.min.y), new THREE.Vector2(box.max.x, box.max.y));
      // const top = new Segment(new THREE.Vector2(box.max.x, box.max.y), new THREE.Vector2(box.min.x, box.max.y));
      // const left = new Segment(new THREE.Vector2(box.min.x, box.max.y), new THREE.Vector2(box.min.x, box.min.y));

      //   return catalogSettings.walls[data.type].coreThickness;
      // };

      box.max.y -= RoomUtils.getWallInternalThickness(soRoom, Side.top);
      box.min.y += RoomUtils.getWallInternalThickness(soRoom, Side.bottom);
      box.max.x -= RoomUtils.getWallInternalThickness(soRoom, Side.right);
      box.min.x += RoomUtils.getWallInternalThickness(soRoom, Side.left);

      // Subtract thickness of plumbing walls.
      soRoom.children.forEach(child => {
        if (child.userData.type !== RoomEntityType.PlumbingWall) {
          return;
        }

        const wallBox = GeometryUtils.getGeometryBoundingBox2D(child);
        const wallLine = GeometryUtils.getBoundingBoxCenterLine(wallBox);
        const wallThickness = catalogSettings.walls[FuncCode.EXT_2X4_WET].exteriorThickness + catalogSettings.walls[FuncCode.EXT_2X4_WET].interiorThickness;
        // const wallThickness = catalogSettings.walls[WallType.PLM].exteriorThickness + catalogSettings.walls[WallType.PLM].interiorThickness;

        if (GeometryUtils.isLineHorizontal(wallLine)) {
          if (box.max.y - wallLine.start.y < wallLine.start.y - box.min.y) {
            box.max.y -= wallThickness;
          } else {
            box.min.y += wallThickness;
          }
        } else {
          if (box.max.x - wallLine.start.x < wallLine.start.x - box.min.x) {
            box.max.x -= wallThickness;
          } else {
            box.min.x += wallThickness;
          }
        }
      });
    } else {
      // For the exterior boundary is enough to consider that all walls are external.
      const thickness = catalogSettings.walls[WallType.EXT].exteriorThickness;
      box.expandByVector(new THREE.Vector3(thickness, thickness));
    }

    return box;
  }
  static getSoRoomBoundingBoxByWallType(soRoom: soRoom2D, isNet: boolean) {
    let box: THREE.Box3;

    if (isNet) {
      //ToChange internal boundaries
      box = soRoom.NetBoundingBox;
    } else {
      // For the exterior boundary is enough to consider that all walls are external.
      soRoom.GrossBoundingBox;
    }

    return box;
  }

  /**
   * Calculates the part of the opening bounding box that lies inside the wall. If there is no intersection returns bounding box of the opening.
   */
  static getRoomOpeningBoundingBoxInsideHostingWall(soOpening: THREE.Object3D): THREE.Box3 {
    const openingBox = GeometryUtils.getGeometryBoundingBox2D(soOpening);
    const soHostingWall = soOpening.parent.children.find(child => child.userData.revitId === soOpening.userData.wallBindingRevitId);

    if (soHostingWall) {
      const wallBox = GeometryUtils.getGeometryBoundingBox2D(soHostingWall);
      const result = openingBox.intersect(wallBox);

      if (!result.isEmpty()) {
        return result;
      }
    }

    return openingBox;
  }

  static getRoomPLMWalls(soRoom: THREE.Object3D): THREE.Object3D[] {
    return soRoom.children.filter(so => so.userData.type === RoomEntityType.PlumbingWall);
  }
  static hasRoomPLMWalls(soRoom: THREE.Object3D): boolean {
    return soRoom.children.some(so => so.userData.type === RoomEntityType.PlumbingWall);
  }
  static getRoomPLMPoints(soRoom: THREE.Object3D): THREE.Object3D[] {
    return soRoom.children.filter(so => so.userData.type === RoomEntityType.PlumbingPoint);
  }
  static hasRoomPLMPoints(soRoom: THREE.Object3D): boolean {
    return soRoom.children.some(so => so.userData.type === RoomEntityType.PlumbingPoint);
  }

  static displayRoomStretchControls(room: THREE.Object3D, visible = true, lineOnly = false): void {
    room.children.forEach(child => {
      if (child.userData.type === RoomEntityType.ReferenceLine || (!lineOnly && child.userData.type === SceneEntityType.StretchTriangle)) {
        child.visible = visible;
      }
    });
  }

  static highlightSelectedRoom(room: THREE.Object3D, force = false): void {
    if (room.userData.isSelected && !force) {
      return;
    }

    room.userData.isSelected = true;

    RoomEditToolPosition.setPosition(true);
    SceneUtils.traverseWithNodeAbort(room, child => {
      const isHighlightedAsIntersect =
        room.userData.isIntersected && (child.userData.type === RoomEntityType.Window || child.userData.type === RoomEntityType.Door);
      if (isHighlightedAsIntersect || child.userData.type === RoomEntityType.Furniture || child.userData.type === RoomEntityType.ReferenceLine) {
        return true;
      }
      if ((child.userData.type === RoomEntityType.Door || child.userData.type === RoomEntityType.Window) && child.userData.isBlockedBy) {
        return true;
      }

      SceneUtils.setLineColor(child, settings.getColorNumber(WebAppUISettingsKeys.selectionColor));
      return false;
    });
  }
  static unhighlightSelectedRoom(room: THREE.Object3D): void {
    if (!room.userData.isSelected) {
      return;
    }

    delete room.userData.isSelected;

    RoomEditToolPosition.setPosition(false);

    if (room.userData.isIntersected || room.userData.isBlockingDoor) {
      SceneUtils.highlightIntersectedRoom(room, true, room.userData.isBlockingDoor);
      return;
    }

    SceneUtils.traverseWithNodeAbort(room, child => {
      if ((child.userData.type === RoomEntityType.Door || child.userData.type === RoomEntityType.Window) && child.userData.isBlockedBy) {
        return true;
      }
      if (child instanceof THREE.Line && child.userData.originalColor !== undefined) {
        child.material.color.setHex(child.userData.originalColor);
        delete child.userData.originalColor;
      }
    });
  }

  static highlightIntersectedRoom(room: THREE.Object3D, force = false, highlightBlock = false): void {
    if (room.userData.isIntersected && !force) {
      return;
    }

    if (!highlightBlock) {
      room.userData.isIntersected = true;
    }

    SceneUtils.traverseWithNodeAbort(room, child => {
      if (
        room.userData.isSelected &&
        (child.userData.type === RoomEntityType.Floor ||
          child.userData.type === RoomEntityType.Wall ||
          child.userData.type === RoomEntityType.ModelLine ||
          child.userData.type === RoomEntityType.RoomBoundaryLines)
      ) {
        return true;
      }

      if ((child.userData.type === RoomEntityType.Door || child.userData.type === RoomEntityType.Window) && child.userData.isBlockedBy) {
        return true;
      }

      SceneUtils.setLineColor(child, INTERSECTED_COLOR);
      return false;
    });
  }
  static unhighlightIntersectedRoom(room: THREE.Object3D, unhighlightBlock = false): void {
    if (!room.userData.isIntersected && !unhighlightBlock) {
      return;
    }

    delete room.userData.isIntersected;

    if (!unhighlightBlock && room.userData.isBlockingDoor) {
      return;
    }

    SceneUtils.traverseWithNodeAbort(room, child => {
      if (
        room.userData.isSelected &&
        (child.userData.type === RoomEntityType.Floor ||
          child.userData.type === RoomEntityType.Wall ||
          child.userData.type === RoomEntityType.ModelLine ||
          child.userData.type === RoomEntityType.RoomBoundaryLines)
      ) {
        return true;
      }

      if ((child.userData.type === RoomEntityType.Door || child.userData.type === RoomEntityType.Window) && child.userData.isBlockedBy) {
        return true;
      }

      if (child instanceof THREE.Line && child.userData.originalColor !== undefined) {
        child.material.color.setHex(child.userData.originalColor);
        delete child.userData.originalColor;
      }
      return false;
    });

    if (room.userData.isSelected) {
      SceneUtils.highlightSelectedRoom(room, false);
      return;
    }
  }

  static highlightBlockedOpening(opening: THREE.Object3D, blockedBy: string): void {
    opening.userData.isBlockedBy = blockedBy;
    opening.traverse(child => {
      SceneUtils.setLineColor(child, INTERSECTED_COLOR);
      return false;
    });
  }
  static unhighlightBlockedOpening(opening: THREE.Object3D): void {
    delete opening.userData.isBlocked;
    opening.traverse(child => {
      if (child instanceof THREE.Line && child.userData.originalColor !== undefined) {
        child.material.color.setHex(child.userData.originalColor);
        delete child.userData.originalColor;
      }
    });
  }

  static updateStretchControlHighlight(stretchControl: THREE.Object3D, isHighlighted: boolean): void {
    if (!stretchControl) {
      return;
    }

    if (stretchControl.userData.type === SceneEntityType.StretchTriangle) {
      if (stretchControl instanceof SoStretchTriangle) {
        stretchControl.updateHighlight(isHighlighted);
      } else {
        stretchControl.children.forEach(triangle => triangle instanceof SoStretchTriangle && triangle.updateHighlight(isHighlighted));
      }
    }
  }

  static setItemVisibility(obj: THREE.Object3D, visible: boolean) {
    if (obj.userData.type === RoomEntityType.ModelLine || obj.userData.type === RoomEntityType.RoomBoundaryLines) {
      return;
    }

    obj.children.forEach(child => {
      SceneUtils.setItemVisibility(child, visible);
    });

    if ((obj as any).material) {
      (obj as any).material.visible = visible;
    }
  }

  static setModelLinesColorForFloor(soFloor: THREE.Object3D, color: number) {
    FloorUtils.getFloorSoRooms(soFloor).forEach(soRoom => {
      soRoom.children
        .filter(c => c.userData.type === RoomEntityType.ModelLine || c.userData.type === RoomEntityType.RoomBoundaryLines)
        .forEach(ml => {
          ((ml as THREE.Line).material as THREE.LineDashedMaterial).color.setHex(color);
          (ml as THREE.Line).computeLineDistances();
        });
    });
  }

  static displayFloorContour(soRoot: THREE.Object3D, floor: THREE.Object3D, visible: boolean) {
    const floorContour = soRoot.children.find(c => c.userData.type === SceneEntityType.FloorContour && c.userData.floorId === floor.userData.id);
    if (floorContour) {
      soRoot.remove(floorContour);
    }
    if (!visible) {
      return;
    }

    const wallProperties = new Map<string, any>();

    FloorUtils.getFloorSoRooms(floor).forEach(room => {
      const roomWalls = room.children.filter(c => c.userData.type === RoomEntityType.Wall || c.userData.type === SceneEntityType.SyntheticWall);

      const roomModelLines = Object.entries(RoomUtils.getRoomLinesByType(room, RoomEntityType.ModelLine));
      wallProperties.set(room.userData.id, []);
      roomWalls.forEach(roomWall => {
        const wallBbox = GeometryUtils.getGeometryBoundingBox2D(roomWall);
        const wallLine = GeometryUtils.getBoundingBoxCenterLine(wallBbox);
        const isWallHorizontal = GeometryUtils.isLineHorizontal(wallLine);
        const component = isWallHorizontal ? "y" : "x";
        const wallModelLine = roomModelLines.find(
          modelLine =>
            GeometryUtils.isLineHorizontal(modelLine[1]) === isWallHorizontal &&
            MathUtils.areNumbersEqual(modelLine[1].start[component], wallLine.start[component], 0.5)
        );

        if (!wallModelLine) {
          return;
        }

        const isTopRight = wallModelLine[0] === "top" || wallModelLine[0] === "right";

        wallProperties.get(room.userData.id).push({
          id: roomWall.uuid,
          line: wallModelLine[1],
          isHorizontal: isWallHorizontal,
          isTopRight,
          halfWallWidth: isTopRight ? wallBbox.max[component] - wallModelLine[1].start[component] : wallModelLine[1].start[component] - wallBbox.min[component],
        });
      });
    });

    const { externalSegments } = WallAnalysisUtils.collectSegments(soRoot.parent, FloorUtils.getFloorSoRooms(floor));

    // Associate line segments with their corresponding walls.
    const segmentWallMap = externalSegments
      .filter(es => es.length() > 1e-4) // Remove too small segments.
      .reduce((map, segment) => {
        const isHorizontal = segment.isHorizontal;
        const component = isHorizontal ? "y" : "x";
        const wall = wallProperties
          .get(segment.roomId[0])
          .find(w => isHorizontal === w.isHorizontal && MathUtils.areNumbersEqual(w.line.start[component], segment.start[component]));

        if (map.has(wall)) {
          map.get(wall).push(segment);
          return map;
        }

        map.set(wall, [segment]);
        return map;
      }, new Map());

    const segmentLines = [...segmentWallMap.values()].flat();

    // Move segment lines to the outer side of the walls.
    [...segmentWallMap.entries()].forEach(swm => {
      const wallProps = swm[0];
      swm[1].forEach(segment => {
        // If the line segment has no wall, do not change it.
        if (!wallProps) {
          return;
        }

        const connectedPoints = [];
        // Get connected points to the line to preserve unbreakable contour.
        for (let i = 0; i < segmentLines.length; i++) {
          const segment2 = segmentLines[i];
          // Don't shift multiple times if the segments constitute one line.
          if (wallProps.isHorizontal === segment2.isHorizontal) {
            continue;
          }

          if (VectorUtils.areVectorsEqual(segment2.start, segment.start) || VectorUtils.areVectorsEqual(segment2.start, segment.end)) {
            connectedPoints.push(segment2.start);
          } else if (VectorUtils.areVectorsEqual(segment2.end, segment.end) || VectorUtils.areVectorsEqual(segment2.end, segment.start)) {
            connectedPoints.push(segment2.end);
          }
        }

        const shift = (wallProps.isTopRight ? 1 : -1) * wallProps.halfWallWidth;
        const component = wallProps.isHorizontal ? "y" : "x";

        segment.start[component] += shift;
        segment.end[component] += shift;
        connectedPoints.forEach(p => {
          p[component] += shift;
        });
      });
    });

    const material = new THREE.LineBasicMaterial({ color: FLOOR_CONTOUR_COLOR });
    const group = new THREE.Group();
    group.name = "Floor Contour";
    [...segmentWallMap.values()].flat().forEach(segment => {
      const geometry = new THREE.BufferGeometry().setFromPoints([segment.start, segment.end]);
      const line = new THREE.Line(geometry, material);
      group.add(line);
    });

    group.userData.type = SceneEntityType.FloorContour;
    group.userData.floorId = floor.userData.id;
    group.userData.floorName = floor.name;
    soRoot.add(group);
  }

  static rotateRoom(soRoom: THREE.Object3D, angle: number, position?: THREE.Vector3): void {
    if (!position) {
      position = RoomUtils.getRoomBoundingBoxByModelLines(soRoom).getCenter(new THREE.Vector3());
    }

    const rotationAxis = new THREE.Vector3(0, 0, 1);

    const quaternion = new THREE.Quaternion().setFromAxisAngle(rotationAxis, angle).normalize();
    const matrix = new THREE.Matrix4().makeRotationFromQuaternion(quaternion);
    soRoom.position.sub(position);
    soRoom.applyMatrix4(matrix);
    soRoom.position.add(position);
    soRoom.updateMatrixWorld();
  }
  static calculateRotateRoomContentValidAngle(soRoom: THREE.Object3D, angle: number): number {
    if (MathUtils.areNumbersEqual(angle % Math.PI, 0)) {
      return angle;
    }

    const { min, max } = SceneUtils.getRoomMinMaxSizes(soRoom);
    const size = BoundingBoxUtils.getBoundingBoxSize(RoomUtils.getRoomBoundingBoxByModelLines(soRoom));

    const is90DegreeValid = MathUtils.isNumberInRange(size.x, min.y, max.y) && MathUtils.isNumberInRange(size.y, min.x, max.x);
    if (!is90DegreeValid) {
      angle *= 2;
    }

    return angle;
  }
  static rotateRoomContent(soRoom: THREE.Object3D, angle: number): number {
    const oldSize = RoomUtils.getRoomBoundingBoxByModelLines(soRoom).getSize(new THREE.Vector3());
    SceneUtils.rotateRoom(soRoom, angle);

    // Stretch to boundaries if rotation angle is not a multiple of 180.
    if (!MathUtils.areNumbersEqual(angle % Math.PI, 0)) {
      const newSize = RoomUtils.getRoomBoundingBoxByModelLines(soRoom).getSize(new THREE.Vector3());
      SceneUtils.stretchRoom(soRoom, oldSize.x - newSize.x, Direction.Horizontal);
      SceneUtils.stretchRoom(soRoom, oldSize.y - newSize.y, Direction.Vertical);
    }

    return angle;
  }

  static scaleRoomInPosition(soRoom: THREE.Object3D, scale: THREE.Vector3, position?: THREE.Vector3): void {
    if (!position) {
      position = RoomUtils.getRoomBoundingBoxByModelLines(soRoom).getCenter(new THREE.Vector3());
    }

    const matrix = new THREE.Matrix4().makeTranslation(-position.x, -position.y, -position.z);
    matrix.premultiply(new THREE.Matrix4().makeScale(scale.x, scale.y, scale.z));
    matrix.premultiply(new THREE.Matrix4().makeTranslation(position.x, position.y, position.z));

    soRoom.applyMatrix4(matrix);
    soRoom.updateMatrixWorld();
  }

  static collectReferenceLines(soRoom: THREE.Object3D, direction?: Direction): { [index: string]: ReferenceLine } {
    const refs = {} as { [index: string]: ReferenceLine };

    soRoom.children.forEach(child => {
      if (child.userData.type !== RoomEntityType.ReferenceLine) {
        return;
      }

      const { id, min, max, currentStretch } = child.userData;

      if (!refs[id]) {
        refs[id] = new ReferenceLine(id, min || 0, max || 0, currentStretch || 0);
        refs[id].startParent = child;
      } else {
        refs[id].endParent = child;

        if (direction) {
          const ref = refs[id] as ReferenceLine;
          const line = new THREE.Line3(ref.start, ref.end);
          if (
            (direction === Direction.Horizontal && GeometryUtils.isLineHorizontal(line)) ||
            (direction === Direction.Vertical && GeometryUtils.isLineVertical(line))
          ) {
            delete refs[id];
          }
        }
      }
    });

    return refs;
  }

  static collectStretchedReferenceLines(room: THREE.Object3D, distance: number, direction: Direction): ReferenceLine[] {
    const referenceLines = Object.values(SceneUtils.collectReferenceLines(room, direction));

    referenceLines.sort((a, b) => {
      if (distance > 0) {
        return a.max - a.currentStretch - (b.max - b.currentStretch);
      }

      return b.currentStretch - b.min - (a.currentStretch - a.min);
    });

    referenceLines.forEach((referenceLine, idx) => {
      let part = distance / (referenceLines.length - idx);
      const finalStretch = referenceLine.currentStretch + part;

      if (finalStretch > referenceLine.max) {
        part = referenceLine.max - referenceLine.currentStretch;
      }

      if (finalStretch < referenceLine.min) {
        part = referenceLine.min - referenceLine.currentStretch;
      }

      distance -= part;
      referenceLine.stretchedDistance = part;
    });

    return referenceLines;
  }

  static stretchRoom(soRoom: THREE.Object3D, distance: number, direction: Direction): number {
    let stretchedDistance = 0;
    const referenceLines = SceneUtils.collectStretchedReferenceLines(soRoom, distance, direction);

    referenceLines.forEach(referenceLine => {
      SceneUtils.stretchRoomByReferenceLine(soRoom, referenceLine, referenceLine.stretchedDistance);
      stretchedDistance += referenceLine.stretchedDistance;
    });
    soRoom.children.forEach(child => (child.userData.roomPosition = child.position));

    return stretchedDistance;
  }

  static getRoomMinMaxSizes(soRoom: THREE.Object3D): { min: THREE.Vector3; max: THREE.Vector3 } {
    const min = new THREE.Vector3();
    const max = new THREE.Vector3();
    const size = BoundingBoxUtils.getBoundingBoxSize(RoomUtils.getRoomBoundingBoxByModelLines(soRoom));

    // Get soRoom max and min sizes
    min.x =
      size.x +
      SceneUtils.collectStretchedReferenceLines(soRoom, Number.NEGATIVE_INFINITY, Direction.Horizontal).reduce((sum, ref) => sum + ref.stretchedDistance, 0);
    max.x =
      size.x +
      SceneUtils.collectStretchedReferenceLines(soRoom, Number.POSITIVE_INFINITY, Direction.Horizontal).reduce((sum, ref) => sum + ref.stretchedDistance, 0);
    min.y =
      size.y +
      SceneUtils.collectStretchedReferenceLines(soRoom, Number.NEGATIVE_INFINITY, Direction.Vertical).reduce((sum, ref) => sum + ref.stretchedDistance, 0);
    max.y =
      size.y +
      SceneUtils.collectStretchedReferenceLines(soRoom, Number.POSITIVE_INFINITY, Direction.Vertical).reduce((sum, ref) => sum + ref.stretchedDistance, 0);

    return {
      min,
      max,
    };
  }

  static hasRoomsSharedObjects(
    soRoom1: THREE.Object3D,
    soRoom2: THREE.Object3D
  ): { hasSharedObjects: boolean; bothHasSharedObjects: boolean; hasIntersectedSharedObjects: boolean } | null {
    const bb1 = RoomUtils.getRoomBoundingBoxByModelLines(soRoom1);
    const bb2 = RoomUtils.getRoomBoundingBoxByModelLines(soRoom2);
    const touchDirection = GeometryUtils.doBoundingBoxesTouch(bb1, bb2);

    if (touchDirection) {
      const items = soRoom1.children
        .filter(child => child.userData.type === RoomEntityType.Door || child.userData.type === RoomEntityType.Window)
        .map(child => GeometryUtils.getGeometryBoundingBox2D(child));

      const items2 = soRoom2.children
        .filter(child => child.userData.type === RoomEntityType.Door || child.userData.type === RoomEntityType.Window)
        .map(child => GeometryUtils.getGeometryBoundingBox2D(child));

      const direction = touchDirection === Direction.Horizontal ? Direction.Vertical : Direction.Horizontal;

      const lines1 = SceneUtils.getRoomWallLines(soRoom1, direction);
      const lines2 = SceneUtils.getRoomWallLines(soRoom2, direction);

      const sharedObjects = items.filter(item => lines2.some(line => GeometryUtils.lineIntersectsBoundingBox(line, item)));
      const sharedObjects2 = items2.filter(item => lines1.some(line => GeometryUtils.lineIntersectsBoundingBox(line, item)));

      const bothHasSharedObjects = sharedObjects.length > 0 && sharedObjects2.length > 0;
      const oneHasSharedObjects = sharedObjects.length > 0 || sharedObjects2.length > 0;
      return {
        hasSharedObjects: oneHasSharedObjects,
        bothHasSharedObjects: bothHasSharedObjects,
        hasIntersectedSharedObjects: GeometryUtils.doBoundingBoxesIntersect(sharedObjects, sharedObjects2),
      };
    }

    return null;
  }

  static areRoomsSnapping(soRoom1: THREE.Object3D, soRoom2: THREE.Object3D): boolean {
    const result = SceneUtils.hasRoomsSharedObjects(soRoom1, soRoom2);

    return result && !result.hasIntersectedSharedObjects;
  }

  /**
   * @deprecated  Use areSoRoomsOverlapping instead
   */
  static areRoomsOverlapping(soRoom1: THREE.Object3D, soRoom2: THREE.Object3D): boolean {
    if (SceneUtils.areRoomsSnapping(soRoom1, soRoom2)) {
      return false;
    }

    const box1 = RoomUtils.getRoomBoundingBox(soRoom1);
    const box2 = RoomUtils.getRoomBoundingBox(soRoom2);

    if (box1.intersectsBox(box2)) {
      soRoom1.userData.isOverlapped = true;
      soRoom2.userData.isOverlapped = true;
      soRoom1.userData.isTooCloseToOther = false;
      soRoom2.userData.isTooCloseToOther = false;

      return true;
    }

    const offset = 2 * UnitsUtils.getSyntheticWallHalfSize() + UnitsUtils.getStretchingAllowance();
    const bb1 = RoomUtils.getRoomBoundingBoxByModelLines(soRoom1);
    const bb2 = RoomUtils.getRoomBoundingBoxByModelLines(soRoom2);
    const min = bb1.min;
    const max = bb1.max;

    // Create four additional boxes for each side of the original bounding box
    const bbs = [
      new THREE.Box3(new THREE.Vector3(min.x - offset, min.y, 0), new THREE.Vector3(min.x, max.y, 0)), // left
      new THREE.Box3(new THREE.Vector3(max.x, min.y, 0), new THREE.Vector3(max.x + offset, max.y, 0)), // right
      new THREE.Box3(new THREE.Vector3(min.x, min.y - offset, 0), new THREE.Vector3(max.x, min.y, 0)), // bottom
      new THREE.Box3(new THREE.Vector3(min.x, max.y, 0), new THREE.Vector3(max.x, max.y + offset, 0)), // top
    ];

    if (bbs.some(bb => bb2.intersectsBox(bb))) {
      if (!soRoom1.userData.isOverlapped) {
        soRoom1.userData.isTooCloseToOther = true;
      }
      if (!soRoom2.userData.isOverlapped) {
        soRoom2.userData.isTooCloseToOther = true;
      }

      return true;
    }

    return false;
  }

  static areSoRoomsOverlapping(soRoom1: soRoom2D, soRoom2: soRoom2D): boolean {
    if (SceneUtils.areRoomsSnapping(soRoom1, soRoom2)) {
      return false;
    }

    const box1 = RoomUtils.getSoRoomBoundingBox(soRoom1);
    const box2 = RoomUtils.getSoRoomBoundingBox(soRoom2);

    if (box1.intersectsBox(box2)) {
      soRoom1.userData.isOverlapped = true;
      soRoom2.userData.isOverlapped = true;
      soRoom1.userData.isTooCloseToOther = false;
      soRoom2.userData.isTooCloseToOther = false;

      return true;
    }

    const offset = 2 * UnitsUtils.getSyntheticWallHalfSize() + UnitsUtils.getStretchingAllowance();
    const bb1 = RoomUtils.getRoomBoundingBoxByModelLines(soRoom1);
    const bb2 = RoomUtils.getRoomBoundingBoxByModelLines(soRoom2);
    const min = bb1.min;
    const max = bb1.max;

    // Create four additional boxes for each side of the original bounding box
    const bbs = [
      new THREE.Box3(new THREE.Vector3(min.x - offset, min.y, 0), new THREE.Vector3(min.x, max.y, 0)), // left
      new THREE.Box3(new THREE.Vector3(max.x, min.y, 0), new THREE.Vector3(max.x + offset, max.y, 0)), // right
      new THREE.Box3(new THREE.Vector3(min.x, min.y - offset, 0), new THREE.Vector3(max.x, min.y, 0)), // bottom
      new THREE.Box3(new THREE.Vector3(min.x, max.y, 0), new THREE.Vector3(max.x, max.y + offset, 0)), // top
    ];

    if (bbs.some(bb => bb2.intersectsBox(bb))) {
      if (!soRoom1.userData.isOverlapped) {
        soRoom1.userData.isTooCloseToOther = true;
      }
      if (!soRoom2.userData.isOverlapped) {
        soRoom2.userData.isTooCloseToOther = true;
      }

      return true;
    }

    return false;
  }
  static setObjectColor(object?: THREE.Object3D, color?: number): void {
    if (!object) {
      return;
    }
    object.traverse(child => {
      if (child instanceof THREE.Mesh || child instanceof THREE.Line) {
        if (color !== undefined) {
          if (child.userData.originalColor === undefined) {
            child.userData.originalColor = child.material.color.getHex();
          }

          child.material.color.setHex(color);
        } else if (child.userData.originalColor !== undefined) {
          child.material.color.setHex(child.userData.originalColor);
          delete child.userData.originalColor;
        }
      }
    });
  }

  static getLine3(line: THREE.Object3D): THREE.Line3 {
    const bb = GeometryUtils.getGeometryBoundingBox2D(line);
    return new THREE.Line3(bb.min, bb.max);
  }
  static getGeometryLine3(line: THREE.Object3D): THREE.Line3 {
    const bb = GeometryUtils.getGeometryBoundingBox2D(line).clone().applyMatrix4(line.matrixWorld);
    return new THREE.Line3(bb.min, bb.max);
  }
  /**
   * @deprecated  Use findClosestSoRoomFromRoomList instead
   */
  static findClosestRoomFromRoomList(mainRoom: THREE.Object3D, roomList: THREE.Object3D[]): THREE.Object3D | null {
    if (roomList.length === 0) {
      return null;
    }

    if (roomList.length === 1) {
      return roomList[0];
    }

    const bb = RoomUtils.getRoomBoundingBoxByModelLines(mainRoom);
    const center = bb.getCenter(new THREE.Vector3());

    let closest = null;
    let minDistance = null;

    roomList.forEach(soOther => {
      const bb2 = RoomUtils.getRoomBoundingBoxByModelLines(soOther);
      const center2 = bb2.getCenter(new THREE.Vector3());
      const distance = center.distanceTo(center2);

      if (!minDistance || distance < minDistance) {
        minDistance = distance;
        closest = soOther;
      }
    });

    return closest;
  }
  static findClosestSoRoomFromRoomList(mainRoom: soRoom2D, roomList: soRoom2D[]): soRoom2D | null {
    if (roomList.length === 0) {
      return null;
    }

    if (roomList.length === 1) {
      return roomList[0];
    }

    const bb = mainRoom.getSoRoomBoundingBoxByModelLines();
    const center = bb.getCenter(new THREE.Vector3());

    let closest = null;
    let minDistance = null;

    roomList.forEach(soOther => {
      const bb2 = soOther.getSoRoomBoundingBoxByModelLines();
      const center2 = bb2.getCenter(new THREE.Vector3());
      const distance = center.distanceTo(center2);

      if (!minDistance || distance < minDistance) {
        minDistance = distance;
        closest = soOther;
      }
    });

    return closest;
  }

  static isRoomReplaceable(mainRoom: THREE.Object3D, targetRoom: THREE.Object3D): boolean {
    const { min, max } = SceneUtils.getRoomMinMaxSizes(mainRoom);
    const targetSize = RoomUtils.getRoomBoundingBoxByModelLines(targetRoom).getSize(new THREE.Vector3());

    // Check if it is replaceable
    return MathUtils.isNumberInRange(targetSize.x, min.x, max.x) && MathUtils.isNumberInRange(targetSize.y, min.y, max.y);
  }

  static createBoxBorder2d(box: THREE.Box3, borderWidth: number, color: number): THREE.Mesh {
    const size = BoundingBoxUtils.getBoundingBoxSize(box);
    const external = size.clone().add(new THREE.Vector3(borderWidth, borderWidth)).multiplyScalar(0.5);
    const internal = size.clone().sub(new THREE.Vector3(borderWidth, borderWidth)).multiplyScalar(0.5);

    const shape = new THREE.Shape();
    shape.moveTo(-external.x, -external.y);
    shape.lineTo(external.x, -external.y);
    shape.lineTo(external.x, external.y);
    shape.lineTo(-external.x, external.y);
    shape.closePath();

    // Avoid adding hole when hole is collapsed.
    if (size.x > borderWidth && size.y > borderWidth) {
      const hole = new THREE.Path();
      hole.moveTo(-internal.x, -internal.y);
      hole.lineTo(internal.x, -internal.y);
      hole.lineTo(internal.x, internal.y);
      hole.lineTo(-internal.x, internal.y);
      hole.closePath();
      shape.holes.push(hole);
    }

    const result = new THREE.Mesh(new THREE.ShapeBufferGeometry(shape), new THREE.MeshBasicMaterial({ color, transparent: true, opacity: 1.0 }));
    result.position.copy(box.getCenter(new THREE.Vector3()));

    return result;
  }

  static createSegmentPlane(segment: Segment, size: number, color: number, renderOrder = STR_VALIDATION_WALL_SEGMENT_RENDER_ORDER): THREE.Mesh {
    const isHorizontal = segment.isHorizontal;
    const sizeX = isHorizontal ? segment.end.x - segment.start.x : size;
    const sizeY = !isHorizontal ? segment.end.y - segment.start.y : size;
    const positionX = isHorizontal ? segment.start.x + sizeX / 2 : segment.start.x;
    const positionY = isHorizontal ? segment.start.y : segment.start.y + sizeY / 2;
    const position = new THREE.Vector3(positionX, positionY);

    const result = new THREE.Mesh(new THREE.PlaneBufferGeometry(sizeX, sizeY), new THREE.MeshBasicMaterial({ color, transparent: true, opacity: 1.0 }));
    result.renderOrder = renderOrder;
    result.position.copy(position);

    return result;
  }
  static createWallPlane(wall: soWall2D, size: number, color: number, renderOrder = STR_VALIDATION_WALL_SEGMENT_RENDER_ORDER): THREE.Mesh {
    const isHorizontal = wall.isHorizontal;
    const sizeX = isHorizontal ? wall.end.x - wall.start.x : size;
    const sizeY = !isHorizontal ? wall.end.y - wall.start.y : size;
    const positionX = isHorizontal ? wall.start.x + sizeX / 2 : wall.start.x;
    const positionY = isHorizontal ? wall.start.y : wall.start.y + sizeY / 2;
    const position = new THREE.Vector3(positionX, positionY);

    const result = new THREE.Mesh(new THREE.PlaneBufferGeometry(sizeX, sizeY), new THREE.MeshBasicMaterial({ color, transparent: true, opacity: 1.0 }));
    result.renderOrder = renderOrder;
    result.position.copy(position);

    return result;
  }
  /**
   * Create contour with holes for ARC Area Validation
   */
  static createArcAreaValidationContour(soSpace: soSpace, color: string, renderOrder: number): THREE.Mesh {
    const shape = new THREE.Shape();
    const points = soSpace.getContourPointsByClockwiseDirection();
    GeometryUtils.addContourToPath(shape, points);

    const result = new THREE.Mesh(
      new THREE.ShapeBufferGeometry(shape),
      new THREE.MeshBasicMaterial({
        color: color,
        transparent: true,
        opacity: 0.4,
      })
    );
    result.renderOrder = renderOrder;

    return result;
  }

  static createArcAreaValidationLabel(name: string, area: string, bb: THREE.Box3): THREE.Group {
    const fontSize = 10;
    const subTitleFontSize = 8;
    const lineSpacing = 1.5;
    const color = settings.getColorNumber(WebAppUISettingsKeys.wallsColor);

    const result = SceneUtils.createTextLabels(name, area, fontSize, subTitleFontSize, lineSpacing, color, bb);
    GeometryUtils.setRenderOrder(result, ARC_AREA_VALIDATION_LABELS_RENDER_ORDER);

    return result;
  }

  static createSegmentMesh(segment: Segment | soWall2D, width: number, color: number, renderOrder: number): THREE.Group {
    const result = new THREE.Group();
    const length = segment.length();
    const angle = Math.atan2(segment.end.y - segment.start.y, segment.end.x - segment.start.x);

    const material = new THREE.MeshBasicMaterial({ color, transparent: true, opacity: 1.0 });
    const segmentMesh = new THREE.Mesh(new THREE.PlaneBufferGeometry(length, width), material);

    const startCircle = new THREE.Mesh(new THREE.CircleBufferGeometry(width / 2, 16, Math.PI / 2, Math.PI), material);
    startCircle.position.x = -length / 2;
    const endCircle = new THREE.Mesh(new THREE.CircleBufferGeometry(width / 2, 16, -Math.PI / 2, Math.PI), material);
    endCircle.position.x = length / 2;

    result.add(segmentMesh, startCircle, endCircle);

    result.rotation.z = angle;
    result.position.copy(segment.getCenter3());
    GeometryUtils.setRenderOrder(result, renderOrder);

    return result;
  }

  static createAngledSegmentPlane(segment: THREE.Line3, color: number, renderOrder: number): THREE.Mesh {
    const thickness = UnitsUtils.getSyntheticWallHalfSize();
    const angle = Math.atan2(segment.end.y - segment.start.y, segment.end.x - segment.start.x);
    const planeGeometry = new THREE.PlaneBufferGeometry(segment.end.distanceTo(segment.start), thickness);
    const planeMaterial = new THREE.MeshBasicMaterial({ color, transparent: true, opacity: 1.0 });
    const planeMesh = new THREE.Mesh(planeGeometry, planeMaterial);
    planeMesh.position.set((segment.start.x + segment.end.x) / 2, (segment.start.y + segment.end.y) / 2, 0);
    planeMesh.rotation.z = angle;
    planeMesh.renderOrder = renderOrder;
    return planeMesh;
  }

  static createSegmentDashedPlane(segment: Segment, color: number): THREE.Group {
    const dashSize = 2 * UnitsUtils.getConversionFactor();
    const gapSize = 1 * UnitsUtils.getConversionFactor();

    const wallHalfSize = UnitsUtils.getSyntheticWallHalfSize();

    const isHorizontal = segment.isHorizontal;
    const axis = isHorizontal ? "x" : "y";
    const planeSize = isHorizontal ? new THREE.Vector3(segment.length(), wallHalfSize) : new THREE.Vector3(wallHalfSize, segment.length());

    const result = new THREE.Group();

    let fillLevel = 0;
    while (fillLevel < planeSize[axis]) {
      const size = fillLevel + dashSize > planeSize[axis] ? planeSize[axis] - fillLevel : dashSize;
      const dash = new THREE.Mesh(
        isHorizontal ? new THREE.PlaneBufferGeometry(size, planeSize.y) : new THREE.PlaneBufferGeometry(planeSize.x, size),
        new THREE.MeshBasicMaterial({ color, transparent: true, opacity: 1.0 })
      );
      dash.renderOrder = STR_VALIDATION_WALL_SEGMENT_RENDER_ORDER;
      dash.position[axis] = fillLevel + size * 0.5;
      result.add(dash);

      fillLevel += size + gapSize;
    }

    result.renderOrder = STR_VALIDATION_WALL_SEGMENT_RENDER_ORDER;
    result.position.x = segment.start.x;
    result.position.y = segment.start.y;

    return result;
  }

  static createCladdingLine(segments: Segment[], offset: number, thickness: number) {
    const strokeSegments = segments.map(s => s.clone());
    SegmentsUtils.addOffsetToContour(strokeSegments, (offset > 0 ? -1 : 1) * thickness);

    const soCladding = new THREE.Group();
    segments.forEach((segment, i) => {
      const strokeSegment = strokeSegments[i];

      const material = new THREE.MeshBasicMaterial({ color: CLADDING_LINE_COLOR, transparent: true, opacity: 1.0 });
      const shape = new THREE.Shape();
      shape.moveTo(segment.start.x, segment.start.y);
      shape.lineTo(segment.end.x, segment.end.y);
      shape.lineTo(strokeSegment.end.x, strokeSegment.end.y);
      shape.lineTo(strokeSegment.start.x, strokeSegment.start.y);
      shape.lineTo(segment.start.x, segment.start.y);
      const mesh = new THREE.Mesh(new THREE.ShapeGeometry(shape), material);
      soCladding.add(mesh);
    });
    soCladding.userData.type = SceneEntityType.Cladding;
    soCladding.renderOrder = CLADDING_LINE_RENDER_ORDER;
    return soCladding;
  }
  /**
   * @deprecated  Use getSoCladdingSpacesWithOffset instead
   */
  static getCladdingSpacesWithOffset(roomManager: any, soFloor: THREE.Object3D): { space: Space; offset: number }[] {
    if (!soFloor) {
      return [];
    }
    const areaOffset = UnitsUtils.getAreaCalculationExteriorOffset();

    const boxes = FloorUtils.getFloorSoRooms(soFloor).map(so => RoomUtils.getRoomBoundingBoxByModelLines(so));
    const { externalSegments: segments } = WallAnalysisUtils.collectSegments(roomManager, FloorUtils.getFloorSoRooms(soFloor), MergeSegmentsMode.SameRoom);

    const graph = new GraphManager<Segment>();
    segments.forEach(s => {
      graph.createEdgeFromPoints(s.start, s.end, s);
    });

    const result: { space: Space; offset: number }[] = [];
    const spaces = SegmentsUtils.extractUnlinkedSpacesFromGraph(graph);
    spaces.forEach(space => {
      const pointInsideSpace = space.contour[0].getCenter3().add(
        VectorUtils.Vector2ToVector3(
          space.contour[0]
            .delta()
            .rotateAround(new THREE.Vector2(), Math.PI / 2)
            .normalize()
            .multiplyScalar(2 * EPSILON)
        )
      );
      const isExternalContour = boxes.some(bb => bb.containsPoint(pointInsideSpace));
      const offset = (isExternalContour ? 1 : -1) * areaOffset;

      SegmentsUtils.addOffsetToContour(space.contour, offset);
      result.push({ space, offset });
    });

    return result;
  }

  static getSoCladdingSpacesWithOffset(roomManager: any, soFloor: soFloor2D): { space: Space; offset: number }[] {
    return [{ space: undefined, offset: null }];
    if (!soFloor) {
      return [];
    }
    const areaOffset = UnitsUtils.getAreaCalculationExteriorOffset();

    const boxes = soFloor.soRooms.map(so => so.getSoRoomBoundingBoxByModelLines());
    const { externalSegments: segments } = soFloor.collectSegments();

    const graph = new GraphManager<Segment>();
    // segments.forEach(s => {
    //   graph.createEdgeFromPoints(s.start, s.end, s);
    // });

    const result: { space: Space; offset: number }[] = [];
    const spaces = SegmentsUtils.extractUnlinkedSpacesFromGraph(graph);
    spaces.forEach(space => {
      const pointInsideSpace = space.contour[0].getCenter3().add(
        VectorUtils.Vector2ToVector3(
          space.contour[0]
            .delta()
            .rotateAround(new THREE.Vector2(), Math.PI / 2)
            .normalize()
            .multiplyScalar(2 * EPSILON)
        )
      );
      const isExternalContour = boxes.some(bb => bb.containsPoint(pointInsideSpace));
      const offset = (isExternalContour ? 1 : -1) * areaOffset;

      SegmentsUtils.addOffsetToContour(space.contour, offset);
      result.push({ space, offset });
    });

    return result;
  }

  static getOpeningAndWallIntersection(wallLine: THREE.Line3, openingBox: THREE.Box3): THREE.Line3 | null {
    const axis = GeometryUtils.isLineHorizontal(wallLine) ? "x" : "y";
    const axis2 = GeometryUtils.isLineHorizontal(wallLine) ? "y" : "x";
    const [lineMin, lineMax] =
      wallLine.start[axis] > wallLine.end[axis] ? [wallLine.end[axis], wallLine.start[axis]] : [wallLine.start[axis], wallLine.end[axis]];

    if (
      wallLine.start[axis2] < openingBox.min[axis2] ||
      wallLine.start[axis2] > openingBox.max[axis2] ||
      lineMin > openingBox.max[axis] ||
      lineMax < openingBox.min[axis]
    ) {
      return null;
    }
    const intersectLine = wallLine.clone();
    intersectLine.start[axis] = openingBox.min[axis];
    intersectLine.end[axis] = openingBox.max[axis];
    return intersectLine;
  }

  static getOpeningProjectionOnWallLine(openingBb: THREE.Box3, wallLine: THREE.Line3): THREE.Line3 | null {
    const axis = GeometryUtils.isLineHorizontal(wallLine) ? "x" : "y";
    const line = wallLine.clone();
    if (line.start[axis] > line.end[axis]) {
      GeometryUtils.swapLineVectors(line);
    }
    if (line.start[axis] > openingBb.max[axis] || line.end[axis] < openingBb.min[axis]) {
      return null;
    }
    line.start[axis] = openingBb.min[axis];
    line.end[axis] = openingBb.max[axis];
    return line;
  }

  /**
   * Finds a wall segment that overlaps with the given opening zone and line.
   * @param {THREE.Object3D} soOpening - The 3D object representing the opening.
   * @param {THREE.Line3} openingZone - The zone around the opening to check for overlaps.
   * @param {THREE.Line3} openingLine - The line representing the opening.
   * @returns {Segment | null} - The overlapping wall segment, or null if none found.
   */
  static findOpeningWallSegment(roomManager: any, soOpening: THREE.Object3D, openingZone: THREE.Line3, openingLine: THREE.Line3): Segment | null {
    const soRoom = soOpening.parent; // Retrieve the room that contains the opening.
    const soStory = soRoom.parent; // Retrieve the story (floor) that contains the room.
    // Collect external and internal wall segments within the story.
    const { externalSegments, internalSegments } = WallAnalysisUtils.collectSegments(roomManager, soStory.children, MergeSegmentsMode.SameRoom, true);

    // Combine all segments into a single array for easy searching.
    const allSegments = [...externalSegments, ...internalSegments];

    // Find and return the first segment that overlaps with both the opening zone and line.
    return allSegments.find(segment => GeometryUtils.doLinesOverlap(openingZone, segment) && GeometryUtils.doLinesOverlap(openingLine, segment));
  }

  /**
   * Finds a wall segment that overlaps with the given opening zone and line.
   * @param {THREE.Object3D} soOpening - The 3D object representing the opening.
   * @param {THREE.Line3} openingZone - The zone around the opening to check for overlaps.
   * @param {THREE.Line3} openingLine - The line representing the opening.
   * @returns {Segment | null} - The overlapping wall segment, or null if none found.
   */
  static findOpeningSoWall(soOpening: THREE.Object3D, openingZone: THREE.Line3, openingLine: THREE.Line3, sceneManager: SceneManager): soWall2D | null {
    const soRoom = sceneManager.getCorePlanSoRoom(soOpening.parent.userData.id); // Retrieve the room that contains the opening.
    const soStory = sceneManager.getSoFloor(soRoom.ParentFloorId); // Retrieve the story (floor) that contains the room.

    // Collect external and internal wall segments within the story.

    // Combine all segments into a single array for easy searching.
    const allWalls = soStory.getWalls();

    // Find and return the first segment that overlaps with both the opening zone and line.

    return allWalls.find(
      wall =>
        wall.openings?.length &&
        wall.openings.find(op => op.uuid === soOpening.uuid) &&
        GeometryUtils.doLinesOverlap(openingZone, wall.toLine3()) &&
        GeometryUtils.doLinesOverlap(openingLine, wall.toLine3())
    );
  }

  /**
   * Limits the opening zone by the provided wall segment.
   * @param {THREE.Object3D} soOpening - The 3D object representing the opening.
   * @param {THREE.Line3} zone - The original zone around the opening.
   * @param {THREE.Line3} line - The original line representing the opening.
   * @param {Segment} segment - The wall segment to limit the opening zone by.
   * @param {boolean} limitToCoreThickness - Flag indicating whether to limit the opening zone based on core thickness or regular thickness.
   * @returns {THREE.Line3} - The adjusted opening zone.
   * @deprecated
   */
  static getLimitedOpeningZoneBySegment(
    roomManager: any,
    soOpening: THREE.Object3D,
    zone: THREE.Line3,
    line: THREE.Line3,
    segment: Segment,
    limitToCoreThickness: boolean
  ): THREE.Line3 {
    if (!segment) {
      return line.clone(); // Return the original line if no segment is provided.
    }

    const _zone = zone.clone(); // Clone the original zone for adjustment.
    const axis = GeometryUtils.isLineHorizontal(zone) ? "x" : "y"; // Determine if the zone is horizontal or vertical.

    const soStory = soOpening.parent?.parent; // Retrieve the story (floor) that contains the opening.
    if (!soStory) {
      return line.clone(); // Return the original line if no story is found.
    }

    // Retrieve the wall segments for the current floor.
    const segments = roomManager.getFloorSegments(soStory.userData.id);
    const { externalSegments, internalSegments } = WallAnalysisUtils.collectSegments(roomManager, soStory.children, MergeSegmentsMode.SameRoom, true);

    // Find segments that are perpendicular to the given segment.
    const perpendicularSegments = segment.getPerpendicularSegments([...externalSegments, ...internalSegments]);

    // Calculate the half-widths of the wall at the start and end of the segment.
    const startWallHalfSize = this.calculateWallHalfWidth(perpendicularSegments, segment.start, segments, soOpening, soStory, limitToCoreThickness);
    const endWallHalfSize = this.calculateWallHalfWidth(perpendicularSegments, segment.end, segments, soOpening, soStory, limitToCoreThickness);

    // Adjust the start and end of the segment based on the calculated wall half-widths.
    segment.start[axis] += Math.max(...startWallHalfSize);
    segment.end[axis] -= Math.max(...endWallHalfSize);

    // Adjust the zone to fit within the segment.
    return this.adjustZoneBySegment(_zone, segment, line, axis);
  }
  /**
   * Limits the opening zone by the provided wall segment.
   * @param {THREE.Object3D} soOpening - The 3D object representing the opening.
   * @param {THREE.Line3} zone - The original zone around the opening.
   * @param {THREE.Line3} line - The original line representing the opening.
   * @param {Segment} segment - The wall segment to limit the opening zone by.
   * @param {boolean} limitToCoreThickness - Flag indicating whether to limit the opening zone based on core thickness or regular thickness.
   * @returns {THREE.Line3} - The adjusted opening zone.
   *
   */
  static getLimitedOpeningZoneBySoWall(
    roomManager: any,
    soOpening: soOpening,
    zone: THREE.Line3,
    line: THREE.Line3,
    wall: soWall2D,
    limitToCoreThickness: boolean
  ): THREE.Line3 {
    if (!wall) {
      return line.clone(); // Return the original line if no segment is provided.
    }
    try {
      const _zone = zone.clone(); // Clone the original zone for adjustment.
      const axis = GeometryUtils.isLineHorizontal(zone) ? "x" : "y"; // Determine if the zone is horizontal or vertical.

      const soStory = roomManager.getSoFloor(roomManager.getCorePlanSoRoom(soOpening.parent.userData.id).parentFloorId) as soFloor2D; // Retrieve the story (floor) that contains the opening.
      if (!soStory) {
        return line.clone(); // Return the original line if no story is found.
      }
      const walls = soStory.getWalls();

      // Find segments that are perpendicular to the given segment.
      const perpendicularSegments = soStory.getWallsByIds(wall.getPerpendicularWallIds());

      // Calculate the half-widths of the wall at the start and end of the segment.
      const startWallHalfSize = this.calculateWallHalfWidth(perpendicularSegments, wall.start, walls, soOpening, soStory, limitToCoreThickness);
      const endWallHalfSize = this.calculateWallHalfWidth(perpendicularSegments, wall.end, walls, soOpening, soStory, limitToCoreThickness);

      // Adjust the start and end of the segment based on the calculated wall half-widths.
      wall.start[axis] += Math.max(...startWallHalfSize);
      wall.end[axis] -= Math.max(...endWallHalfSize);

      // Adjust the zone to fit within the segment.
      return this.adjustZoneBySegment(_zone, wall, line, axis);
    } catch (e) {
      console.log(e);
    }
  }
  /**
   * Calculates the half-width of a wall at a specified point, considering openings such as windows or doors.
   * The method finds perpendicular segments connected to the point and computes the wall width based on the floor segments
   * and the bounding box of any relevant opening within the specified story (floor).
   *
   * @param {Segment[]} segments - The array of perpendicular segments, used to determine wall connectivity at the given point.
   * @param {THREE.Vector2} point - The point where the wall width is calculated.
   * @param {any} floorSegments - The array of floor segments, used to check wall alignment and size.
   * @param {THREE.Object3D} soOpening - The opening (e.g., door, window) related to the calculation.
   * @param {THREE.Object3D} soStory - The story (floor) containing the opening and segments.
   * @param {boolean} useCoreThickness - Flag indicating whether to use the core thickness when calculating the wall half-width.
   * @returns {number[]} - An array of calculated wall half-widths at the specified point, including edge offsets for windows or doors.
   */
  private static calculateWallHalfWidth(
    segments: (Segment | soWall2D)[],
    point: THREE.Vector2,
    floorSegments: any,
    soOpening: THREE.Object3D,
    soStory: THREE.Object3D,
    useCoreThickness: boolean
  ): number[] {
    // Determine edge offset based on the type of opening (Window or Door)
    let edgeOffset = 0;
    if (soOpening.userData.type === RoomEntityType.Window) {
      edgeOffset = settings.values.validationSettings.windowEdgeOffset;
    } else if (soOpening.userData.type === RoomEntityType.Door) {
      edgeOffset = settings.values.validationSettings.doorEdgeOffset;
    }

    // Initialize array for wall half-widths, with a small default value to avoid zero-width walls
    const wallHalfSize: number[] = [0.0001 + edgeOffset];

    // Filter segments that are connected to the specified point
    const connectedSegments = segments.filter(seg => VectorUtils.areVectors2Equal(seg.start, point) || VectorUtils.areVectors2Equal(seg.end, point));

    // Get the bounding box of the opening (flattened along the z-axis)
    const openingBb = GeometryUtils.getGeometryBoundingBox3D(soOpening);
    openingBb.max.z = openingBb.min.z = 0;

    // Calculate and add the wall size for each connected segment, including the edge offset for valid segments
    for (const seg of connectedSegments) {
      const wallSize =
        seg instanceof soWall2D
          ? WallUtils.getWallWidthByFuncCode(seg.FunctionCode, useCoreThickness)
          : this.getWallWidthForSegment(seg, floorSegments, openingBb, soStory, useCoreThickness);
      if (wallSize !== null) {
        wallHalfSize.push(wallSize + edgeOffset);
      }
    }

    // Return the array of wall half-widths, including any valid sizes calculated for connected segments
    return wallHalfSize;
  }

  /**
   * Retrieves the wall width for a specific segment.
   * @param {Segment} segment - The segment to retrieve the wall width for.
   * @param {any} floorSegments - The floor segments to check against.
   * @param {THREE.Box3} openingBb - The bounding box of the opening.
   * @param {THREE.Object3D} soStory - The story (floor) containing the segment.
   * @param {boolean} useCoreThickness - Flag indicating whether to return the core thickness instead of interior/exterior thickness.
   * @returns {number | null} - The wall width, or null if not applicable.
   */
  private static getWallWidthForSegment(
    segment: Segment,
    floorSegments: any,
    openingBb: THREE.Box3,
    soStory: THREE.Object3D,
    useCoreThickness: boolean
  ): number | null {
    if (!segment.hasWall) {
      const internalSegments = [...floorSegments.grg, ...floorSegments.ddl, ...floorSegments.internal];
      if (internalSegments.find(s => SegmentsUtils.segmentsOverlap(segment, s))) {
        return null; // If the segment overlaps with internal segments but doesn't have a wall, return null.
      }
    }

    const wallThickness = this.getWallThickness(segment, floorSegments); // Get the wall thickness from predefined settings.
    if (wallThickness !== null) {
      return wallThickness; // Return the wall thickness if found.
    }

    const externalSegment = floorSegments.external.find(s => SegmentsUtils.segmentsOverlap(segment, s)); // Find overlapping external segments.
    if (!externalSegment) {
      return null; // Return null if no overlapping external segment is found.
    }

    if (useCoreThickness) {
      return catalogSettings.walls[FuncCode[segment.FunctionCode]].coreThickness; // Return core thickness if flag is true.
    }

    const soRoom = segment.roomId ? SceneUtils.getFloorRooms(soStory).find(soR => segment.roomId.includes(soR.userData.id)) : undefined;
    if (soRoom && this.isRoomContainingOpening(soRoom, openingBb)) {
      return catalogSettings.walls[FuncCode[segment.FunctionCode]].interiorThickness; // Return interior thickness if the room contains the opening.
    }

    return catalogSettings.walls[FuncCode[segment.FunctionCode]].exteriorThickness; // Otherwise, return exterior thickness.
  }

  /**
   * Retrieves the wall thickness based on the segment type.
   * @param {Segment} segment - The segment to check.
   * @param {any} floorSegments - The floor segments to check against.
   * @returns {number | null} - The wall thickness, or null if not applicable.
   */
  private static getWallThickness(segment: Segment, floorSegments: any): number | null {
    if (floorSegments.grg.find(s => SegmentsUtils.segmentsOverlap(segment, s))) {
      return catalogSettings.walls[segment.FunctionCode ?? FuncCode.INT_2X4_GARG].interiorThickness; // Return the GRG wall thickness if applicable.
    }
    // if (floorSegments.ddl.find(s => segment.overlapsSegment(s))) {
    //   return catalogSettings.walls[segment.FunctionCode].interiorThickness; // Return the DDL wall thickness if applicable.
    // }
    if (floorSegments.internal.find(s => SegmentsUtils.segmentsOverlap(segment, s))) {
      return catalogSettings.walls[segment.FunctionCode ?? FuncCode.INT_2X4].interiorThickness; // Return the internal wall thickness if applicable.
    }
    return null; // Return null if no specific wall thickness is found.
  }

  /**
   * Checks if a room contains a specific opening.
   * @param {THREE.Object3D} room - The room to check.
   * @param {THREE.Box3} openingBb - The bounding box of the opening.
   * @returns {boolean} - True if the room contains the opening, false otherwise.
   */
  private static isRoomContainingOpening(room: THREE.Object3D, openingBb: THREE.Box3): boolean {
    const roomBb = GeometryUtils.getGeometryBoundingBox3D(room); // Get the bounding box of the room.
    roomBb.max.z = roomBb.min.z = 0; // Flatten the bounding box in the z-axis.
    return roomBb.containsBox(openingBb); // Return true if the room bounding box contains the opening bounding box.
  }

  /**
   * Adjusts the opening zone to fit within the segment.
   * @param {THREE.Line3} _zone - The zone to adjust.
   * @param {Segment} segment - The segment to adjust the zone by.
   * @param {THREE.Line3} line - The original line representing the opening.
   * @param {"x" | "y"} axis - The axis of the zone and segment.
   * @returns {THREE.Line3} - The adjusted zone.
   */
  private static adjustZoneBySegment(_zone: THREE.Line3, segment: Segment | soWall2D, line: THREE.Line3, axis: "x" | "y"): THREE.Line3 {
    const isTopLeft = _zone.start[axis] < _zone.end[axis]; // Determine if the zone is oriented top-left to bottom-right.
    if (!isTopLeft) {
      GeometryUtils.swapLineVectors(_zone); // Swap the start and end vectors if not.
    }

    // Adjust the start and end of the zone to fit within the segment.
    if (_zone.start[axis] < segment.start[axis]) {
      _zone.start[axis] = segment.start[axis];
    }
    if (_zone.end[axis] > segment.end[axis]) {
      _zone.end[axis] = segment.end[axis];
    }

    // If the adjusted zone is smaller than the original line, return the original line.
    if (MathUtils.isNumberLessOrEqual(_zone.distance(), line.distance())) {
      return line.clone();
    }

    if (!isTopLeft) {
      GeometryUtils.swapLineVectors(_zone); // Swap the vectors back if needed.
    }

    return _zone; // Return the adjusted zone.
  }

  static collectRoomBoundingBoxes(floors: Floor[], firstFloorToFloorHeight: number, upperFloorToFloorHeight: number): Map<string, THREE.Box3> {
    const result = new Map<string, THREE.Box3>();

    // reads the room data!
    for (const floor of floors) {
      const boxMinZ = floor.index === 0 ? floor.index * firstFloorToFloorHeight : firstFloorToFloorHeight + (floor.index - 1) * upperFloorToFloorHeight;
      const boxMaxZ = floor.index === 0 ? firstFloorToFloorHeight : firstFloorToFloorHeight + floor.index * upperFloorToFloorHeight;

      for (const room of floor.rooms) {
        const halfW = room.width * 0.5;
        const halfH = room.height * 0.5;
        const bb = new THREE.Box3(new THREE.Vector3(room.x - halfW, room.y - halfH, boxMinZ), new THREE.Vector3(room.x + halfW, room.y + halfH, boxMaxZ));
        result.set(room.id, bb);
      }
    }

    return result;
  }
  static createCircle(position: THREE.Vector3, color: number, renderOrder: number): THREE.Object3D {
    const result = new THREE.Mesh(
      new THREE.CircleBufferGeometry(UnitsUtils.getSyntheticWallHalfSize() * 1.5, 32),
      new THREE.MeshBasicMaterial({ color, transparent: true, opacity: 1.0 })
    );
    result.userData.type = SceneEntityType.BasePoint;
    result.position.copy(position);
    result.renderOrder = renderOrder;
    return result;
  }

  static createPLMRing(position: THREE.Vector3, color: number, renderOrder: number): THREE.Object3D {
    const size = UnitsUtils.getSyntheticWallHalfSize();
    const result = new THREE.Mesh(new THREE.RingBufferGeometry(size, size * 1.5, 32), new THREE.MeshBasicMaterial({ color, transparent: true, opacity: 1.0 }));
    result.userData.type = SceneEntityType.BasePoint;
    result.position.copy(position);
    result.renderOrder = renderOrder;
    return result;
  }
  static createRing(coreDiameter: number, thickness: number, color: number): THREE.Object3D {
    return new THREE.Mesh(
      new THREE.RingBufferGeometry(coreDiameter, coreDiameter + thickness, 32),
      new THREE.MeshBasicMaterial({ color, transparent: true, opacity: 1.0 })
    );
  }

  static createDashedCircle(position: THREE.Vector3, radius: number, color: number): THREE.Object3D {
    const path = new THREE.Path().arc(0, 0, radius, 0, Math.PI * 2, true).getPoints(64);
    const circleGeometry = new THREE.BufferGeometry().setFromPoints(path);
    const dashMaterial = new THREE.LineDashedMaterial({ color: color, transparent: true, opacity: 1.0, dashSize: 2, gapSize: 2 });
    const circle = new THREE.Line(circleGeometry, dashMaterial);
    circle.computeLineDistances();
    circle.position.copy(position);
    return circle;
  }

  static createRoomBboxPlane(bbox: THREE.Box3, color: number, opacity: number): THREE.Object3D {
    const size = new THREE.Vector3();
    bbox.getSize(size);

    const planeGeometry = new THREE.PlaneBufferGeometry(size.x, size.y);
    const planeMaterial = new THREE.MeshBasicMaterial({ color, opacity, transparent: true });
    const planeMesh = new THREE.Mesh(planeGeometry, planeMaterial);
    const center = bbox.getCenter(new THREE.Vector3());
    planeMesh.position.copy(center);

    return planeMesh;
  }

  static areRoomsIntersectingHorizontally(soRoom1: THREE.Object3D, soRoom2: THREE.Object3D): boolean {
    const bb1 = RoomUtils.getRoomBoundingBox(soRoom1);
    bb1.min.z = bb1.max.z = 0;

    const bb2 = RoomUtils.getRoomBoundingBox(soRoom2);
    bb2.min.z = bb2.max.z = 0;

    return bb1.intersectsBox(bb2);
  }

  static getNearestArchitecturalScale(architecturalScale: number): { value: number; name: string } {
    const architecturalScales = [
      { value: 12, name: "1'=1'-0\"" },
      { value: 6, name: '6"=1\'-0"' },
      { value: 3, name: '3"=1\'-0"' },
      { value: 1 + 1 / 2, name: '1 1/2"=1\'-0"' },
      { value: 1, name: '1"=1\'-0"' },
      { value: 1 / 2, name: '1/2"=1\'-0"' },
      { value: 3 / 8, name: '3/8"=1\'-0"' },
      { value: 3 / 4, name: '3/4"=1\'-0"' },
      { value: 1 / 4, name: '1/4"=1\'-0"' },
      { value: 3 / 16, name: '3/16"=1\'-0"' },
      { value: 1 / 8, name: '1/8"=1\'-0"' },
      { value: 3 / 32, name: '3/32"=1\'-0"' },
      { value: 1 / 16, name: '1/16"=1\'-0"' },
      { value: 1 / 32, name: '1/32"=1\'-0"' },
      { value: 1 / 64, name: '1/64"=1\'-0"' },
      { value: 1 / 128, name: '1/128"=1\'-0"' },
      { value: 1 / 256, name: '1/256"=1\'-0"' },
    ];

    let v = 0;
    let name = "";

    const scale = architecturalScales.find(s => s.value == architecturalScale);
    if (scale) {
      return scale;
    }

    architecturalScales.forEach(item => {
      if (item.value > v && item.value < architecturalScale) {
        v = item.value;
        name = item.name;
      }
    });

    // TODO: handle cases whan scale is beyound the predefined list
    if (v == 0) {
      v = architecturalScales[architecturalScales.length - 1].value;
      name = architecturalScales[architecturalScales.length - 1].name;
    }

    return { value: v, name: name };
  }
  // Todo - delete createModelLine
  // private static createModelLine(roomEntity: RoomEntity): THREE.Line {
  //   if (roomEntity.type == RoomEntityType.ModelLine) {
  //     const property = roomEntity.properties[0];
  //     if (property.name === RoomEntityProperties.Points) {
  //       const points = property.value.map((p: Vector3V) => VectorUtils.Vector3VToVector3(p).clone());

  //       const result = new THREE.Line(
  //         new THREE.BufferGeometry().setFromPoints(points),
  //         new THREE.LineDashedMaterial({
  //           color: MODEL_LINE_COLOR,
  //           dashSize: 0.3 * UnitsUtils.getConversionFactor(),
  //           gapSize: 0.3 * UnitsUtils.getConversionFactor(),
  //           transparent: true,
  //           opacity: 1.0,
  //         })
  //       );
  //       result.renderOrder = MODEL_LINE_RENDER_ORDER;
  //       result.computeLineDistances();

  //       return result;
  //     }
  //   }

  //   return null;
  // }

  private static createLineWithOffset(roomEntity: RoomEntity): THREE.Line {
    const property = roomEntity.properties[0];
    const lineType = RoomEntityType.RoomBoundaryLines ? RoomEntityType.ModelLine : RoomEntityType.RoomBoundaryLines;
    if (property.name === RoomEntityProperties.Points) {
      const points = property.value.map((p: Vector3V) => VectorUtils.Vector3VToVector3(p).clone());
      const centroid = new THREE.Vector3(0, 0, 0);
      points.forEach(point => centroid.add(point));
      centroid.divideScalar(points.length);

      const offsetDistance = UnitsUtils.convertInchesToUnits(2);
      const offsetPoints = points.map(point => {
        if (roomEntity.type == RoomEntityType.RoomBoundaryLines) {
          const direction = new THREE.Vector3().subVectors(point, centroid).normalize();
          return point.clone().sub(direction.multiplyScalar(offsetDistance));
        } else {
          const direction = new THREE.Vector3().subVectors(centroid, point).normalize();
          return point.clone().add(direction.multiplyScalar(offsetDistance));
        }
      });

      const result = new THREE.Line(
        new THREE.BufferGeometry().setFromPoints(offsetPoints),
        new THREE.LineDashedMaterial({
          color: MODEL_LINE_COLOR,
          dashSize: 0.3 * UnitsUtils.getConversionFactor(),
          gapSize: 0.3 * UnitsUtils.getConversionFactor(),
          transparent: true,
          opacity: 1.0,
        })
      );
      result.renderOrder = MODEL_LINE_RENDER_ORDER;
      result.computeLineDistances();

      result.name = lineType;
      result.userData = {
        id: roomEntity.id,
        type: lineType,
      };

      return result;
    }
  }

  private static createLines(roomEntity: RoomEntity): THREE.Line {
    const property = roomEntity.properties[0];
    const lineType = roomEntity.type;
    if (property.name === RoomEntityProperties.Points) {
      const points = property.value.map((p: Vector3V) => VectorUtils.Vector3VToVector3(p).clone());

      const result = new THREE.Line(
        new THREE.BufferGeometry().setFromPoints(points),
        lineType == RoomEntityType.ModelLine
          ? new THREE.LineDashedMaterial({
              color: MODEL_LINE_COLOR,
              dashSize: 0.3 * UnitsUtils.getConversionFactor(),
              gapSize: 0.3 * UnitsUtils.getConversionFactor(),
              transparent: true,
              opacity: 1.0,
            })
          : new THREE.LineBasicMaterial({
              color: MODEL_LINE_COLOR,
            })
      );
      result.renderOrder = MODEL_LINE_RENDER_ORDER;
      result.computeLineDistances();
      result.name = lineType;
      result.userData = {
        id: roomEntity.id,
        type: lineType,
      };
      return result;
    }
  }

  private static createDataBoxLines(dataBox: any, roomEntity: RoomEntity): soDataBox {
    const dataBoxLines: soDataBoxLine[] = [];

    Object.keys(dataBox).forEach(type => {
      if (type.startsWith("DataBoxLine")) {
        const lineData = dataBox[type];

        const startPoint = new THREE.Vector3(lineData.Line.Start.X, lineData.Line.Start.Y, 0);
        const endPoint = new THREE.Vector3(lineData.Line.End.X, lineData.Line.End.Y, 0);

        const line = new THREE.Line(new THREE.BufferGeometry().setFromPoints([startPoint, endPoint]));

        const dataBoxLine = new soDataBoxLine(
          type, // Using the type as the unique ID for the line
          startPoint,
          endPoint,
          line,
          lineData.IsWetWall,
          lineData.IsPlumbingWall,
          lineData.Clearance,
          lineData.type
        );

        dataBoxLines.push(dataBoxLine);
      }
    });

    return new soDataBox(roomEntity.id, dataBoxLines);
  }

  private static createRoomLinesFromBoundingBox(boundingBox: THREE.Box3, type: string = ""): THREE.Line {
    const points = [
      new THREE.Vector3(boundingBox.min.x, boundingBox.min.y, boundingBox.min.z),
      new THREE.Vector3(boundingBox.max.x, boundingBox.min.y, boundingBox.min.z),
      new THREE.Vector3(boundingBox.max.x, boundingBox.max.y, boundingBox.min.z),
      new THREE.Vector3(boundingBox.min.x, boundingBox.max.y, boundingBox.min.z),
    ];

    return;
  }
  private static createReferenceLine(roomEntity: RoomEntity): THREE.Points[] {
    if (roomEntity.type == RoomEntityType.ReferenceLine) {
      const material = new THREE.PointsMaterial({
        color: new THREE.Color(STRETCH_POINT_COLOR),
        size: UnitsUtils.getConversionFactor(),
        map: new THREE.TextureLoader().load(STRETCH_POINT_TEXTURE_URL),
        transparent: true,
        opacity: 1.0,
      });

      const max = roomEntity.properties?.find(property => property.name === RoomEntityProperties.Max).value;

      return roomEntity.properties
        ?.find(property => property.name === RoomEntityProperties.Points)
        .value.map((p: Vector3V) => {
          const point = VectorUtils.Vector3VToVector3(p);

          const geometry = new THREE.BufferGeometry().setFromPoints([point, point.clone()]);
          geometry.computeBoundingBox();

          const twins = new THREE.Points(geometry, material);
          twins.userData.id = roomEntity.id;
          twins.userData.type = roomEntity.type;
          twins.userData.max = max;
          twins.visible = false;
          twins.renderOrder = REFERENCE_LINE_RENDER_ORDER;

          return twins;
        });
    }

    return null;
  }

  private static setLineColor(child: THREE.Object3D, color: number) {
    if (child instanceof THREE.Line) {
      if (child.userData.originalColor === undefined) {
        child.userData.originalColor = child.material.color.getHex();
      }

      child.material.color.setHex(color);
    }
  }

  private static traverseWithNodeAbort(object: THREE.Object3D, callback: (object: THREE.Object3D) => boolean) {
    let aborted = callback(object);

    if (!aborted) {
      const children = object.children;
      for (let i = 0, l = children.length; i < l; i++) {
        aborted = SceneUtils.traverseWithNodeAbort(children[i], callback);
      }
    }
    return aborted;
  }

  private static getCharacterWidth(character: string, fontSize: number): number {
    const data: ThreeFontData = SceneUtils.Font.data as any;
    const scale = fontSize / data.resolution;

    return (data.glyphs[character].ha || data.glyphs["?"].ha) * scale;
  }

  private static getTextWidth(text: string, fontSize: number): number {
    return Array.from(text).reduce((width, character) => width + SceneUtils.getCharacterWidth(character, fontSize), 0);
  }

  private static wordWrap(text: string, lineWidth: number, fontSize: number): string[] {
    const result: string[] = [];
    const words = text.split(" ");
    let currentLine = "";

    const appendLine = () => {
      if (currentLine.length > 0) {
        result.push(currentLine);
        currentLine = "";
      }
    };

    for (const word of words) {
      let wordChunk = word;

      // Include whitespace.
      const currentLineLength = currentLine.length ? SceneUtils.getTextWidth(currentLine + " ", fontSize) : 0;
      if (currentLineLength + SceneUtils.getTextWidth(wordChunk, fontSize) > lineWidth) {
        appendLine();

        while (SceneUtils.getTextWidth(wordChunk, fontSize) > lineWidth) {
          let idx = -1;
          let stringWidth = 0;
          while (stringWidth < lineWidth) {
            idx++;
            stringWidth += SceneUtils.getCharacterWidth(wordChunk.charAt(idx), fontSize);
          }

          currentLine = wordChunk.substring(0, idx);
          wordChunk = wordChunk.substring(idx);
          appendLine();
        }
      }

      currentLine += (currentLine.length ? " " : "") + wordChunk;
    }

    appendLine();

    return result;
  }
  public static getSceneManager(obj: THREE.Object3D): SceneManager {
    obj.traverseAncestors(ancestor => {
      console.log("anc", ancestor);
      if (ancestor instanceof SceneManager) return ancestor;
    });
    return null;
  }
}
