import { IReactionDisposer, reaction } from "mobx";
import * as THREE from "three";

import { appModel } from "../../../models/AppModel";
import { CursorStyle } from "../../../models/CursorStyle";
import { CorePlan } from "../../../models/CorePlan";
import { DragMode } from "../../models/DragMode";
import { Keys } from "../../models/Keys";
import { SceneEditorMode } from "../../models/SceneEditorMode";
import { GraphAnalysisUtils } from "../../utils/GraphAnalysisUtils";
import { SceneEntityType } from "../../models/SceneEntityType";

import SceneManager from "./SceneManager";
import { HOVERD_WALL, SELECTED_WALL, SELECTED_WALL_RENDER_ORDER, WALL_RENDER_ORDER } from "../../consts";
import { settings } from "../../../entities/settings";
import { WebAppUISettingsKeys } from "../../../entities/settings/types";
import GeometryUtils from "../../utils/GeometryUtils/GeometryUtils";
import { catalogSettings } from "../../../entities/catalogSettings";
import log from "loglevel";
import { IManager } from "../IManager";
import { soWall2D } from "../../models/SceneObjects/Wall/soWall2D";
import { soRoom2D } from "../../models/SceneObjects/Room/soRoom2D";
import { Direction } from "../../models/Direction";
import WallUtils from "../../utils/WallUtils";

export default class RoomWallManager implements IManager {
  private reactions: IReactionDisposer[] = [];
  private dragMode: DragMode = DragMode.none;

  constructor(public sceneManager: SceneManager) {
    reaction(() => appModel.activeCorePlan, this.onActiveCorePlanChanged.bind(this), { fireImmediately: true });
    reaction(() => appModel.sceneEditorMode, this.onEditModeChanged.bind(this));
  }

  public onMouseMove(e: MouseEvent) {
    const intersectedWall = this.getIntersectedWall();
    const intersectedOpening = this.sceneManager.roomOpeningManager.getIntersectedOpening();
    this.resetWallSelection();

    if (intersectedWall && intersectedWall.HasGeometry && !intersectedOpening) {
      (e.target as HTMLCanvasElement).style.cursor = CursorStyle.Pointer;
      this.handleHoverWall(intersectedWall);
    } else {
      (e.target as HTMLCanvasElement).style.cursor = CursorStyle.Default;
    }
    Array.from(appModel.selectedRoomSoWall).forEach(wall => {
      this.paintSelectedWall(wall, true);
    });
    if (appModel.selectedRoomSoWall.size > 0) {
      appModel.setTooltipOptions({ show: false });
    }
  }

  public onMouseLeave(e: MouseEvent): void {
    appModel.setSceneEditorMode(SceneEditorMode.Room);
  }

  public onMouseUp(e: MouseEvent) {
    appModel.clearSelectedRoomsIds();
    const intersectedOpening = this.sceneManager.roomOpeningManager.getIntersectedOpening();

    if (appModel.isViewOnlyMode || !this.sceneManager.baseManager.isMouseHandlersEnabled) {
      return;
    }
    /////////////////////////////////////////////////////
    // no ctrl
    //     if wall-selected single and wall was clicked => deselect
    //     else reset and select wall clicked
    // ctrl
    //     if wall-selected and wall was clicked => deselect
    //     else => select wall clicked
    /////////////////////////////////////////////////////
    if (this.dragMode === DragMode.none) {
      if (e.button === 0) {
        const intersectedWall = this.getIntersectedWall();

        // CTRL key is not pressed
        if (!e.ctrlKey || !appModel.featureFlags["wallAlignment"]) {
          const selectedWall = appModel.selectedRoomSoWall.values().next().value;
          if (appModel.selectedRoomSoWall.size == 1 && intersectedWall && selectedWall && intersectedWall.wallId === selectedWall.wallId) {
            this.resetWallSelection();
            appModel.clearSelectedRoomWalls();
            appModel.setSceneEditorMode(SceneEditorMode.Room);
            return;
          }
          appModel.clearSelectedRoomOpenings();
          // remove color from all walls
          this.resetWallSelection();
          this.clearSelection();
          if (intersectedWall && intersectedWall.HasGeometry && !intersectedOpening) {
            // replace single selected wall with the new one
            this.addSoWallToSelected(intersectedWall);
          } else {
            appModel.setSceneEditorMode(SceneEditorMode.Room);
          }
        } else {
          // CTRL key is pressed
          // check if the clicked wall is already selected
          if (appModel.selectedRoomSoWall.has(intersectedWall)) {
            appModel.removeSelectedRoomWall(intersectedWall);
            this.areSelectedRoomWallsEligible();
            this.resetColorSelectedWall(intersectedWall);
            if (appModel.selectedRoomSoWall.size === 0) {
              appModel.setSceneEditorMode(SceneEditorMode.Room);
            }
          } else if (intersectedWall.HasGeometry) {
            this.addSoWallToSelected(intersectedWall);
          }
        }
      }
    }
  }

  public clearSelection() {
    appModel.clearSelectedRoomWalls();
  }

  public init() {
    this.clearSelection();
    this.resetWallSelection();
  }

  public getIntersectedWall(): soWall2D | null {
    const activeFloorSoWalls = this.sceneManager.getActiveSoFloor()?.getWalls();
    if (!activeFloorSoWalls) return null;
    const intersections = this.sceneManager.raycaster.intersectObjects(
      activeFloorSoWalls.map(wall => wall as THREE.Object3D),
      true
    );

    return intersections.length ? (intersections[0].object.parent as soWall2D) : null;
  }

  public onKeyUp(e: KeyboardEvent) {
    if (appModel.isViewOnlyMode) {
      return;
    }

    switch (e.code) {
      case Keys.Esc: {
        this.clearSelection();
        this.resetWallSelection();
        appModel.setSceneEditorMode(SceneEditorMode.Room);
        break;
      }
      case Keys.Y: {
        this.redo();
        break;
      }
      case Keys.Z: {
        this.undo();
        break;
      }
    }
  }
  //Need to add this.sceneManager.commandManager.add for spesific command
  public undo(): void {
    if (this.dragMode === DragMode.none) {
      const command = this.sceneManager.commandManager.undo();

      if (command) {
        appModel.clearSelectedRoomWalls();
        appModel.setSceneEditorMode(SceneEditorMode.Room);
      }
    }
  }

  //Need to add this.sceneManager.commandManager.add for spesific command
  public redo(): void {
    if (this.dragMode === DragMode.none) {
      const command = this.sceneManager.commandManager.redo();
      if (command) {
        appModel.clearSelectedRoomWalls();
        appModel.setSceneEditorMode(SceneEditorMode.Room);
      }
    }
  }

  public addSoWallToSelected(soWall: soWall2D): void {
    if (!soWall) return;
    this.paintSelectedWall(soWall, false);
    appModel.addSelectedRoomWall(soWall);
    this.areSelectedRoomWallsEligible();
  }

  public handleHoverWall(soWall: soWall2D): void {
    if (!soWall) return;
    const soWalls = this.getActiveFloorSoWalls();

    soWalls.forEach(sWall => {
      if (soWall.wallId === sWall.wallId) {
        sWall.children.forEach(child => {
          const wallMesh = child as THREE.Mesh;
          if (wallMesh.material instanceof THREE.MeshStandardMaterial || wallMesh.material instanceof THREE.MeshBasicMaterial) {
            wallMesh.material.color.set(HOVERD_WALL);
            wallMesh.renderOrder = SELECTED_WALL_RENDER_ORDER;
          }
        });
      }
    });
    appModel.setTooltipOptions({ show: false });
  }

  public getActiveFloorWall(wall: any): THREE.Object3D {
    return this.getActiveFloorSoWalls().find(soWall => {
      const soWallbb = new THREE.Box3().setFromObject(soWall);
      const wallbb = new THREE.Box3().setFromObject(wall);
      soWallbb.intersectsBox(wallbb);
    });
  }

  public async onActiveCorePlanChanged(corePlan?: CorePlan): Promise<void> {
    this.unsubscribe(this.reactions);

    if (corePlan) {
      this.subscribeActiveCorePlan();
    }
  }

  private onActiveFloorChanged(): void {
    appModel.clearSelectedRoomOpenings();
  }

  private onEditModeChanged(mode: SceneEditorMode, oldMode: SceneEditorMode): void {
    if (oldMode === SceneEditorMode.RoomOpening) {
      appModel.clearSelectedRoomOpenings();
    }
  }

  private subscribeActiveCorePlan(): void {
    this.reactions.push(reaction(() => appModel.activeFloor, this.onActiveFloorChanged.bind(this)));
  }

  private unsubscribe(reactions: IReactionDisposer[]): void {
    reactions.forEach(r => r());
    reactions.length = 0;
  }

  private getActiveFloorSoWalls(): soWall2D[] {
    return this.sceneManager.getActiveSoFloor().getWalls();
  }

  private extractSynteticSoWalls(soRoom: soRoom2D): THREE.Object3D[] {
    return this.sceneManager.getActiveSoFloor().getWallsByIds(soRoom.wallsIds);
  }

  public paintSelectedWall(soWall: soWall2D, inMove?: boolean): void {
    if (!soWall) return;
    const soWalls = this.getActiveFloorSoWalls();
    soWalls.forEach(sWall => {
      const soWallStart = soWall.start;
      const sWallStart = sWall.start;
      if (soWall.wallId === sWall.wallId) {
        // if (!inMove) {
        //   appModel.addSelectedRoomSegment(sWall);
        // }
        sWall.children.forEach(child => {
          const wallMesh = child as THREE.Mesh;
          if (wallMesh.material instanceof THREE.MeshStandardMaterial || wallMesh.material instanceof THREE.MeshBasicMaterial) {
            wallMesh.material.color.set(SELECTED_WALL);
            wallMesh.renderOrder = SELECTED_WALL_RENDER_ORDER;
          }
        });
      }
    });
  }

  private resetColorSelectedWall(wall: soWall2D): void {
    wall.children.forEach(child => {
      const childMesh = child as THREE.Mesh;
      (childMesh.material as THREE.MeshBasicMaterial).color.set(settings.getColorNumber(WebAppUISettingsKeys.wallsColor));
      childMesh.renderOrder = WALL_RENDER_ORDER;
    });
  }

  // Function to check if any segments in the array intersect
  private checkIntersections(wallSegments1: any[], wallSegments2: any[]): number {
    for (let i = 0; i < wallSegments1.length; i++) {
      for (let j = 0; j < wallSegments2.length; j++) {
        const wallBb1 = GeometryUtils.getGeometryBoundingBox2D(wallSegments1[i]);
        const l1 = GeometryUtils.getBoundingBoxCenterLine(wallBb1);
        const wallBb2 = GeometryUtils.getGeometryBoundingBox2D(wallSegments2[j]);
        if (GeometryUtils.lineIntersectsBoundingBox(l1, wallBb2)) {
          return 1;
        }
      }
    }
    return 0;
  }

  /**
   * Checks if all selected walls in the multi-select set on the same axis and intersects each other.
   * If the size of multi-select is 1 => true.
   * Otherwise, it iterates through the walls segements to check all segements intersections (2 - both sides) and
   * checks that only 2 edges on both side have only 1 intersect.
   *
   * @private
   * @returns {void}
   */
  private areSelectedRoomWallsEligible(): void {
    if (!appModel.featureFlags["wallAlignment"]) {
      return;
    }
    if (appModel.selectedRoomSoWall.size == 1) {
      appModel.isSelectedRoomWallsEligible = true;
      return;
    }
    const selectedWalls = Array.from(appModel.selectedRoomSoWall);
    const selectedWallsMap: Map<string, soWall2D> = new Map();
    selectedWalls.forEach(wall => {
      selectedWallsMap.set(wall.wallId, wall);
    });
    const direction = selectedWalls[0].wallDirection;
    const sameDirection = selectedWalls.every(wall => wall.wallDirection === direction);
    if (!sameDirection) {
      appModel.isSelectedRoomWallsEligible = false;
      return;
    }
    const connectedWalls = selectedWalls.flatMap(wall => WallUtils.getConnectedColinearWallIds(wall));
    const notConnectedTest = selectedWalls.some(wall => !connectedWalls.includes(wall.wallId));
    if (notConnectedTest) {
      appModel.isSelectedRoomWallsEligible = false;
      return;
    }

    appModel.isSelectedRoomWallsEligible = appModel.isSelectedRoomWallsEligible && !this.areWallsHaveSameThickness();
  }

  /**
   * Checks if all selected walls in the multi-select have same thickness
   * Pre-condition: multi-select is eligible.
   * If the size of multi-select is 1 => true.
   *
   * @private
   * @returns {boolean}
   */
  private areWallsHaveSameThickness(): boolean {
    if (!appModel.featureFlags["wallAlignment"]) {
      return true;
    }
    const selectedWalls = Array.from(appModel.selectedRoomSoWall) as soWall2D[];
    const firstObject = selectedWalls[0];

    const initialThickness = firstObject.GetWallTotalWidth(true);
    let wallsHaveSameThicknessOrAligned = true;

    selectedWalls.forEach(item => {
      if (item.GetWallTotalWidth(true) !== initialThickness) {
        wallsHaveSameThicknessOrAligned = false;
        return;
      }
    });

    return wallsHaveSameThicknessOrAligned;
  }

  private resetWallSelection(): void {
    const soFloorWalls = this.sceneManager.getActiveSoFloor()?.getWalls();
    if (soFloorWalls) soFloorWalls.forEach(wall => this.resetColorSelectedWall(wall));
  }
}
