import * as THREE from "three";

import SceneUtils from "./SceneUtils";
import GeometryUtils from "./GeometryUtils/GeometryUtils";
import LineUtils from "./GeometryUtils/LineUtils";
import SnapEvent from "../models/SnapEvent";
import SceneManager from "../managers/SceneManager/SceneManager";

import { RoomEntityType } from "../../models/RoomEntityType";
import { SceneEntityType } from "../models/SceneEntityType";
import { Side } from "../../models/Side";
import { FuncCode, WallType } from "../../entities/catalogSettings/types";
import { catalogSettings } from "../../entities/catalogSettings";
import { appModel } from "../../models/AppModel";
import { EPSILON } from "../consts";
import { soRoom2D } from "../models/SceneObjects/Room/soRoom2D";
import { soFloor2D } from "../models/SceneObjects/Floor/soFloor2D";
import { soFloor2DRoot } from "../models/SceneObjects/Floor/soFloor2DRoot";
import { soOpening } from "../models/SceneObjects/Openings/soOpening";
import VectorUtils from "./GeometryUtils/VectorUtils";
import { soBoundaryLine } from "../models/SceneObjects/RoomBoundary/soBoundaryLine";
import { soWall2D } from "../models/SceneObjects/Wall/soWall2D";
import BoundingBoxUtils from "./GeometryUtils/BoundingBoxUtils";

/**
 * Utility class for handling operations related to rooms in a 3D environment.
 */
export default class RoomUtils {
  /**
   * Calculates the bounding box of a given room object.
   * The bounding box is determined by expanding to include all child objects
   * that are of type `RoomEntityType.Wall` or `SceneEntityType.SyntheticWall`.
   * Additionally, it unions with the bounding box derived from the room's model lines.
   * The resulting bounding box has its `z` dimension set to 0.
   *
   * @param room - The room object for which the bounding box is to be calculated.
   * @returns The bounding box of the room as a `THREE.Box3` object.
   */
  static getSoRoomBoundingBox(room: soRoom2D): THREE.Box3 {
    const bb = new THREE.Box3();

    room.children.forEach(child => {
      if (child.userData.type === RoomEntityType.Wall || child.userData.type === SceneEntityType.SyntheticWall) {
        bb.expandByObject(child);
      }
    });
    bb.union(room.getSoRoomBoundingBoxByModelLines());

    bb.min.z = bb.max.z = 0;
    return bb;
  }

  /**
   * Calculates the bounding box of a given room object.
   * The bounding box is determined by expanding to include all child objects
   * that are of type `RoomEntityType.Wall` or `SceneEntityType.SyntheticWall`.
   * Additionally, it unions with the bounding box derived from the room's model lines.
   * The resulting bounding box has its `z` dimension set to 0.
   *
   * @param room - The room object for which the bounding box is to be calculated.
   * @returns The bounding box of the room as a `THREE.Box3` object.
   * @deprecated Use `getSoRoomBoundingBox` instead.
   */
  static getRoomBoundingBox(room: THREE.Object3D): THREE.Box3 {
    const bb = new THREE.Box3();

    room.children.forEach(child => {
      if (child.userData.type === RoomEntityType.Wall || child.userData.type === SceneEntityType.SyntheticWall) {
        bb.expandByObject(child);
      }
    });

    bb.union(RoomUtils.getRoomBoundingBoxByModelLines(room));

    bb.min.z = bb.max.z = 0;
    return bb;
  }

  /**
   * Calculates the bounding box of a room based on its model lines.
   * The bounding box is created using the start points of the left, right, bottom, and top lines.
   * The `z` dimension of the bounding box is set to 0.
   *
   * @param soRoom - The room object for which the bounding box by model lines is to be calculated.
   * @returns The bounding box of the room based on its model lines as a `THREE.Box3` object.
   * @deprecated Use `getSoRoomBoundingBoxByModelLines` instead.
   */
  static getRoomBoundingBoxByModelLines(soRoom: THREE.Object3D): THREE.Box3 {
    if (soRoom instanceof soRoom2D) {
      return soRoom.boundingBoxByModelLine;
    }
    const lines = RoomUtils.getRoomLinesByType(soRoom, RoomEntityType.ModelLine);
    return new THREE.Box3(new THREE.Vector3(lines.left.start.x, lines.bottom.start.y, 0), new THREE.Vector3(lines.right.start.x, lines.top.start.y, 0));
  }

  static getRoomNetBoundingBoxOld(roomManager: any, soRoom: THREE.Object3D): THREE.Box3 {
    const soFloor = soRoom.parent;
    const segments = roomManager.getFloorSegments(soFloor.userData.id);
    const roomType = appModel.getRoomType(soRoom.userData.roomTypeId);
    const roomCategory = appModel.getRoomCategory(roomType.roomCategoryId);
    return SceneUtils.getRoomBoundingBoxByWallType(soRoom, roomCategory.isGarage, true, segments);
  }
  /**
   * @deprecated Use `getSoRoomNetBoundingBox` instead.
   */
  static getRoomNetBoundingBox(roomManager: any, soRoom: THREE.Object3D): THREE.Box3 {
    const soFloor = soRoom.parent;
    const segments = roomManager.getFloorSegments(soFloor.userData.id);
    const roomType = appModel.getRoomType(soRoom.userData.roomTypeId);
    const roomCategory = appModel.getRoomCategory(roomType.roomCategoryId);
    return SceneUtils.getRoomBoundingBoxByWallType(soRoom, roomCategory.isGarage, true, segments);
  }

  static getSoRoomNetBoundingBox(roomManager: SceneManager, soRoom: soRoom2D): THREE.Box3 {
    return SceneUtils.getSoRoomBoundingBoxByWallType(soRoom, true);
  }
  /**
   * Retrieves the room lines of a specific type from the given room object.
   *
   * @param room - The 3D object representing the room.
   * @param type - The type of room entity to filter lines by.
   * @returns An object containing the left, right, bottom, and top lines as THREE.Line3 objects.
   * @throws Error if the room is not valid or does not contain any children.
   * @deprecated Use `getSoRoomLinesByType` instead.
   */
  static getRoomLinesByType(
    room: THREE.Object3D | soRoom2D,
    type: RoomEntityType
  ): {
    left: THREE.Line3;
    right: THREE.Line3;
    bottom: THREE.Line3;
    top: THREE.Line3;
  } {
    // Validate input
    if (!room || !room.children || !Array.isArray(room.children)) {
      throw new Error("Invalid room object or room does not contain any children.");
    }

    const lines = room.children.filter(so => so.userData.type === type).map(so => SceneUtils.getLine3(so));

    // Check if any lines were found
    if (lines.length === 0) {
      throw new Error(`No lines found for the specified room type: ${type}`);
    }

    // Separate horizontal and vertical lines
    const horizontalLines = lines.filter(l => GeometryUtils.isLineHorizontal(l));
    const verticalLines = lines.filter(l => GeometryUtils.isLineVertical(l));

    // Return the lines organized by their respective positions
    return {
      left: RoomUtils.getLeftLine(verticalLines),
      right: RoomUtils.getRightLine(verticalLines),
      bottom: RoomUtils.getBottomLine(horizontalLines),
      top: RoomUtils.getTopLine(horizontalLines),
    };
  }

  /**
   * Retrieves the room lines of a specific type from the given room object.
   *
   * @param room - The 3D object representing the room.
   * @param type - The type of room entity to filter lines by.
   * @returns An object containing the left, right, bottom, and top lines as THREE.Line3 objects.
   * @throws Error if the room is not valid or does not contain any children.
   */
  static getSoRoomLinesByType(
    room: soRoom2D,
    type: RoomEntityType
  ): {
    left: THREE.Line3;
    right: THREE.Line3;
    bottom: THREE.Line3;
    top: THREE.Line3;
  } {
    // Validate input
    if (!room || !room.children || !Array.isArray(room.children)) {
      throw new Error("Invalid room object or room does not contain any children.");
    }
    // const lines = room.roomBoundary.boundaryLines.map(so => {
    //   console.log("so", so);
    //   const res = SceneUtils.getLine3(so.line);

    //   return res;
    // });
    //TODO Chnage to roomBoundary as above
    const lines = room.children.filter(so => so.userData.type === type).map(so => SceneUtils.getLine3(so));

    // Check if any lines were found
    if (lines.length === 0) {
      throw new Error(`No lines found for the specified room type: ${type}`);
    }

    // Separate horizontal and vertical lines
    const horizontalLines = lines.filter(l => GeometryUtils.isLineHorizontal(l));
    const verticalLines = lines.filter(l => GeometryUtils.isLineVertical(l));

    // Return the lines organized by their respective positions
    return {
      left: RoomUtils.getLeftLine(verticalLines),
      right: RoomUtils.getRightLine(verticalLines),
      bottom: RoomUtils.getBottomLine(horizontalLines),
      top: RoomUtils.getTopLine(horizontalLines),
    };
  }

  /**
   * Retrieves a specific side line of a room based on the room type and side requested.
   *
   * @param room - The 3D object representing the room.
   * @param type - The type of room entity to filter lines by.
   * @param side - The specific side of the room to retrieve (Top, Right, Bottom, Left).
   * @returns The line representing the specified side of the room as a THREE.Line3 object.
   * @throws Error if the specified side is invalid.
   * @deprecated Use `getSoRoomSideByType` instead.
   */
  static getRoomSideByType(room: THREE.Object3D, type: RoomEntityType, side: Side): THREE.Line3 {
    const lines = RoomUtils.getRoomLinesByType(room, type);

    // Return the line corresponding to the specified side
    switch (side) {
      case Side.top:
        return lines.top;
      case Side.right:
        return lines.right;
      case Side.bottom:
        return lines.bottom;
      case Side.left:
        return lines.left;
      default:
        throw new Error(`Invalid side specified: ${side}. Must be one of: Top, Right, Bottom, Left.`);
    }
  }

  /**
   * Retrieves a specific side line of a room based on the room type and side requested.
   *
   * @param room - The 3D object representing the room.
   * @param type - The type of room entity to filter lines by.
   * @param side - The specific side of the room to retrieve (Top, Right, Bottom, Left).
   * @returns The line representing the specified side of the room as a THREE.Line3 object.
   * @throws Error if the specified side is invalid.
   */
  static getSoRoomSideByType(room: soRoom2D, type: RoomEntityType, side: string): THREE.Line3 {
    const lines = RoomUtils.getSoRoomLinesByType(room, type);

    // Return the line corresponding to the specified side
    switch (side.toLowerCase()) {
      case Side.top.toLowerCase():
        return lines.top;
      case Side.right.toLowerCase():
        return lines.right;
      case Side.bottom.toLowerCase():
        return lines.bottom;
      case Side.left.toLowerCase():
        return lines.left;
      default:
        throw new Error(`Invalid side specified: ${side}. Must be one of: Top, Right, Bottom, Left.`);
    }
  }

  /**
   * Moves the specified room side by a given distance.
   * This method adjusts the vertices of intersecting objects (lines or meshes)
   * that fall within the bounding box of the model line corresponding to the given side.
   *
   * @param soRoom - The room object (THREE.Object3D) to move.
   * @param side - The side of the room to move.
   * @param distance - The distance to move the side.
   * @deprecated Use `soRoom.moveRoomSideByDistance` instead.
   */
  static moveRoomSideByDistance(soRoom: THREE.Object3D, side: Side, distance: number): void {
    // Retrieve the room model lines corresponding to each side
    const modelLine = RoomUtils.getRoomSideByType(soRoom, RoomEntityType.ModelLine, side);

    // Compute the room's center
    const roomBoundingBox = new THREE.Box3().setFromObject(soRoom);
    const roomCenter = new THREE.Vector3();
    roomBoundingBox.getCenter(roomCenter);

    // Compute the center of the model line
    const modelLineCenter = modelLine.getCenter(new THREE.Vector3());

    // Determine the direction vector from the model line center to the room center
    const direction = new THREE.Vector3().subVectors(roomCenter, modelLineCenter).normalize();

    // Create an axis-aligned vector based on the dominant axis (x or y)
    const axisVector = new THREE.Vector3(
      Math.abs(direction.x) > Math.abs(direction.y) ? Math.sign(direction.x) : 0,
      Math.abs(direction.y) > Math.abs(direction.x) ? Math.sign(direction.y) : 0,
      0 // Assuming movement only occurs along the x or y axis
    );
    axisVector.negate(); // Invert the axis vector for proper direction

    // Find objects that intersect with the model line's bounding box
    const items = soRoom.children.filter(child => GeometryUtils.lineIntersectsBoundingBox(modelLine, GeometryUtils.getGeometryBoundingBox3D(child), 0.001));

    // Process each intersecting item
    items.forEach(item => {
      try {
        const localModelLine = modelLine.clone().applyMatrix4(item.matrixWorld.clone().invert());
        const localModelLineBox = new THREE.Box3().setFromPoints([localModelLine.start, localModelLine.end]);
        // Handle lines (THREE.Line or similar geometry types)
        if (item.type === "Line") {
          SceneUtils.MoveIntersectingPoints(item, localModelLineBox, axisVector, distance);
        }
        // Handle meshes (THREE.Mesh or similar geometry types)
        else if (item.type === "Mesh" && item.userData.type != "StretchTriangle") {
          SceneUtils.MoveIntersectingPoints(item, localModelLineBox, axisVector, distance);
        }
        // For non-line, non-mesh objects, move the object directly
        else {
          item.position.addScaledVector(axisVector, distance);

          // const geom = (item as any).geometry;
          // geom.needsUpdate = true;
          // geom.computeBoundingBox();
          // geom.computeBoundingSphere();
        }
      } catch (e) {
        console.error(e);
      }
    });
  }

  /**
   * Calculates the delta distance required to move a room's side based on the given wall type.
   * @param room - The 3D room object.
   * @param side - The side of the room to calculate the move delta for.
   * @param wallType - The wall type to consider for the calculation.
   * @returns The distance required to move the side.
   */
  static calculateSideMoveDelta(room: THREE.Object3D, side: Side, funcCode: FuncCode): number {
    const distance = this.getWallInternalThickness(room, side, funcCode);
    const wallInteriorThickness = catalogSettings.walls[funcCode].interiorThickness;
    return wallInteriorThickness - distance;
  }

  /**
   * Calculates the thickness of the interior wall for the specified side of the room.
   * @param room - The 3D room object.
   * @param side - The side of the room to calculate the wall thickness for.
   * @param wallType - The wall type to consider for the calculation.
   * @returns The thickness of the interior wall for the specified side.
   */
  static getWallInternalThickness(room: THREE.Object3D, side: Side, funcCode: FuncCode = null): number {
    if (funcCode == null) {
      funcCode = room.userData.RoomSidesWallTypes[side];
    }
    const currentModelLine = RoomUtils.getRoomSideByType(room, RoomEntityType.ModelLine, side);
    const boundaryLine = RoomUtils.getRoomSideByType(room, RoomEntityType.RoomBoundaryLines, side);
    const substractFinish = appModel.showFinishFaceDimension
      ? 0
      : catalogSettings.walls[funcCode].interiorThickness - catalogSettings.walls[funcCode].coreThickness;
    return Math.abs(LineUtils.GetLinesAxisDistance(currentModelLine, boundaryLine)) - substractFinish;
  }

  /**
   * Performs a snap operation based on the given snap event.
   * @param snapEvent - The event containing information about the snap operation.
   */
  static performSnap(snapEvent: SnapEvent): void {
    const offset = this.calculateWallSnapCompenstationOffset(snapEvent);
    RoomUtils.moveRoomAwayFromSideByWallType(snapEvent.primaryRoom, snapEvent.primaryRoomSide, FuncCode.INT_2X4, offset);
  }

  static calculateWallSnapCompenstationOffset(snapEvent): number {
    const primaryRoom = snapEvent.primaryRoom;
    const secondaryRoom = snapEvent.secondaryRoom;
    const primaryRoomSide = snapEvent.primaryRoomSide;
    const secondaryRoomSide = snapEvent.secondaryRoomSide;
    if (!primaryRoomSide || !secondaryRoomSide) return 0;
    const wallType = FuncCode.INT_2X4;
    const compensateOtherRoom =
      secondaryRoom.userData.RoomSidesWallTypes[secondaryRoomSide] != wallType || secondaryRoom.userData.RoomSidesWallOffset[secondaryRoomSide] != WallType.DDL;
    return compensateOtherRoom ? this.calculateSideMoveDelta(snapEvent.secondaryRoom, snapEvent.secondaryRoomSide, wallType) : 0;
  }

  /**
   * Moves the specified side of a room by the delta distance required for the given wall type.
   * @param room - The 3D room object.
   * @param side - The side of the room to move.
   * @param wallType - The wall type determining the move distance.
   * @deprecated Use `room.adjustSoRoomSideByWallType` instead.
   */
  static adjustRoomSideByWallType(room: THREE.Object3D, side: Side, funcCode: FuncCode): void {
    const moveDelta = this.calculateSideMoveDelta(room, side, funcCode);
    RoomUtils.moveRoomSideByDistance(room, side, moveDelta);
    room.userData.RoomSidesWallTypes[side] = funcCode;
    //room.updateMatrixWorld();
  }

  /**
   * Moves the entire room away from the specified side by the delta distance for the given wall type.
   * @param room - The 3D room object.
   * @param side - The side to move away from.
   * @param wallType - The wall type determining the move distance.
   * @deprecated Use `room.moveRoomAwayFromSideByWallType` instead.
   */
  static moveRoomAwayFromSideByWallType(room: THREE.Object3D, side: Side, funcCode: FuncCode, offset: number = 0): void {
    if (!side) return;
    const moveDelta = this.calculateSideMoveDelta(room, side, funcCode);
    this.moveRoomBySideAndDistance(room, side, moveDelta + offset);
    RoomUtils.moveRoomSideByDistance(room, side, moveDelta + offset);
    room.userData.RoomSidesWallTypes[side] = funcCode;
    room.userData.RoomSidesWallOffset[side] = offset;
    //room.updateMatrixWorld();
  }

  /**
   * @param room - The 3D room object.
   * @param floorsRoot - soFloor2DRoot.
   */
  static getSoRoomsByFloorsRoot(room: soRoom2D, floorsRoot: soFloor2DRoot): soRoom2D[] {
    return floorsRoot
      .getVisibleSoFloors()
      .flatMap(soFloor => soFloor.soRooms)
      .filter(so => so !== room);
  }

  /**
   * Retrieves the side of the room that corresponds to the given line.
   * @param room - The 3D room object.
   * @param line - The line to find the corresponding side for.
   * @returns The side of the room that corresponds to the given line.
   */
  static getRoomSideByLine(room: THREE.Object3D, line: THREE.Line3): Side {
    const lines = RoomUtils.getRoomLinesByType(room, RoomEntityType.ModelLine);
    const overlappingSide = Object.keys(lines).find(side => GeometryUtils.doLinesOverlap(lines[side], line));
    return overlappingSide as Side;
  }

  /**
   * Moves the entire room by a specified distance in the direction of the given side.
   * @param room - The 3D room object.
   * @param side - The side to move in the direction of.
   * @param distance - The distance to move the room.
   * @deprecated Use `soRoom.moveRoomBySideAndDistance` instead.
   */
  static moveRoomBySideAndDistance(room: THREE.Object3D, side: Side, distance: number): void {
    if (Math.abs(distance) > 0) {
      switch (side) {
        case Side.top:
          room.position.y -= distance;
          break;
        case Side.right:
          room.position.x -= distance;
          break;
        case Side.bottom:
          room.position.y += distance;
          break;
        case Side.left:
          room.position.x += distance;
          break;
        default:
          throw new Error(`Invalid side specified: ${side}. Must be one of: Top, Right, Bottom, Left.`);
      }
    }
    room.updateMatrixWorld();
  }

  /**
   * Initializes the wall types for all sides of a room.
   * This method sets the wall type for each side of the room based on the provided wall type.
   *
   * @param room - The 3D room object.
   * @param wallType - The wall type to set for all sides of the room.
   * @deprecated Use `soRoom.initRoomWallTypes` instead.
   */
  static initRoomWallTypes(room: THREE.Object3D, funcCode: FuncCode = FuncCode.EXT_2X4): void {
    room.userData.RoomSidesWallTypes = {};
    Object.values(Side).forEach(side => {
      room.userData.RoomSidesWallTypes[side] = funcCode;
    });
  }

  /**
   * @deprecated Use `soRoom.initRoomWallOffset` instead.
   */
  static initRoomWallOffset(room: THREE.Object3D, offset: number = 0): void {
    room.userData.RoomSidesWallOffset = {};
    Object.values(Side).forEach(side => {
      room.userData.RoomSidesWallOffset[side] = offset;
    });
  }

  /**
   * Applies the wall types stored in the room's userData to the sides of the room.
   * This method sets the wall type for each side of the room based on the wall types stored in userData.
   *
   * @param room - The 3D room object.
   */
  static applyRoomWallTypes(room: THREE.Object3D): void {
    const wallTypes = room.userData.RoomSidesWallTypes;
    if (!wallTypes) {
      throw new Error("RoomSidesWallTypes not found in room's userData.");
    }
    Object.entries(wallTypes).forEach(([side, funcCode]) => {
      RoomUtils.adjustRoomSideByWallType(room, side as Side, funcCode as FuncCode);
    });
  }

  /**
   * Finds rooms that intersect with the given snapped room.
   *
   * @param {THREE.Object3D} snappedRoom - The room that was snapped.
   * @param {THREE.Object3D[]} otherRooms - The other rooms to check for intersection.
   * @returns {THREE.Object3D[]} - The intersecting rooms.
   * @deprecated Use `getIntersectingSoRooms` instead.
   */
  static getIntersectingRooms(snappedRoom, otherRooms) {
    return otherRooms.filter(
      predicateRoom =>
        GeometryUtils.getBoundingBoxIntersectionArea(
          GeometryUtils.getGeometryBoundingBox2D(snappedRoom),
          GeometryUtils.getGeometryBoundingBox2D(predicateRoom)
        ) > EPSILON
    );
  }

  /**
   * Finds rooms that intersect with the given snapped room.
   *
   * @param {soRoom2D} snappedRoom - The room that was snapped.
   * @param {soRoom2D[]} otherRooms - The other rooms to check for intersection.
   * @returns {soRoom2D[]} - The intersecting rooms.
   */
  static getIntersectingSoRooms(snappedRoom, otherRooms) {
    return otherRooms.filter(
      predicateRoom =>
        GeometryUtils.getBoundingBoxIntersectionArea(
          GeometryUtils.getGeometryBoundingBox2D(snappedRoom),
          GeometryUtils.getGeometryBoundingBox2D(predicateRoom)
        ) > EPSILON
    );
  }

  private static getLeftLine(lines: THREE.Line3[]): THREE.Line3 {
    return lines[0].start.x < lines[1].start.x ? lines[0] : lines[1];
  }
  private static getRightLine(lines: THREE.Line3[]): THREE.Line3 {
    return lines[0].start.x < lines[1].start.x ? lines[1] : lines[0];
  }
  private static getBottomLine(lines: THREE.Line3[]): THREE.Line3 {
    return lines[0].start.y < lines[1].start.y ? lines[0] : lines[1];
  }
  private static getTopLine(lines: THREE.Line3[]): THREE.Line3 {
    return lines[0].start.y < lines[1].start.y ? lines[1] : lines[0];
  }

  static getRoomTypeFunctions(roomTypeId: string): Map<string, boolean> {
    const roomFunctions: Map<string, boolean> = new Map();
    const roomType = appModel.getRoomType(roomTypeId);
    const roomCategory = appModel.getRoomCategory(roomType.roomCategoryId);

    if (roomCategory.isGarage) {
      roomFunctions.set("isGarage", true);
    }
    if (roomCategory.isOutdoor) {
      roomFunctions.set("isOutdoor", true);
    }
    if (roomCategory.isBathroom) {
      roomFunctions.set("isBathroom", true);
    }
    if (roomCategory.isStairs) {
      roomFunctions.set("isStairs", true);
    }
    if (roomType.isShaft) {
      roomFunctions.set("isShaft", true);
    }
    // todo: correct implementation of functions needed
    // if (roomCategory.isKitchen) {
    //   roomFunctions.push("Kitchen");
    // }
    // if (roomCategory.isStorage) {
    //   roomFunctions.push("Storage");
    // }
    // if (roomCategory.isBedroom) {
    //   roomFunctions.push("Storage");
    //}

    return roomFunctions;
  }
  static getRoomId(soRoom: any): string {
    return soRoom instanceof soRoom2D ? soRoom.soId : soRoom.userData.id;
  }
  static offsetOpening(opening: soOpening, offset: THREE.Vector3): void {
    if (!opening) return;

    const soRoom = opening.parent as soRoom2D;
    const wall = soRoom.walls.find(wall => wall.openings.includes(opening));

    if (!wall) return;
    const rotatedVector = VectorUtils.rotateVectorAccordingToParentRotation(
      wall.isHorizontal ? new THREE.Vector3(0, 1, 0) : new THREE.Vector3(1, 0, 0),
      soRoom
    );
    const intersecting = soRoom.children
      .filter(child => child.userData.type === RoomEntityType.Wall || child.userData.type === "OpeningZone")
      .filter(wall =>
        GeometryUtils.lineIntersectsBoundingBox(
          GeometryUtils.getBoundingBoxCenterLine(GeometryUtils.getGeometryBoundingBox3D(wall)),
          GeometryUtils.getGeometryBoundingBox3D(opening)
        )
      );
    const all = [...intersecting, opening];

    if (Math.abs(rotatedVector.x) < EPSILON && Math.abs(rotatedVector.y) > EPSILON) {
      all.forEach(obj => obj.position.setY(obj.userData.roomPosition.y));
    } else {
      all.forEach(obj => obj.position.setX(obj.userData.roomPosition.x));
    }
    all.forEach(x => wall.attach(x));
    all.forEach(obj => obj.position.add(offset.clone()));
    all.forEach(x => soRoom.attach(x));
  }
  static openingSaveRoomLocation(opening: soOpening): void {
    if (!opening) return;

    const soRoom = opening.parent as soRoom2D;
    const intersecting = soRoom.children
      .filter(child => child.userData.type === RoomEntityType.Wall || child.userData.type === "OpeningZone")
      .filter(wall =>
        GeometryUtils.lineIntersectsBoundingBox(
          GeometryUtils.getBoundingBoxCenterLine(GeometryUtils.getGeometryBoundingBox3D(wall)),
          GeometryUtils.getGeometryBoundingBox3D(opening)
        )
      );

    intersecting.forEach(inter => (inter.userData.roomPosition = inter.position.clone()));
    opening.userData.roomPosition = opening.position.clone();
  }
  // static setNetBoundaryLineByWallWidth(wall: soWall2D, soRoom: soRoom2D): void {
  //   const roomSide = RoomUtils.getWallCurrentSideInRoom(wall, soRoom);
  //   const secondarySides = roomSide === Side.top || roomSide === Side.bottom ? [Side.left, Side.right] : [Side.top, Side.bottom];
  //   const netBoundaryLinesMap = soRoom.roomNetBoundary.getRelativeBoundaryLineMap();
  //   const boundaryLinesMap = soRoom.roomBoundary.getRelativeBoundaryLineMap();
  //   const roomSideLine = netBoundaryLinesMap.get(roomSide);
  //   const roomSideModelLine = boundaryLinesMap.get(roomSide);
  //   const roomSecondaryLines = secondarySides.map(side => netBoundaryLinesMap.get(side));
  //   const wallSideWidth = !wall.HasGeometry ? 0 : roomSide === Side.top || roomSide === Side.left ? wall.wallRightSideThickness : wall.wallLeftSideThickness;
  //   const wallWidthOffset = wallSideWidth - LineUtils.GetLinesAxisDistance(roomSideLine.line3, roomSideModelLine.line3);
  //   const wallWidthOffsetVector = new THREE.Vector3();
  //   const OriginalRoomSide = soRoom.wallsSideMap.get(wall.wallId);
  //   if (OriginalRoomSide == Side.top || OriginalRoomSide == Side.bottom) {
  //     wallWidthOffsetVector.y = OriginalRoomSide == Side.top ? 1 : -1;
  //   } else {
  //     wallWidthOffsetVector.x = OriginalRoomSide == Side.left ? 1 : -1;
  //   }
  //   soRoom.roomNetBoundary.moveSideBy(OriginalRoomSide, wallWidthOffset);
  // }

  static getWallCurrentSideInRoom(wall: soWall2D, soRoom: soRoom2D): Side {
    const center = BoundingBoxUtils.getBoundingBoxCenter(soRoom.boundingBoxByModelLine);
    const wallCenter = wall.getWallMidpoint();
    return wall.isHorizontal ? (center.y < wallCenter.y ? Side.top : Side.bottom) : center.x < wallCenter.x ? Side.left : Side.right;
  }
}
