import { soWall2D } from "../../models/SceneObjects/Wall/soWall2D";

export class ModifiedWallManager {
  // map of modified segements (Segment objects) with modified functionCode
  private modifiedWalls = new Map<string, soWall2D>();

  addModifiedWall(wall: soWall2D): void {
    this.modifiedWalls.set(wall.wallId, wall);
  }

  setModifiedWalls(walls: soWall2D[]): void {
    walls.forEach(wall => this.addModifiedWall(wall));
  }

  findModifiedWall(wall: soWall2D): soWall2D | undefined {
    return this.modifiedWalls.get(wall?.wallId);
  }
  findSegmentOffset(wall: soWall2D): number {
    const modifiedWall = this.modifiedWalls.get(wall.wallId);
    if (modifiedWall) return modifiedWall.wallOffset?.distance ?? 0;
  }
  getAllModifiedWalls() {
    return Array.from(this.modifiedWalls.values());
  }
  getallModifiedWallsMap() {
    return this.modifiedWalls;
  }

  clearModifiedWalls(): void {
    this.modifiedWalls.clear();
  }
  removeModifiedWall(wall: soWall2D): void {
    this.modifiedWalls.delete(wall.wallId);
  }
}
